import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { AiFillStar, AiOutlineStar, AiOutlineTool } from "react-icons/ai";
import { BiMessageRoundedX, BiMessageRoundedCheck, BiSolidPurchaseTagAlt } from "react-icons/bi";
import { BsThreeDotsVertical, BsMarkdown } from "react-icons/bs";
import { FaShareAlt, FaMarkdown } from "react-icons/fa";
import { FiArrowLeft, FiLogOut } from "react-icons/fi";
import { GoPerson } from "react-icons/go";
import { IoVolumeMute, IoVolumeHigh } from "react-icons/io5";
import { ImBin } from "react-icons/im";
import { IoIosPeople } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import { RiInboxUnarchiveLine, RiInboxArchiveLine } from "react-icons/ri";
import DropdownTopicMenu from "ui/Dropdown/dropdownTopicMenu";
import DatabaseContext from "data/contextDatabase";
import GlobalContext from "contexts/context";
import TopicsContext from "contexts/contextTopics";
import SubscriptionsContext from "contexts/contextSubscriptions";
import LiveCacheContext from "data/contextLiveCache";
import {
  randomString,
  timestamp,
  timestampZero,
  secondsSinceEpoch,
} from "hooks/helper";
import UICreateTopicModal from "ui/UICreateTopicModal";
import "ui/UI.css";
import Modals from "./Modals";
import "ui/modals.css";
import UIInviteModal from "ui/UIInviteModal";
import UIParticipantsModal from "ui/UIParticipantsModal";
import UISubGroupModal from "ui/UISubGroupModal";
import logo from "../../src/person_outline2.png";
import TopicContext from "contexts/contextTopic";
import UIShareModal from "ui/UIShareModal";
import StyleContext from "contexts/contextStyle";
import { useLiveQuery } from "dexie-react-hooks";
import { dex_action } from "data/dexUtils";
import { isMobileTablet } from "hooks/helper";
import UILabels from "./UILabels";
import GetInitials from "../utils/GetInitials";
import SkeletonImage from "./skeletons/SkeletonImage";
import { getDescriptor } from "data/descriptors";
import LabelTag from "./icons/LabelTag";
import InverseColor from "../utils/InverseColor";

const UITopicHeader = (props) => {
  // const size = props.size;
  const topic = props.topic;
  // const topicAbout = props.description;
  const label =
    topic?.topic_display_name ||
    topic?.props?.topic_display_name ||
    topic?.topic ||
    topic?.peerpersona;

  const { databaseState } = useContext(DatabaseContext);
  const { globalState, globalDispatch } = useContext(GlobalContext);
  const { styleState } = useContext(StyleContext);
  const { topicState, topicDispatch } = useContext(TopicContext);
  const { topicsState, topicsDispatch } = useContext(TopicsContext);
  const { subscriptionsState } = useContext(SubscriptionsContext);
  const { liveCacheState } = useContext(LiveCacheContext);
  let history = useHistory();
  const [imagePres, setImagePres] = useState(false);
  const liveTopics = topicsState.topics;
  const [open, setOpen] = useState(false);
  const targetRef = useRef(null);
  const totalHgtRef = useRef(null);
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const topicTitleRef = useRef(null);
  const topicTitleDivRef = useRef(null);
  const avatarRef = useRef(null);
  const topicPersonaRef = useRef(null);
  const topicTagRef = useRef(null);
  const topicTypeRef = useRef(null);
  const [topicThemeState, setTopicThemeState] = useState(false);
  const refLogo = useRef();
  const descriptionRef = useRef();
  const [fontFam, setFontFam] = useState("");
  const [fontWeight, setFontWeight] = useState("");
  const [modalClose, setModalClose] = useState(true);
  const [isArchived, setIsArchived] = useState(false);
  const [hvr, setHvr] = useState(false);
  const [muteHvr, setMuteHvr] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [personaInfoLinesAvail, setPersonaInfoLinesAvail] = useState(1);
  const [changeImgInTopic, setChangeImageInTopic] = useState(true);
  // const [prevImage, setPrevImage] = useState()
  const [avatarLoading, setAvatarLoading] = useState(true);
  const skeletonDisplayDelay = 1000; // Set the time delay for showing skeleton (2 seconds in this example)
  const [headerSize, setHeaderSize] = useState(undefined);
  const [origHeaderSize, setOrigHeaderSize] = useState("");
  const [description, setDescription] = useState(undefined);
  const [origDescription, setOrigDescription] = useState("");
  const [descriptionHgt, setDescriptionHgt] = useState();
  const t = topic?.mtopic || topic?.mdialog;
  const [labelColor, setLabelColor] = useState("black");

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [screenWidth]);

  const showTopicThemeModal = () => {
    setTopicThemeState(!topicThemeState);
  };
  const [contentModal, setContentModal] = useState();

  useEffect(() => {
    // CONSUMING THE TOPIC DESCRIPTOR
    let isMounted = true;
    if (topicsState.topics && t && t.startsWith("t_")) {
      let topicList = topicsState.topics.filter((top) => top.mtopic === t);
      if (topicList[0]?.props?.descriptor?.digest) {
        // do we have the descriptor? if not, then fetch
        getDescriptor(
          databaseState.dexUser,
          "topic_descriptor",
          t,
          topicList[0]?.props?.descriptor.digest
        ).then(async (d) => {
          if (d.mtopic) {
            globalState.logging &&
              console.log("CONSUMING_THE_TOPIC_DESCRIPTOR", d);
            // then apply
            if (isMounted) {
              // set the header size
              if (d.headerSize) {
                setHeaderSize(d.headerSize);
                setOrigHeaderSize(d.headerSize);
              } else {
                setHeaderSize("medium");
                setOrigHeaderSize("medium");
              }
              //set the description
              d.description
                ? setDescription(d.description)
                : setDescription(undefined);
              setOrigDescription(d.description);
              //set the logo
              // if (d.logo?.thumbdigest) {
              //   try {
              //     let image64 = await getImage64(
              //       databaseState.dexUser,
              //       d.logo?.thumbdigest
              //     );

              //     if (!image64 && d.logo?.thumbdigest) {
              //       let scope = d.logo.thumbpath.split("/")[1];
              //       let i = await s3ToImage(scope, d.logo.thumbdigest);
              //       if (i?.b64) {
              //         // cacheMpersonaThumb(drec.mpersona, i?.b64);
              //         topicDispatch({
              //           type: "SET_LOGO_IMAGE",
              //           values: { logo_image: i?.b64 }
              //         });
              //         topicsDispatch({
              //           type: "SET_LOGO",
              //           values: { mtopic: t, logo: i?.b64 }
              //         });
              //         storeImage(databaseState.dexUser, i);
              //       }
              //     } else if (image64) {
              //       topicDispatch({
              //         type: "SET_LOGO_IMAGE",
              //         values: { logo_image: image64 }
              //       });
              //       //cacheMpersonaThumb(drec.mpersona, image64);
              //     }
              //   } catch (err) {
              //     console.log(err);
              //   }
              // } else {
              //   setDefaults()
              // }
            }
            // else {
            //   setDefaults()
            // }
          } else {
            setHeaderSize("medium");
          }
        });
      } else {
        setHeaderSize("medium");
      }
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [databaseState.dexUser, topicsState, t]);
  // databaseState.dexUser, topicsState.topics, t]);

  const getCountSubs = () => {
    if (topic?.topic) {
      if (topic?.mtopic.charAt(0) === "t") {
        for (let i = 0; i < liveTopics?.length; i++) {
          if (liveTopics[i]?.mtopic === topic?.mtopic) {
            return liveTopics[i]?.count_subs;
          }
        }
      } else {
        for (let i = 0; i < subscriptionsState.subscriptions?.length; i++) {
          if (subscriptionsState.subscriptions[i]?.mtopic === topic?.mtopic) {
            return subscriptionsState.subscriptions[i]?.count_subs;
          }
        }
      }
    }
    return -1;
  };

  const liveDescriptorB = useLiveQuery(() => {
    const s = topic.mtopic.split("_");
    if (s.length !== 3) return undefined;
    let bmpersona = s[1];
    if (bmpersona === topic.mpersona) bmpersona = s[2];
    return (
      databaseState.dexUser &&
      databaseState.dexUser.descriptor
        ?.filter((d) => d.mpersona === bmpersona)
        ?.toArray((d) =>
          d.reduce(
            (acc, d) => (d.storedate > (acc?.storedate || "") ? d : acc),
            undefined
          )
        )
    );
  }, [databaseState.dexUser]);

  const liveDescriptor = useLiveQuery(() => {
    const s = topic.mtopic.split("_");
    if (s.length !== 2) return undefined;
    return (
      databaseState.dexUser &&
      databaseState.dexUser.topic_descriptor.get({
        mtopic: topic.mtopic
      })
    );
  }, [databaseState.dexUser]);
  // useEffect(() => {
  //   // do we have the assets in the descriptor? if not, then fetch them
  //   // console.log("liveDescriptor", liveDescriptor);
  //   return () => {};
  // }, [liveDescriptor]);

  const liveLogo = useLiveQuery(() => {
    return (
      databaseState.dexUser &&
      databaseState.dexUser.topic_logo.get({
        mtopic:
          topic.mtopic.split("_").length < 3
            ? topic.mtopic
            : `${topic.mtopic}_${topic.mpersona}`
      })
    );
  }, [databaseState.dexUser, `${topic.mtopic}_${topic.mpersona}`]);

  // const getSubgroup = () => {
  //   let j = {
  //     type: "w.t.participants",
  //     // mtopic: props.sub.mtopic,
  //     mtopic: topic?.mtopic,
  //     /* as below this props.sub.mtopic must be changed  */
  //     version: globalState.version,
  //     smid: `${"participants"}|${topic?.mtopic}`,
  //     ts_sender: timestamp()
  //   };
  //   databaseState.dexUser &&
  //     dex_action({
  //       type: "DEX_PUT",
  //       values: {
  //         db: databaseState.dexUser,
  //         table: "send",
  //         doc: { ...j }
  //       }
  //     });
  //   setContentModal(
  //     <UISubGroupModal
  //       onClose={() => setContentModal(undefined)}
  //       sub={props.topic}
  //     ></UISubGroupModal>
  //   );
  // };

  useEffect(() => {
    props.topic?.subprops?.archived === "true" ||
    props.topic?.subprops?.archived === true
      ? setIsArchived(true)
      : setIsArchived(false);
    // setIsArchived(props.topic?.subprops?.archived === "true" ? true : false);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAvatarLoading(false);
    }, skeletonDisplayDelay);
    // Clear the timer when the component unmounts or when avatarLoading becomes false
    return () => clearTimeout(timer);
  }, [skeletonDisplayDelay]);

  useEffect(() => {
    let matching = true;
    if (
      globalState.displaying_topic &&
      topicsState.showTopic?.subscription?.mtopic
    ) {
      if (
        globalState.displaying_topic !==
        topicsState.showTopic?.subscription?.mtopic
      ) {
        matching = false;
      }
    }

    let mounted = true;
    let img = undefined;
    if (matching) {
      // console.log("!_!_!_!_top", props, topicsState.descriptorsB);
      // split the topic
      let p = topic?.mtopic?.split("_");
      if (p && p.length > 2 && p[0] === "t") {
        if (changeImgInTopic) {
          setChangeImageInTopic(false);
        } else {
          setImagePres(liveLogo?.i64 || liveLogo?.b64);
          setAvatarLoading(false);
        }
      } else {
        // let img = undefined
        // if (topicState.currentTopic === topic?.mtopic) {
        if (changeImgInTopic) {
          setChangeImageInTopic(false);
        } else {
          img = topicState?.logo?.thumb64 || topicState?.logo_image;
          // }
          if (img && img !== logo) {
            setImagePres(liveLogo?.i64 || liveLogo?.b64);
            // setImagePres(img);
            setAvatarLoading(false);
          } else {
            setImagePres(false);
          }
        }
      }
    }
    return () => {
      mounted = false;
    };
  }, [
    // databaseState.dexUser,
    // globalState.displaying_topic,
    liveLogo,
    // topic?.mtopic,
    topicState?.logo?.thumb64,
    topicState?.logo_image
    // topicsState.showTopic
  ]);

  useEffect(() => {
    let fontName = styleState?.theme?.fonts?.topic_name_topic_bar_font;
    if (fontName) {
      setFontFam(fontName.substring(0, fontName.length - 3));
      setFontWeight(fontName.substring(fontName.length - 3, fontName.length));
    }
  }, [styleState?.theme]);

  useEffect(() => {
    // console.log("!_!_!_!logo", topic?.mtopic, topicsState?.logos);
    try {
      if (
        topicsState?.logos &&
        topicsState?.logos[
          topic?.mtopic.split("_").length < 3
            ? topic.mtopic
            : `${topic.mtopic}_${topic.mpersona}`
        ]
      ) {
        topicDispatch({
          type: "SET_LOGO_IMAGE",
          values: {
            logo_image:
              topicsState.logos[
                topic.mtopic.split("_").length < 3
                  ? topic.mtopic
                  : `${topic.mtopic}_${topic.mpersona}`
              ]
          }
        });
      }
    } catch (error) {
      globalState.logging && console.log(error);
    }
    return () => {};
  }, [topic?.mpersona, topic?.mtopic, topicDispatch, topicsState?.logos]);
  // topic?.mtopic, topicDispatch, topicsState?.logos]);

  const getIcon = (groupType) => {
    if (groupType !== undefined) {
      switch (groupType.toLowerCase()) {
        case "private_dialog":
          return "private";
        case "public":
          return "public";
        case "private":
          return "private";
        case "whisper":
          return "whisper";
        case "hidden":
          return "hidden";
        default:
          break;
      }
      // return icon
    }
    return undefined;
  };

  const leaveT = () => {
    if (
      window.confirm(
        'Are you sure you want to leave "' +
          (props.topic.topic_display_name ||
            props.topic.props?.topic_display_name ||
            props.topic.topic) +
          '"?\nIt will only be deleted for you.\nOthers will still be able to use it.\n'
      )
    ) {
      let j = {
        type: "w.t.leave",
        mtopic: props.topic.mtopic,
        mpersona: props.topic.mpersona,
        version: globalState.version,
        smid: randomString(8)
      };
      if (databaseState.dexUser) {
        let transform = (topicsRec) => {
          let newRec = JSON.parse(JSON.stringify(topicsRec));
          newRec.value =
            topicsRec.value?.filter(
              (t) =>
                !(
                  t.mtopic === props.topic.mtopic &&
                  t.mpersona === props.topic.mpersona
                )
            ) || [];
          return newRec;
        };
        dex_action({
          type: "DEX_MODIFY_TRANS",
          values: {
            db: databaseState.dexUser,
            table: "account",
            filter: (i) => i.type === "topics",
            transform: transform
          }
        });
        dex_action({
          type: "DEX_PUT",
          values: {
            db: databaseState.dexUser,
            table: "send",
            doc: j
          }
        });
      }
      if (!isMobileTablet()) {
        topicsDispatch({
          type: "SET_SHOWTOPIC",
          values: { showTopic: { subscription: undefined } }
        });
      } else {
        history.push("/");
      }
      // if (history.length >= 1) history.goBack();
      // else history.push("/");
    } else {
    }
  };

  const destroyTopic = () => {
    if (
      window.confirm(
        'Are you sure you want to leave "' +
          (props.topic.topic_display_name ||
            props.topic.props?.topic_display_name ||
            props.topic.topic) +
          '"?\nIf you are the topic owner, it will be deleted for all users.\nOtherwise it will only be deleted for you.\n'
      )
    ) {
      let j = {
        type: "w.topic.destroy",
        mtopic: props.topic.mtopic,
        persona: props.topic.persona,
        version: globalState.version,
        smid: randomString(8)
      };
      databaseState.dexUser &&
        dex_action({
          type: "DEX_PUT",
          values: {
            db: databaseState.dexUser,
            table: "send",
            doc: j
          }
        });

      history.push("/");
    } else {
    }
  };

  let muteFCM = (Seconds, Mtopic) => {
    let j = {
      type: "w.user.fcmtime", // for new/old topics and dialogs
      cid: globalState.cid,
      seconds: Seconds, // how long to mute this topic
      mtopic: Mtopic,
      version: globalState.version,
      smid: randomString(8)
    };
    // console.log("MUTE w.user.fcmtime", j);
    databaseState.dexUser &&
      dex_action({
        type: "DEX_PUT",
        values: {
          db: databaseState.dexUser,
          table: "send",
          doc: j
        }
      });
  };

  let muteTopic = () => {
    setOpen(false);
    muteFCM(60 * 60 * 24 * 365 * 10, props.topic.mtopic); // mute for 10 years
    history.goBack();
  };

  let unmuteTopic = () => {
    setOpen(false);
    muteFCM(0, props.topic.mtopic); // mute for 10 years
    history.goBack();
  };

  // let toggleIDFlag = () => {
  //   let tempFlag;
  //   if (globalState.idFlag) tempFlag = globalState.idFlag;
  //   else tempFlag = false;
  //   globalDispatch({
  //     type: "SET_IDFLAG",
  //     values: { idFlag: !tempFlag }
  //   });
  // };

  // let toggleTimeFormat = () => {
  //   let tempTimeFormat;
  //   if (globalState.timeFormat)
  //     if (globalState.timeFormat === "HH:mm")
  //       tempTimeFormat = "HH:mm:ss";
  //     else tempTimeFormat = "HH:mm"
  //   else tempTimeFormat = "HH:mm:ss";
  //   globalDispatch({
  //     type: "SET_TIMEFORMAT",
  //     values: { timeFormat: tempTimeFormat }
  //   });
  // }

  let topicTheme = () => {
    setOpen(false);
    setTopicThemeState(true);
  };

  const deleteT = () => {
    if (
      window.confirm(
        'Are you sure you want to destroy "' +
          (props.topic.topic_display_name ||
            props.topic.props?.topic_display_name ||
            props.topic.topic) +
          '"?\nIt will be deleted for all users\n'
      )
    ) {
      let j = {
        type: "w.t.delete",
        mtopic: props.topic.mtopic,
        mpersona: props.topic.mpersona,
        version: globalState.version,
        ts_sender: timestamp(),
        smid: randomString(8)
      };
      if (databaseState.dexUser) {
        let transform = (topicsRec) => {
          let newRec = JSON.parse(JSON.stringify(topicsRec));
          newRec.value =
            topicsRec.value?.filter(
              (t) =>
                !(
                  t.mtopic === props.topic.mtopic &&
                  t.mpersona === props.topic.mpersona
                )
            ) || [];
          return newRec;
        };
        dex_action({
          type: "DEX_MODIFY_TRANS",
          values: {
            db: databaseState.dexUser,
            table: "account",
            filter: (i) => i.type === "topics",
            transform: transform
          }
        });
        dex_action({
          type: "DEX_PUT",
          values: {
            db: databaseState.dexUser,
            table: "send",
            doc: j
          }
        });
      }
      if (!isMobileTablet()) {
        topicsDispatch({
          type: "SET_SHOWTOPIC",
          values: { showTopic: { subscription: undefined } }
        });
      } else {
        history.push("/");
      }
    } else {
    }
  };

  useEffect(() => {
    if (topicsState.markdownSwitch === undefined)
      topicsDispatch({
        type: "SET_MARKDOWN",
        values: { markdownSwitch: true }
      });
  }, []);

  const toggleMarkdown = () => {
    setOpen(false);
    topicsDispatch({
      type: "SET_MARKDOWN",
      values: { markdownSwitch: !topicsState.markdownSwitch }
    });
  };

  const archiveToggle = () => {
    if (true) {
      let archivedNewVal =
        props.topic?.subprops?.archived === "true" ? "false" : "true";
      let j = {
        type: "w.t.subprops.set",
        mtopic: props.topic.mtopic,
        mpersona: props.topic.mpersona,
        new_props: { archived: archivedNewVal },
        version: globalState.version,
        smid: randomString(8),
        ts_sender: timestampZero(),
        status: "1",
        origin_sender: "registered"
      };

      if (databaseState.dexUser) {
        let transform = (topicsRec) => {
          let newRec = JSON.parse(JSON.stringify(topicsRec));
          newRec.value?.forEach(
            (t) =>
              t.mpersona === props.topic.mpersona &&
              t.mtopic === props.topic.mtopic &&
              (t.subprops.archived = archivedNewVal)
          );
          return newRec;
        };
        dex_action({
          type: "DEX_MODIFY_TRANS",
          values: {
            db: databaseState.dexUser,
            table: "account",
            filter: (i) => i.type === "topics",
            transform: transform
          }
        });
        dex_action({
          type: "DEX_PUT",
          values: {
            db: databaseState.dexUser,
            table: "send",
            doc: j
          }
        });
        // WAPP-1607 - mark all msgs read upon archiving
        !isArchived && // if it was not archived when we started but now it is
          ReadAll();
        // whatever option the user chose, they must be directed to active topics screen upon exit.
        // WAPP-1521
        isArchived && // it was when we started
          globalState?.displaying_archive === true &&
          globalDispatch({
            type: "TOGGLE_ARCHIVE"
          });
      }

      // Depending on the previous state (opposite of isArchived),
      // close topic and exit to main(active) screen, or keep topic open
      // WAPP-1521
      setOpen(false);
      if (!isMobileTablet()) {
        topicsDispatch({
          type: "SET_SHOWTOPIC",
          values: { showTopic: { subscription: undefined } }
        });
      } else {
        if (window.location?.href?.includes("menu") && history.length > 1)
          history.goBack();
        if (history.length > 1) history.goBack();
      }
      // isArchived ? history.goBack() : history.push("/");
    }
  };

  useEffect(() => {
    if (!modalClose) {
      window.addEventListener("popstate", closeModal);
      if (window.location?.href?.includes("menu")) {
        window.history.replaceState({ id: 2 }, null, "modal");
      } else {
        window.history.pushState({ id: 2 }, null, "modal");
      }
      return () => {
        window.removeEventListener("popstate", closeModal);
      };
    }
  }, [modalClose]);

  const closeModal = () => {
    setModalClose(true);
    setContentModal(undefined);
    if (history.length >= 1) history.goBack();
  };

  const handleClickOutside = () => {
    setModalClose(true);
    setContentModal(undefined);
    if (history.length >= 1) {
      history.goBack();
    }
  };

  const HideAll = () => {
    setOpen(false);
    setModalClose(false);
    setContentModal(
      <Modals
        // noBckgrnd={true}
        title="Clear Chat"
        onClose={() => closeModal()}
        onClickOutside={() => handleClickOutside()}
        footer={
          <div>
            <button
              className="UI-button-service"
              type="button"
              onClick={() => {
                dex_action({
                  type: "DEX_HIDE_TOPIC_MESSAGES",
                  values: {
                    db: databaseState.dexUser,
                    mtopic: topic.mtopic,
                    mpersona: topic.mpersona
                  }
                });
                setContentModal(undefined);
                if (
                  history.length >= 1 &&
                  (!topic.pinned || topic.pinned?.length <= 0)
                )
                  history.goBack();
              }}
            >
              Clear
            </button>
          </div>
        }
      >
        <label className="modal-label-new">
          All messages on this topic will be removed on this device. Messages
          cannot be recovered but will still be visible on other devices.
          <br />
          Are you sure you want to proceed?
          <br /> <br />
        </label>
      </Modals>
    );
  };

  const ReadAll = () => {
    setOpen(false);
    //console.log("uith: ReadAll: topic: ", topic);
    dex_action({
      type: "DEX_MULTI_MARK_READ",
      values: {
        db: databaseState.dexUser,
        global: true,
        mtopics: [topic.mtopic ? topic.mtopic : topic.mdialog]
      }
    });
  };

  const onlyPublicHidden = () => {
    let oPH = false;
    if (topic?.props?.topictype?.length > 0) {
      if (
        topic?.props?.topictype === "public" ||
        topic?.props?.topictype === "hidden"
      )
        oPH = true;
    } else if (
      topic?.props?.visibility === "public" ||
      topic?.props?.visibility === "hidden"
    )
      oPH = true;
    return oPH;
  };

  const toggleStarFlag = () => {
    //what we know is the current mtopic and the current mpersona
    //our list of topics with stars are described by [{mtopic:mtopic,mpersona:mpersona}]
    let clientStarList = [];
    let updatedList;
    if (globalState.prefs?.clientStar) {
      clientStarList = globalState.prefs.clientStar;
      const index = clientStarList.findIndex(
        (item) =>
          item.mtopic === topic?.mtopic && item.mpersona === topic?.mpersona
      );
      // Check if the index is found
      if (index !== -1) {
        // Remove the item from the list
        updatedList = clientStarList.filter((item, i) => i !== index);
      } else {
        // Add the item to the list
        updatedList = [
          ...clientStarList,
          { mtopic: topic?.mtopic, mpersona: topic?.mpersona }
        ];
      }
    } else {
      // Add the item to the list
      updatedList = [
        ...clientStarList,
        { mtopic: topic?.mtopic, mpersona: topic?.mpersona }
      ];
    }
    globalDispatch({
      type: "SET_PREFS",
      values: {
        prefs: {
          ...globalState.prefs,
          clientStar: [...updatedList] //list of topics with stars
        }
      }
    });

    dex_action({
      type: "DEX_PUT",
      values: {
        db: databaseState.dexUser,
        table: "user",
        doc: {
          key: "prefs",
          value: {
            ...globalState.prefs,
            clientStar: updatedList //list of topics with stars
          }
        }
      }
    });
    setOpen(false);
  };

  const closeLabels = () => {
    topicsDispatch({
      type: "SET_LABEL_DISPLAY",
      values: { labelDisplay: undefined }
    });
    setContentModal(undefined);
  };

  const labelTopic = () => {
    setOpen(false);
    if (topicsState.labelDisplay)
      topicsDispatch({
        type: "SET_LABEL_DISPLAY",
        values: { labelDisplay: undefined }
      });
    setContentModal(
      <UILabels
        mtopic={topic?.mtopic}
        mpersona={topic?.mpersona}
        onClose={closeLabels}
      ></UILabels>
    );
  };

  const menuItems = [
    {
      leftIcon:
        (liveCacheState?.mute &&
          liveCacheState?.mute[topic?.mtopic]?.mute &&
          liveCacheState?.mute[topic?.mtopic].mute) >
        secondsSinceEpoch() + 60 ? (
          <IoVolumeHigh />
        ) : (
          <IoVolumeMute />
        ),
      text:
        (liveCacheState?.mute &&
          liveCacheState?.mute[topic?.mtopic]?.mute &&
          liveCacheState?.mute[topic?.mtopic].mute) >
        secondsSinceEpoch() + 60
          ? "Unmute"
          : "Mute",
      callback:
        (liveCacheState?.mute &&
          liveCacheState?.mute[topic?.mtopic]?.mute &&
          liveCacheState?.mute[topic?.mtopic].mute) >
        secondsSinceEpoch() + 60
          ? unmuteTopic
          : muteTopic,
      permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
      goToMenu: ""
    },
    {
      leftIcon:
        globalState.prefs?.clientStar?.findIndex(
          (item) =>
            item.mtopic === topic?.mtopic && item.mpersona === topic?.mpersona
        ) > -1 ? (
          <AiOutlineStar />
        ) : (
          <AiFillStar />
        ),
      text:
        globalState.prefs?.clientStar?.findIndex(
          (item) =>
            item.mtopic === topic?.mtopic && item.mpersona === topic?.mpersona
        ) > -1
          ? "Unstar topic"
          : "Star topic",
      callback: toggleStarFlag,
      permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
      goToMenu: ""
    },
    {
      leftIcon: <BiSolidPurchaseTagAlt />,
      text: "Label topic",
      callback: labelTopic,
      permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
      goToMenu: ""
    },
    {
      leftIcon: <GoPerson strokeWidth={2} size={20} />,
      text: "Invite Persona",
      callback: () => {
        if (props.topic?.mtopic?.startsWith("t_")) {
          setOpen(false);
          setContentModal(
            <UIInviteModal
              topic={topic}
              onClose={() => setContentModal(undefined)}
            ></UIInviteModal>
          );
        } else alert("Cannot invite to old topics");
      },
      permissionLevels:
        topic?.props?.topictype === "dialog" ||
        topic?.props?.visibility === "dialog"
          ? []
          : topic?.props?.visibility === "private"
          ? // || topic?.props?.visibility === "hidden"
            ["admin", "owner"]
          : ["admin", "owner", "designer", "tx", "whisper", "rx"],
      goToMenu: ""
    },
    onlyPublicHidden() && {
      // process.env.REACT_APP_MODE === "dev" && {
      leftIcon: <FaShareAlt />,
      text: "Share",
      callback: () => {
        setOpen(false);
        setContentModal(
          <UIShareModal
            onClose={() => setContentModal(undefined)}
            title={props.topic?.topic_display_name || props.topic?.topic}
            link={
              props.topic.mtopic.startsWith("t_")
                ? window.location.origin + "/" + props.topic?.mtopic
                : window.location.origin + "/topic/" + props.topic?.mtopic
            }
          ></UIShareModal>
        );
      },
      permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
      goToMenu: ""
    },
    {
      leftIcon: <IoIosPeople size={24} />,
      text: "Participants",
      callback: () => {
        setOpen(false);

        if (topic?.mtopic && topic?.mtopic.startsWith("t_")) {
          setContentModal(
            <UIParticipantsModal
              onClose={() => setContentModal(undefined)}
              sub={props.topic}
            ></UIParticipantsModal>
          );
          // getParticipants();
        } else {
          alert("Not yet for older topics");
        }
      },
      permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
      goToMenu: ""
    },
    // {
    //   leftIcon: <IoIosPeople />,
    //   text: "Whisper Subgroup",
    //   callback: () => {
    //     setOpen(false);
    //     if (topic?.mtopic && topic?.mtopic.startsWith("t_")) {
    //       getSubgroup();
    //     } else {
    //       alert("Not yet for older topics");
    //     }
    //   },
    //   permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
    //   goToMenu: ""
    // },
    {
      leftIcon: <BiMessageRoundedCheck strokeWidth={1} size={22} />,
      text: "Mark all as Read",
      callback: ReadAll,
      permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
      goToMenu: ""
    },
    {
      leftIcon: <BiMessageRoundedX strokeWidth={1} size={22} />,
      text: "Clear chat",
      // text: "Delete all Messages",
      callback: HideAll,
      permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
      goToMenu: ""
    },
    // process.env.REACT_APP_MODE === "dev" && {
    //   leftIcon: <AiOutlineAntDesign />,
    //   rightIcon: <FaArrowRight />,
    //   text: "Advanced",
    //   permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
    //   // callback: design,
    //   goToMenu: "secondary"
    // },
    {
      leftIcon: <MdEdit size={22} />,
      text: "Edit Topic",
      callback: topicTheme,
      permissionLevels:
        (topic.mdialog ||
          topic.props?.tag?.toLowerCase().includes("-job") ||
          topic.props?.topictype?.toLowerCase().includes("job") ||
          //tag id for "job"  is deprecated note end feb to check status
          topic.props?.topictype?.toLowerCase() === "dialog") &&
        !(globalState.devMode === "true" || globalState.devMode === true)
          ? []
          : ["admin", "owner", "designer"],
      // permissionLevels: ["designer"],
      goToMenu: ""
    },
    {
      leftIcon: isArchived ? (
        <RiInboxUnarchiveLine strokeWidth={0.5} size={21} />
      ) : (
        <RiInboxArchiveLine strokeWidth={0.5} size={21} />
      ),
      text: isArchived ? "Unarchive Topic" : "Archive Topic",
      callback: archiveToggle,
      title: isArchived ? "retrieve" : "archive",
      permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
      goToMenu: ""
    },
    !process.env.REACT_APP_URL?.includes("ezist") &&
      !process.env.REACT_APP_URL?.includes("app") &&
      (globalState.devMode === true || globalState.devMode === "true") && {
        leftIcon: topicsState.markdownSwitch ? (
          <FaMarkdown size={24} />
        ) : (
          <FaMarkdown size={24} />
        ),
        text: topicsState.markdownSwitch ? "Markdown OFF" : "Markdown ON",
        callback: toggleMarkdown,
        permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
        goToMenu: ""
      },
    {
      leftIcon: <FiLogOut strokeWidth={3} size={18} />,
      // <IoExitOutline
      // strokeWidth={2.4}
      // size={22} />,
      text: "Leave Topic",
      callback: leaveT,
      permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
      goToMenu: ""
    },
    topic?.props?.topictype !== "dialog"
      ? {
          leftIcon: <ImBin />,
          text: "Delete Topic",
          callback: deleteT,
          permissionLevels: ["owner"],
          goToMenu: ""
        }
      : {}
  ];

  // topic?.props?.topictype === "dialog" && menuItems.splice(-1, 1);

  const secMenuItems = [
    // {
    //   leftIcon: <FaPalette />,
    //   text: "Design",
    //   callback: topicTheme,
    //   permissionLevels:
    //     topic.mdialog ||
    //     topic.props?.tag?.toLowerCase() === "job" ||
    //     //tag id for "job"  is deprecated note end feb to check status
    //     topic.props?.topictype?.toLowerCase() === "job" ||
    //     topic.props?.topictype?.toLowerCase() === "dialog"
    //       ? []
    //       : ["designer"],
    //   // permissionLevels: ["designer"],
    //   goToMenu: ""
    // }
    // {
    //   leftIcon: <FaFlagCheckered />,
    //   text: "Identity flag",
    //   permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
    //   callback: toggleIDFlag,
    //   goToMenu: ""
    // },
    // {
    //   leftIcon: <FaRegClock />,
    //   text: "Time-toggle format",
    //   permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
    //   callback: toggleTimeFormat,
    //   goToMenu: ""
    // }
  ];

  const getMuteIcon = () => {
    if (
      (liveCacheState?.mute &&
        liveCacheState?.mute[topic?.mtopic]?.mute &&
        liveCacheState?.mute[topic?.mtopic].mute) >
      secondsSinceEpoch() + 60
    )
      return true;
    else return false;
  };

  useEffect(() => {
    setDescriptionHgt(descriptionRef?.current?.offsetHeight);
  }, [description]);

  const hgtStyle = {
    height: description ? `${descriptionHgt}px` : "1.5rem"
  };

  const handleOpen = (e) => {
    setOpen(!open);
    setCoords({ x: e.clientX, y: e.clientY });
  };

  // useEffect(() => {
  // if (topicTitleRef?.current)
  //   console.log("ttref:", topicTitleRef.current.clientWidth);
  //   if (avatarRef?.current) {
  //     setAvatarWidth(avatarRef.current?.offsetWidth.toString() + "px")
  //     console.log("avatarref:", avatarRef.current?.offsetWidth);
  //   }
  //   // console.log("innerWidth=", window.innerWidth);
  //   return avatarRef.current?.offsetWidth
  // }, [refLogo, size, topicState?.logo?.thumb64, topicState?.logo_image]
  // )

  const getHeadColor = (groupType) => {
    let root = document.getElementById("root");
    if (groupType !== undefined) {
      switch (groupType.toLowerCase()) {
        case "private":
          return root.style.getPropertyValue(
            "--main_screen_topic_type_private_background_color"
          );
        case "public":
          return root.style.getPropertyValue(
            "--main_screen_topic_type_public_background_color"
          );
        case "dialog":
          return root.style.getPropertyValue(
            "--main_screen_topic_type_dialog_background_color"
          );
        case "hidden":
          return root.style.getPropertyValue(
            "--main_screen_topic_type_hidden_background_color"
          );
        case "community":
          return root.style.getPropertyValue(
            "--main_screen_topic_type_community_background_color"
          );
        case "job":
          return root.style.getPropertyValue(
            "--main_screen_topic_type_job_background_color"
          );
        default:
          if (groupType.toLowerCase().includes("-job"))
            return root.style.getPropertyValue(
              "--main_screen_topic_type_job_background_color"
            );
          break;
      }
    }
    return root.style.getPropertyValue("--topic_header_background_color");
  };

  const tagOrVis = () => {
    if (props.topic?.props?.tag) return props.topic.props.tag;
    else return getIcon(topic?.props?.visibility) || getIcon(topic?.visibility);
  };

  const DispArc = () => {
    const [hover, setHover] = useState(true);
    //   const toggleHover = () => {
    //     setHover(!hover)
    // }
    return (
      <div
      // style={{
      //   // display: "block",
      //   position: "absoloute"
      // }
      // }
      >
        "this is archived"
      </div>
    );
  };

  const handleBackArrow = () => {
    if (!isMobileTablet()) {
      topicsDispatch({
        type: "SET_SHOWTOPIC",
        values: { showTopic: { subscription: undefined } }
      });
    } else history.goBack();
  };

  const isLabelled = useMemo(() => {
    const foundLabel = globalState.prefs?.labelNamesList?.find((i) => {
      if (
        i.mtopic === topic.mtopic &&
        i.mpersona === props.topic?.mpersona &&
        // globalState.persona?.mpersona &&
        i.labels?.length > 0
      ) {
        return true;
      }
      return false;
    });
    if (foundLabel) {
      if (
        typeof foundLabel.labels[0] === "object" &&
        foundLabel.labels[0]?.color
      ) {
        setLabelColor(foundLabel.labels[0]?.color);
        return true;
      } else {
        if (foundLabel.labels) return true;
        else return false;
      }
    } else {
      return false;
    }
  }, [globalState.prefs, topic, globalState.persona]);

  const isFlagged = () => {
    if (
      globalState.prefs?.clientStar?.findIndex(
        (item) =>
          item.mtopic === topic?.mtopic && item.mpersona === topic?.mpersona
      ) > -1
    )
      return true;
    else return false;
  };

  const avatarStyle = () => {
    if (headerSize?.toLowerCase() !== "small") return "min-content";
    else return "";
  };
  const archiveStyle = () => {
    if (isArchived) return 1.5;
    else return 0;
  };
  const muteStyle = () => {
    if (getMuteIcon()) return 1.5;
    else return 0;
  };
  // const labelStyle = () => {
  const labelStyle = isLabelled ? 1.5 : 0;
  const starStyle = () => {
    if (isFlagged()) return 1.5;
    else return 0;
  };

  useEffect(() => {
    if (headerSize !== "none" && topicTitleRef.current && targetRef.current) {
      const lineHeight = parseFloat(
        getComputedStyle(topicTitleRef?.current)?.lineHeight
      );
      if (
        lineHeight &&
        targetRef?.current?.offsetHeight &&
        topicTitleRef?.current?.offsetHeight
      ) {
        setPersonaInfoLinesAvail(
          Math.floor(
            (targetRef?.current?.offsetHeight -
              topicTitleRef.current.offsetHeight) /
              lineHeight
          )
        );
      }
    }
    props.hdrHgt(totalHgtRef?.current?.offsetHeight);
  }, [
    headerSize,
    topicTitleRef?.current?.offsetHeight,
    targetRef?.current?.offsetHeight,
    topicTitleDivRef?.current?.offsetHeight,
    totalHgtRef
  ]);

  // const calcWidthNeeds = (spcNeed) => {
  //   let spaceAvail = false
  //   const width = topicTitleRef?.current?.offsetWidth
  //   const widthPersona = topicPersonaRef?.current?.offsetWidth
  //   spaceAvail = (width - widthPersona > spcNeed)
  //   return spaceAvail
  // }

  // const calcWidthNeedsType = (spcNeed) => {
  //   let spaceAvail = false
  //   const width = topicTitleRef?.current?.offsetWidth
  //   const widthPersona = topicPersonaRef?.current?.offsetWidth
  //   const widthTag = topicTagRef?.current?.offsetWidth
  //   spaceAvail = (width - widthPersona - widthTag > spcNeed)
  //   return spaceAvail
  // }

  // const calcWidthNeedsPart = (spcNeed) => {
  //   let spaceAvail = false
  //   const width = topicTitleRef?.current?.offsetWidth
  //   const widthPersona = topicPersonaRef?.current?.offsetWidth
  //   const widthTag = topicTagRef?.current?.offsetWidth
  //   const widthType = topicTypeRef.current?.offsetWidth
  //   spaceAvail = (width - widthPersona - widthTag - widthType > spcNeed)
  //   return spaceAvail
  // }

  // const testDisplayTag = () => {
  //   if (topicTitleRef?.current?.offsetWidth -
  //     (topicPersonaRef?.current?.offsetWidth +
  //       topicTagRef?.current?.offsetWidth +
  //       topicTypeRef.current?.offsetWidth + 3 * 0.5 * 15) > 0)
  //     return true
  //   else return false
  // }

  // useEffect(() => {
  //   const width = topicTitleRef?.current?.offsetWidth
  // }, [topicTitleRef?.current?.offsetWidth])

  const content = (
    <div
      ref={totalHgtRef}
      style={headerSize?.toLowerCase() === "none" ? hgtStyle : {}}
    >
      {/* <div ref={targetRef} style={size === "none" ? hgtStyle : {}}> */}
      {headerSize && (
        <div
          ref={targetRef}
          className="UI-topic-header"
          style={{
            gridTemplateColumns: `2.5rem ${avatarStyle()} auto ${archiveStyle()}rem ${muteStyle()}rem ${labelStyle}rem ${starStyle()}rem 1.5rem`,
            backgroundColor: getHeadColor(
              props?.topic?.props?.tag ||
                getIcon(topic?.props?.visibility) ||
                getIcon(topic?.visibility)
            )
          }}
          type={
            description?.length > 0
              ? headerSize + "withdescription"
              : headerSize
          }
          onClick={() =>
            props.selectTopic &&
            props.selectTopic(topic?.mtopic || topic?.mdialog)
          }
          key={"topicHeader"}
        >
          {props.showback && (
            <div //back arrow
              style={
                headerSize?.toLowerCase() === "none"
                  ? { position: "relative" }
                  : {
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }
              }
              onClick={() => {
                handleBackArrow();
              }}
            >
              <FiArrowLeft
                className={
                  headerSize?.toLowerCase() === "none"
                    ? "UI-back-arrow-no-header"
                    : "UI-back-arrow"
                }
                size={
                  headerSize?.toLowerCase() === "none"
                    ? 22
                    : headerSize?.toLowerCase() === "small"
                    ? 24
                    : 30
                }
                style={{
                  // fontSize: "3rem",
                  color: "var(--menu_button_icon_color)"
                }}
              />
            </div>
          )}
          {headerSize?.toLowerCase() !== "none" && (
            <div //avatar
              ref={avatarRef}
              className="UI-topic-avatar"
              style={
                headerSize?.toLowerCase() === "small" ? { display: "none" } : {}
              }
              type={headerSize}
            >
              {/*wallpaper or avatar to go here*/}
              {/* image */}
              {(headerSize?.toLowerCase() === "xlarge" ||
                headerSize?.toLowerCase() === "large" ||
                headerSize?.toLowerCase() === "medium") &&
                (avatarLoading ? (
                  <SkeletonImage
                    type={headerSize === "xlarge" ? "avatar" : "image"}
                    width={headerSize === "xlarge" ? "4rem" : "4rem"}
                    height={headerSize === "xlarge" ? "6.5rem" : "4rem"}
                    background="lightgray"
                  />
                ) : imagePres ? (
                  <img
                    style={
                      headerSize?.toLowerCase() === "medium"
                        ? {
                            borderRadius: "50%",
                            width: "4rem",
                            height: "4rem"
                          }
                        : headerSize?.toLowerCase() === "large"
                        ? { height: "4.6rem" }
                        : headerSize?.toLowerCase() === "xlarge"
                        ? { height: "6.5rem" }
                        : { height: "3rem" }
                    }
                    ref={refLogo}
                    id={
                      "logo_" +
                      (props.topic.mtopic.split("_").length < 3
                        ? props.topic.mtopic
                        : `${props.topic.mtopic}_${props.topic.mpersona}`)
                    }
                    src={imagePres}
                    alt="Logo"
                    height={40}
                    onClick={() => {
                      if (liveDescriptor) {
                        history.push("/imageDisplay", {
                          avatar: true,
                          picAttach: false,
                          thumb: topicState.logo_image,
                          descriptor: liveDescriptor.logo,
                          scope: liveDescriptor.logo?.imgpath?.split("/")[1],
                          imgdigest: liveDescriptor.logo?.imgdigest
                        });
                      } else if (liveDescriptorB) {
                        history.push("/imageDisplay", {
                          avatar: true,
                          picAttach: false,
                          thumb: topicState.logo_image,
                          descriptor: liveDescriptorB,
                          scope: liveDescriptorB?.imgpath?.split("/")[1],
                          imgdigest: liveDescriptorB?.imgdigest
                        });
                      }
                    }}
                  />
                ) : props.topic?.props?.tag &&
                  props.topic?.props?.tag.toLowerCase().includes("-job") ? (
                  <AiOutlineTool
                    style={{
                      fontSize: "3rem",
                      borderRadius: "50%",
                      backgroundColor: "#008080",
                      color: "white",
                      width: "4.0rem",
                      height: "4.0rem"
                    }}
                  />
                ) : (
                  <GetInitials
                    type="job"
                    head_list={false}
                    str={
                      props?.topic?.topic_display_name ||
                      props?.topic?.props?.topic_display_name ||
                      props?.topic?.topic
                    }
                    // str={props?.topic?.topic || props?.topic?.peerpersona}
                    // str={props?.topic?.topic}
                  ></GetInitials>
                ))}
            </div>
          )}

          <div
            id="topicInfo"
            className="UI-unread-topics-container"
            type={headerSize}
          >
            {/* <div className="UI-unread-topics-container" type={headerSize}> */}
            {headerSize?.toLowerCase() !== "none" && (
              <div ref={topicTitleDivRef} className="UI-topic-header-names">
                <label //topic name
                  ref={topicTitleRef}
                  className="UI-topic-title"
                  style={{
                    fontFamily: fontFam,
                    fontWeight: fontWeight
                  }}
                  type={headerSize}
                >
                  {label}
                </label>
              </div>
            )}
            {headerSize !== "small" && personaInfoLinesAvail && (
              <div>
                {(headerSize?.toLowerCase() === "xlarge" ||
                  headerSize?.toLowerCase() === "large" ||
                  headerSize?.toLowerCase() === "medium") && (
                  <div
                    className="UI-topic-header-names-persona"
                    // className="UI-topic-header-persona-type"
                    style={
                      personaInfoLinesAvail > 0
                        ? personaInfoLinesAvail > 1
                          ? {
                              gridTemplateColumns:
                                "min-content min-content min-content"
                            }
                          : {
                              gridTemplateColumns:
                                "min-content min-content min-content min-content"
                            }
                        : { display: "none" }
                    }
                  >
                    {/* <label className="UI-topic-title-persona"> */}
                    <div
                      ref={topicPersonaRef}
                      className="UI-topic-header-persona"
                      style={
                        personaInfoLinesAvail > 1
                          ? { gridColumn: "1 / span 3" }
                          : {}
                      }
                    >
                      {props.topic.persona}
                    </div>
                    <span //participants
                      className="UI-topic-header-participants"
                    >
                      {topic?.topic ? getCountSubs() : 2}
                    </span>
                    <label className="UI-topic-header-type" ref={topicTypeRef}>
                      {getIcon(topic?.props?.visibility) ||
                        getIcon(topic?.visibility)}
                    </label>
                    <div ref={topicTagRef}>
                      <label className="UI-topic-header-tag">
                        {tagOrVis()}
                      </label>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center"
              }}
            >
              {hvr && <div className="mute-tooltip">Archived</div>}
            </div>
          </div>
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center"
            }}
          >
            {isArchived && (
              <div
                className={
                  headerSize?.toLowerCase() === "none" ? "mute-icon" : ""
                }
                style={
                  headerSize?.toLowerCase() === "none"
                    ? { display: "none" }
                    : {}
                }
                onMouseEnter={() => setHvr(true)}
                onMouseLeave={() => setHvr(false)}
              >
                <RiInboxArchiveLine
                  size={headerSize?.toLowerCase() === "none" ? 0 : 20}
                />
              </div>
            )}
          </div>
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center"
            }}
          >
            {getMuteIcon() && (
              <span
                style={
                  headerSize?.toLowerCase() === "none"
                    ? { display: "none" }
                    : {
                        height: "100%",
                        display: "flex",
                        alignItems: "center"
                      }
                }
                onMouseEnter={() => setMuteHvr(true)} //??
                onMouseLeave={() => setMuteHvr(false)}
              >
                <IoVolumeMute size={20} />
              </span>
            )}
            {muteHvr && <div className="mute-tooltip">Muted</div>}
          </div>
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {isLabelled && headerSize?.toLowerCase() !== "none" && (
              <LabelTag
                strokeColor="black"
                fillColor={labelColor ? labelColor : "black"}
                holeFillColor="white"
                tagHeight="22"
                tagWidth="22"
              />
            )}
          </div>
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {isFlagged() && headerSize?.toLowerCase() !== "none" && (
              <AiFillStar color={"red"} size={24} />
            )}
          </div>
          {headerSize?.toLowerCase() === "none" ? (
            <li
              className={"UI-topic-dots-menu-no-header"}
              style={{
                fontSize: "1.2rem",
                right: "0",
                marginTop: "1px",
                marginRight: "2px"
              }}
            >
              <BsThreeDotsVertical
                size={20}
                onClick={handleOpen}
                className={"UI-topic-dots-icon"}
              />
              {open && (
                <DropdownTopicMenu
                  roles={
                    topic?.roles
                      ? topic?.roles
                      : ["admin", "owner", "designer", "tx", "whisper", "rx"]
                  }
                  menuItems={menuItems}
                  secMenuItems={secMenuItems}
                  mainscreen={false}
                  onClose={() => setOpen(false)}
                  coords={coords}
                ></DropdownTopicMenu>
              )}
            </li>
          ) : (
            <div
              style={
                headerSize?.toLowerCase() === "none"
                  ? { display: "none" }
                  : {
                      height: "100%",
                      display: "flex",
                      alignItems: "center"
                    }
              }
            >
              <BsThreeDotsVertical onClick={handleOpen} size={20} />
              {open && (
                <DropdownTopicMenu
                  roles={
                    topic?.roles
                      ? topic?.roles
                      : ["admin", "owner", "designer", "tx", "whisper", "rx"]
                  }
                  menuItems={menuItems}
                  secMenuItems={secMenuItems}
                  mainscreen={false}
                  onClose={() => setOpen(false)}
                  coords={coords}
                ></DropdownTopicMenu>
              )}
            </div>
          )}
          <span>
            {topicThemeState && (
              <UICreateTopicModal
                from="uitopicheader"
                topic={topic}
                size={headerSize}
                origHeaderSize={origHeaderSize}
                origAbout={origDescription}
                origLogo={topicState.logo_image}
                preview={props.preview}
                // description={topicDescription}
                onClose={showTopicThemeModal}
                show={topicThemeState}
                callback={props.callback}
                mtopic={topic?.mtopic}
                tag={topic.tag}
                // timeFormatHMS={props.timeFormatHMS}
              />
            )}
          </span>
          {contentModal}
        </div>
      )}
      <div
        className="UI-topic-header-description"
        ref={descriptionRef}
        type={description?.length > 0 ? "" : "none"}
      >
        {description?.length > 0 ? description : ""}
      </div>
    </div>
  );

  return content;
};

export default React.memo(UITopicHeader, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
