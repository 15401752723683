import React, { useContext, useState, useEffect, useRef } from "react";
import { WorldTextInput, convHrsMins, DispTopicHdr } from "../utils/UtilsUniversal"
import TopicsContext from "contexts/contextTopics";
import GlobalContext from "contexts/context";
import { timestamp } from "hooks/helper";
import DatabaseContext from "data/contextDatabase";
import TopicContext from "contexts/contextTopic";
import { FaCaretRight, FaCaretDown } from 'react-icons/fa'
import useDebounce from "hooks/debounce";
import { isMobileTablet } from "hooks/helper";
import { useLiveParticipants } from "hooks/dexHooks";
import { dex_action } from "data/dexUtils";
import { randomString } from "hooks/helper";
import "ui/UI.css";
import StyleContext from "contexts/contextStyle";

const UIParticipants = (props) => {
const { topicsState, topicsDispatch } = useContext(TopicsContext);
const { topicState, topicDispatch } = useContext(TopicContext);
const [useSearch, setSearch] = useState("");
const debouncedSearchTerm = useDebounce(useSearch, 500);
const [isActive, setIsActive] = useState(false);
const [timer, setTimer] = useState(0);
const { globalState } = useContext(GlobalContext);
const { databaseState } = useContext(DatabaseContext);
const [subgroup, setSubgroup] = useState();
const [okToEvict, setOkToEvict] = useState(false);
const { styleState } = useContext(StyleContext);
const [dataSource, setDataSource] = useState({});
const [selectedArr, setSelectedArr] = useState([]);
const [expand, setExpand] = useState([]);
const increment = useRef(null);
const tick = useRef();

useEffect(() => {
  setSelectedArr([]);
}, [topicsState.showTopic?.subscription?.mtopic]);

const mtopicFromProps = props.sub?.mtopic
  ? props.sub?.mtopic
  : topicsState?.showTopic?.subscription
  ? topicsState?.showTopic?.subscription.mtopic
  : undefined;

const liveParticipants = useLiveParticipants(
  databaseState.dexUser,
  mtopicFromProps
  // props.sub.mtopic
);

const menuEvict = (mpersona) => {
  let j = {
    type: "w.t.evict",
    version: globalState.version,
    smid: randomString(8),
    ts_sender: timestamp(),
    mtopic: props.sub.mtopic,
    mpersona: props.sub.mpersona,
    bparty: mpersona
  };
  dex_action({
    type: "DEX_PUT",
    values: {
      db: databaseState.dexUser,
      table: "send",
      doc: j
    }
  });
  let transform = (participantsRec) => {
    let newRec = { ...participantsRec };
    newRec.personas =
      participantsRec.personas?.filter((p) => p.mpersona !== mpersona) || [];
    newRec.count_subs = newRec.personas.length;
    return newRec;
  };
  dex_action({
    type: "DEX_MODIFY_TRANS",
    values: {
      db: databaseState.dexUser,
      table: "participants",
      filter: (i) => i.mtopic === props.sub.mtopic,
      transform: transform
    }
  });
};

useEffect(() => {
  let index = liveParticipants?.personas?.findIndex(
    (x) =>
      x.mpersona?.toLowerCase() === globalState?.persona?.mpersona.toLowerCase()
  );
  if (index >= 0) {
    if (liveParticipants)
      setOkToEvict(
        liveParticipants?.personas[index]?.props?.roles?.some(
          (el) => el === "owner" || el === "admin"
        )
      );
  }
}, [liveParticipants]);

const handleEvict = () => {
  // Only allow eviction while online, so that it is harder for an evicted person to receive a message after eviction
  fetch(process.env.REACT_APP_PERSONA_API_URL, { method: "HEAD" })
    .then((response) => {
      globalState.logging && console.log("response", response);
      if (response.status === 403 && globalState.connected) {
        if (
          window.confirm(
            "Are you sure you want to evict all selected personas?"
          )
        ) {
          selectedArr.map((item) => menuEvict(item.mpersona));
        }
      }
    })
    .catch((e) => window.alert("Evicting users only works while online."));
};

const dateFormat = (s) => {
  let newstr = s.replace(" ", "T");
  const d = new Date(newstr + "Z");
  // const d = new Date(newstr);
  return d.toLocaleString();
};

useEffect(() => {
  const roles = ["owner", "admin", "designer", "tx", "whisper", "rx"];
  let prevMpersona = [];
  roles.map((role) => {
    let newArr = [];
    liveParticipants?.personas?.map((pp) => {
      if (pp.props?.roles?.includes(role)) {
        let arrtime =
          pp?.props?.subscription_state?.read?.timestamp ||
          topicState?.messages?.unpinned.reduce((sd, tm) => {
            if (pp.mpersona === tm.mpersona) {
              let date = dateFormat(tm.ts_origin_server || tm.ts_sender);
              if (!sd || date > sd) {
                sd = date;
              }
            }
            return sd;
          }, undefined);
        if (!prevMpersona.includes(pp.mpersona)) {
          newArr.push({
            persona: pp.persona,
            mpersona: pp.mpersona,
            time: arrtime,
            title: role
          });
          prevMpersona.push(pp.mpersona);
        }
      }
    });
    newArr = newArr.sort((a, b) => {
      // equal items sort equally
      if (b.time === a.time) {
        return 0;
      }
      // nulls sort after anything else
      else if (a.time === null || a.time === undefined) {
        return 1;
      } else if (b.time === null || b.time === undefined) {
        return -1;
      }
      // ascending
      else {
        return b.time < a.time ? -1 : 1;
      }
    });
    newArr = newArr.sort((a, b) => {
      if (a.time === undefined && b.time === undefined) {
        return a.persona.localeCompare(b.persona);
      }
    });
    //set the singleArray/Object here
    //so we have a single object (call it dataSource)
    //owner(role) is a nested object, which contains dataArr and expand(t/f)

    setDataSource((dataSource) => {
      dataSource[role] = {};
      dataSource[role].dataArr = newArr;
      dataSource[role].expand = true;
      return dataSource;
    });
  });
}, [liveParticipants?.personas, topicState?.messages?.unpinned, props]);

useEffect(() => {
  let j = {
    type: "w.t.participants",
    // mtopic: props.sub.mtopic,
    mtopic: props.sub?.mtopic,
    /* as below this props.sub.mtopic must be changed  */
    version: globalState.version,
    smid: `${"participants"}|${props.sub?.mtopic}`,
    ts_sender: timestamp()
  };

  databaseState.dexUser &&
    dex_action({
      type: "DEX_PUT",
      values: {
        db: databaseState.dexUser,
        table: "send",
        doc: { ...j }
      }
    });
}, [databaseState.dexUser]);

const removeSeconds = (str) => {
  //str must be in format time HH:MM:SS.ss it may nahe text before HH
  // anything after the second ':' will disappear
  let newstr = str.replace("T", " ");
  //find pos of last :
  let pos = newstr.split(":", 2).join(":").length;
  newstr = newstr.substring(0, pos);
  return newstr;
};

const arrExpandNew = (role, status) => {
  setDataSource((dataSource) => ({
    ...dataSource,
    [role]: { ...dataSource[role], expand: status }
  }));
};

useEffect(() => {
  if (increment.current) {
    increment.current = !increment.current;
    return;
  }

  if (isActive) {
    tick.current = setInterval(() => {
      // <-- set tick ref current value
      setTimer((timer) => timer + 1);
    }, 1000);
  } else {
    clearInterval(tick.current); // <-- access tick ref current value
  }
  return () => clearInterval(tick.current); // <-- clear on unmount!
}, [isActive]);

useEffect(() => {
  const roles = ["owner", "admin", "designer", "tx", "whisper", "rx"];
  roles.map((role) => {
    if (dataSource[role]?.dataArr?.length > 0)
      setDataSource((dataSource) => ({
        ...dataSource,
        [role]: { ...dataSource[role], expand: true }
      }));
    else
      setDataSource((dataSource) => ({
        ...dataSource,
        [role]: { ...dataSource[role], expand: false }
      }));
  });
}, [debouncedSearchTerm]);

useEffect(() => {
  props.transferSubgroup(selectedArr);
  // if (selectedArr?.length > 0) props.selPrtChk(true)
  // else props.selPrtChk(false)
}, [selectedArr]);

const handleParticipantsList = (item, participant) => {
  //place in selected items list
  setSelectedArr((selectedArr) => [...selectedArr, participant]);
  //no need to remove from the datasource ... handled by filter when displaying
};

const displayParticipantsNew = (item) => {
  let dispTitle = item;
  if (dispTitle === "tx") dispTitle = "send";
  if (dispTitle === "rx") dispTitle = "receive";
  return (
    <div
      style={{ position: "relative" }}
      // className="flex-container"
      // style={{
      //   overflowY: "auto",
      //   maxHeight: `${(props.theMaxHgtBody - 55) / 3}px`,
      //   fontWeight: "normal",
      //   paddingRight: "1rem"
      // }}
    >
      <div
        className={
          dataSource[item].dataArr?.length > 0
            ? "flex-row"
            : // "ui-invite-grid"
              {}
        }
        style={{
          position: "sticky",
          top: "0",
          backgroundColor: "var(--modal_header_background_color)"
        }}
      >
        <div
          className={
            dataSource[item].dataArr?.length > 0 ? "modal-label-new" : {}
          }
          style={
            dataSource[item].dataArr?.length <= 0
              ? { display: "none" }
              : {
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center"
                }
          }
        >
          <button
            style={{
              background: "transparent",
              padding: "0",
              border: "none"
            }}
            onClick={() => {
              arrExpandNew(item, !dataSource[item].expand);
            }}
          >
            {dataSource[item]?.expand ? (
              <FaCaretDown
                style={{
                  color: "var(--menu_button_icon_color)",
                  fontSize: "1.5rem"
                }}
              />
            ) : (
              <FaCaretRight
                style={{
                  color: "var(--menu_button_icon_color)",
                  fontSize: "1.5rem"
                }}
              />
            )}
          </button>
          {dispTitle}
        </div>
        <div
          className={
            dataSource[item].dataArr?.length > 0 ? "modal-label-new" : {}
          }
          style={
            dataSource[item].dataArr?.length > 0 && dataSource[item]?.expand
              ? {
                  marginLeft: "0.625rem",
                  fontSize: "0.9rem"
                }
              : { display: "none" }
          }
        ></div>
      </div>
      {
        dataSource[item].dataArr?.length > 0 &&
          dataSource[item]?.expand &&
          dataSource[item].dataArr
            ?.filter(
              (participant) =>
                !selectedArr
                  .map((s) => s.mpersona)
                  .includes(participant.mpersona)
            )
            .map((participant) => (
              <div
                style={{
                  borderBottom:
                    "1px solid var(--main_screen_topic_separator_line_color)",
                  color:
                    participant?.persona
                      ?.toLowerCase()
                      .includes(debouncedSearchTerm?.toLowerCase()) &&
                    "var(--modal_text_color)",
                  display:
                    !participant?.persona
                      ?.toLowerCase()
                      .includes(debouncedSearchTerm?.toLowerCase()) && "none"
                }}
                // participant?.persona
                //   ?.toLowerCase()
                //   .includes(debouncedSearchTerm?.toLowerCase())
                //   ? { color: "var(--modal_text_color)" }
                //   : { display: "none" }
                // }
                className="flex-row"
                // "ui-invite-grid"
                onClick={() => {}}
                key={"p_" + participant.mpersona}
                id={"p_" + participant.mpersona}
              >
                <div
                  className="flex-cell"
                  style={{
                    fontSize: isMobileTablet() ? "1rem" : "1.1rem",
                    maxWidth: isMobileTablet()
                      ? "40vw"
                      : participant.persona?.length > 40
                      ? "300px"
                      : "fit-content"
                  }}
                >
                  <label
                    style={{
                      color: "var(--modal_text_color)",
                      fontSize: "1.1rem",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      marginRight: "1rem"
                      // cursor: props.roles?.length > 0 && "auto"
                    }}
                  >
                    <input
                      className="checkbox"
                      type="checkbox"
                      onChange={() => {
                        handleParticipantsList(item, participant);
                      }}
                    />
                    {participant.persona}
                  </label>
                </div>
                <div
                  className="flex-cell"
                  style={{
                    fontSize: "0.9rem",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden"
                  }}
                >
                  {participant.time
                    ? removeSeconds(participant.time.toString())
                    : "-"}
                </div>
              </div>
            ))
        // ))
      }
    </div>
  );
};

const handleSelList = (index) => {
  //remove from selected array
  let mp = selectedArr[index].mpersona;
  setSelectedArr((selectedArr) =>
    selectedArr.filter((oa) => oa.mpersona !== mp)
  );
  //no need to return to the datasource ... handled by filter when displaying
};

useEffect(() => {
  let liveMpersonas = liveParticipants?.personas?.map((p) => p.mpersona);
  setSelectedArr((selectedArr) =>
    selectedArr.filter((s) => liveMpersonas.includes(s.mpersona))
  );
  return () => {};
}, [liveParticipants]);

const getCreator = (mtopic) => {
  let topicMatches = topicsState.topics?.filter((t) => t.mtopic === mtopic);
  return {
    creator: topicMatches[0]?.mcreator,
    creatorpersona: topicMatches[0]?.persona
  };
};

const handleWhisper = () => {
  let whispArr = [
    ...selectedArr,
    { persona: props.sub?.persona, mpersona: props.sub?.mpersona }
  ];
  topicDispatch({
    type: "SET_PERSONAS_RX",
    values: { personas_rx: whispArr }
  });
  topicDispatch({
    type: "SET_REPLY_TO",
    values: { reply_to: undefined }
  });
  // props.onClose();
  topicsDispatch({
    type: "SET_WHISPERS",
    values: {
      whispers: {
        mpersona: props.sub.mpersona,
        mtopic: props.sub.mtopic,
        personas_rx: selectedArr
        // .map((item) => item.mpersona)
        // .concat(props.sub.mpersona)
      }
    }
  });
};

let content = (
  <div
    className={props.col3 ? "UI-login-container-col3" : "UI-login-container"}
  >
    {props.col3 && <h2>Participants</h2>}
    <div
      style={{
        fontStyle: "italic",
        marginBottom: "0.25rem"
      }}
    >
      <DispTopicHdr
        topicName={props.sub?.topic || props.sub?.dialog}
        displayName={props.sub?.props?.topic_display_name}
        topicID={props.sub?.mtopic}
        time={
          props.sub?.props?.creationdate
            ? convHrsMins(props.sub?.props?.creationdate)
            : "-"
        }
        creator={
          props.sub?.mtopic ? getCreator(props.sub?.mtopic).creator : "-"
        }
      />
    </div>
    {selectedArr && selectedArr.length > 0 && (
      <div className="modal-label-new" style={{ marginBottom: "0.5rem" }}>
        Selected personas:
        <div
          className="flex-container"
          style={{
            overflowY: "auto",
            maxHeight: `${(props.theMaxHgtBody - 55) / 3}px`,
            fontWeight: "normal",
            paddingRight: "1rem"
          }}
          // className="ui-invite-grid"
          // style={{
          //   fontWeight: "bold",
          //   fontSize: "0.9rem"
          // }}
        >
          {/* <label>Persona</label>
            <label>Read</label>
          </div>
          <div
            style={{
              overflowY: "auto",
              maxHeight: "20vh",
              fontWeight: "normal"
            }}
          > */}
          {selectedArr && selectedArr.length > 0
            ? selectedArr
                .sort((a, b) => a.persona.localeCompare(b.persona))
                .map((item, index) => (
                  <div
                    key={"sp_" + item.mpersona}
                    id={"sp_" + item.mpersona}
                    style={{
                      borderBottom:
                        "1px solid var(--main_screen_topic_separator_line_color)"
                      // overflowY: "scroll"
                    }}
                  >
                    <div
                      className="flex-row"
                      // className="ui-invite-grid"
                    >
                      <div
                        className="flex-cell"
                        style={{
                          maxWidth: isMobileTablet()
                            ? "40vw"
                            : item.persona?.length > 40
                            ? "300px"
                            : "fit-content"
                        }}
                      >
                        <label
                          style={{
                            fontSize: isMobileTablet() ? "1rem" : "1.1rem",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            marginRight: "1rem",
                            cursor: props.roles?.length > 0 && "auto"
                          }}
                          // style={{
                          //   display: "flex",
                          //   flexDirection: "row",
                          //   alignItems: "flex-start",
                          //   fontWeight: "bold",
                          //   fontSize: "1rem"
                          // }}
                        >
                          <input
                            className="checkbox"
                            type="checkbox"
                            checked={true}
                            onChange={() => {
                              handleSelList(index);
                            }}
                          />
                          {item.persona}
                        </label>
                      </div>
                      <div
                        className="flex-cell"
                        style={{
                          fontSize: "0.9rem",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden"
                        }}
                      >
                        {item.time && removeSeconds(item.time.toString())}
                      </div>
                    </div>
                  </div>
                ))
            : ""}
        </div>
      </div>
    )}
    <WorldTextInput
      identity="participantsearch"
      // ref={inviteBoxRef}
      focus={isMobileTablet() ? false : true}
      // labelStyle={{
      //   fontWeight: "normal",
      // }}
      type="text"
      // title={tagTooltip}
      callback={(e) => setSearch(e)}
      // label={"Tap on names to invite"}
      // required={true}
      placeholder={"Search for..."}
      description={
        debouncedSearchTerm?.length > 0 || dataSource ? "Select from list" : ""
      }
      minRows={1}
      maxRows={1}
      // minStrLen={3}
      // maxStrLen={10}
      // active={worldTestTA?.length > 0 ? "true" : "false"}
      value={useSearch}
      // regex={/^[\w\ ]+$/i}
    />
    {isActive && (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>Loading...</span>
        {timer}
        <div className="spinner"></div>
      </div>
    )}
    {/* {isActive && (
        <div>
          <span>Loading...</span>
          {timer}
        </div>
      )} */}
    <div
      className="flex-row"
      // className="ui-invite-grid"
      style={{
        fontWeight: "bold",
        fontSize: "1.1rem"
      }}
    >
      <header>Persona</header>
      <header style={{ marginRight: "2rem" }}>Last read</header>
      {/* <label>Persona</label>
        <label>Read</label> */}
    </div>
    <div
      style={{
        overflowY: "auto",
        // maxHeight: `${
        //   props.theMaxHgtBody - 250 - Math.min(selectedArr?.length * 40, 5 * 40)
        // }px`,
        maxHeight: `${(props.theMaxHgtBody - 55) / 2}px`,
        fontWeight: "normal",
        paddingRight: "1rem"
      }}
    >
      {Object.keys(dataSource).map((item) => displayParticipantsNew(item))}
    </div>
    {props.col3 && (
      <div>
        <button
          // className={"modal-button-new"}
          className="UI-button-service"
          disabled={selectedArr?.length <= 0}
          style={
            okToEvict
              ? {
                  marginTop: "0.5rem",
                  marginRight: "0.5rem"
                }
              : { display: "none" }
          }
          type="button"
          onClick={handleEvict}
        >
          Evict
        </button>
        <button
          className={"UI-button-service"}
          disabled={selectedArr?.length <= 0}
          style={{
            marginTop: "0.5rem"
          }}
          type="button"
          onClick={() => {
            handleWhisper();
            // topicDispatch({
            //   type: "SET_PERSONAS_RX",
            //   values: { personas_rx: selectedArr }
            // });
            // topicDispatch({
            //   type: "SET_REPLY_TO",
            //   values: { reply_to: undefined }
            // });
            // // props.onClose();
            // topicsDispatch({
            //   type: "SET_WHISPERS",
            //   values: {
            //     whispers: {
            //       mpersona: props.sub.mpersona,
            //       mtopic: props.sub.mtopic,
            //       personas_rx: selectedArr
            //       // .map((item) => item.mpersona)
            //       // .concat(props.sub.mpersona)
            //     }
            //   }
            // });
          }}
        >
          Whisper
        </button>
      </div>
    )}
  </div>
);

  return content;
};

export default React.memo(UIParticipants, (prevProps, nextProps) => {
  return prevProps === nextProps;
});


