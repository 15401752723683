import React from 'react'
import Shimmer from './Shimmer'
import SkeletonElement from './SkeletonElement'

function SkeletonImage(props) {
  return (
    <div
      className='skeleton-wrapper'>
      <div
        className='skeleton-image'>
        <SkeletonElement
          myStyle={props.myStyle}
          image="true"
          type={props.type}
          // "image"
          width={props.width}
          height={props.height}
          background={props.background}
        />
      </div>
      <Shimmer />
    </div>
  )
}

export default SkeletonImage
