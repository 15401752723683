import React, { useState, useContext, useEffect } from "react";
import { FaChevronRight } from "react-icons/fa";
import "ui/UIPersonaCard.css";
import IconDelete from "ui/Dropdown/iconDelete";
import GlobalContext from "contexts/context";
import plusLogo from "./icons/plusonly.png";
import PersonasContext from "contexts/contextPersonas";
import GetInitials from "../utils/GetInitials";
import logo from "../../src/person_outline2.png";

const CardHeader = (props) => {
  const { globalState, globalDispatch } = useContext(GlobalContext);
  const { personasState } = useContext(PersonasContext);
  const { preTitle, title, image, size } = props.header;

  let mpersona = props?.header?.item?.mpersona;
  const enlargeImg = (img) => {
    if (img) {
      img.style.transform = `scale(${56 / 48})`;
      // Animation effect
      img.style.transition = "transform 0.25s ease";
      img.style.filter = "grayscale(0)";
    }
  };

  const reduceImg = (img) => {
    if (img) {
      img.style.transform = `scale(${1})`;
      // Animation effect
      img.style.transition = "transform 0.25s ease";
      img.style.filter = "grayscale(1)";
    }
  };
  useEffect(() => {
    if (props.header?.selected === "false" || props.header?.selected === false)
      reduceImg(document.getElementById(props.header?.item?.mpersona));
    else enlargeImg(document.getElementById(props.header?.item?.mpersona));
  }, [props.header?.selected]);

  const handleThisOnClick = () => {
    let newPers = personasState.personas?.filter(
      (persona) => persona.mpersona === props.header?.item?.mpersona
    );
    if (newPers[0])
      globalDispatch({
        type: "SET_PERSONA",
        values: { persona: newPers[0] }
      });
  };

  useEffect(() => {
    if (globalState.persona?.mpersona === props.header.item?.mpersona)
      enlargeImg(document.getElementById(props.header?.item?.mpersona));
  }, []);

  return (
    <header id={"header_" + mpersona}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center"
        }}
      >
        <div
          className="dummy-wrapper-position-img-and-topiccnt"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative"
          }}
        >
          <div
            id={mpersona}
            className={image !== "logo" ? "persona-card-header-wrapper" : {}}
            style={
              {
                position: "relative",
                filter:
                  globalState.persona?.mpersona === props.header.item?.mpersona
                    ? "grayscale(0)"
                    : "grayscale(1)"
              }
              // globalState.persona?.mpersona === props.header.item?.mpersona
              //   ? {
              //       position: "relative",
              //       filter: "grayscale(0)",
              //       zIndex: "99999"
              //     }
              //   : {
              //       position: "relative",
              //       filter: "grayscale(1)",
              //       zIndex: "99999"
              //     }
            }
            onClick={() => handleThisOnClick()}
          >
            {image !== "logo" ||
            image === "profile" ||
            props?.header?.item?.id === "btn_add_persona" ? (
              <img
                id={"image_" + mpersona}
                className="persona-card-header-unselected"
                src={
                  props?.header?.item?.mpersona === ""
                    ? plusLogo
                    : image === "profile"
                    ? logo
                    : image
                }
                alt="Logo"
                height={40}
                style={{
                  zIndex: "5",
                  width: image === "profile" ? "2rem" : {},
                  height: image === "profile" ? "2rem" : {}
                }}
              />
            ) : (
              <div
                style={{
                  border: "var(--main_header_image_border_color) 2px solid",
                  borderRadius: "50%",
                  width: "3.5rem",
                  height: "3.5rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                  // transform: "translate(0px -8px)"
                }}
              >
                <GetInitials
                  type="persona"
                  head_list={false}
                  str={props?.header?.item?.persona}
                ></GetInitials>
              </div>
            )}
          </div>
          <div
            className={
              props?.header?.item?.topicCnt
                ? "persona-topic-count"
                : "persona-topic-count-invisible"
            }
            style={{
              position: "absolute",
              zIndex: "10",
              top: "0",
              right: "0"
            }}
          >
            {props?.header?.item?.topicCnt
              ? props?.header?.item?.topicCnt
              : "0"}
          </div>
        </div>
      </div>
      <p className="persona-card-header--pre-title" type={size}>
        {preTitle || ""}
      </p>
      {title && (
        <h4 className="persona-card-header--title" type={size}>
          {title}
        </h4>
      )}
    </header>
  );
};

const Button = (props) => {
  const { button, size } = props;
  return (
    <button
      className="persona-button button-primary"
      type={size}
      onClick={button?.onClick}
    >
      <i>
        <FaChevronRight />
      </i>
      {button.text}
    </button>
  );
};

const CardBody = (props) => {
  const { body, size, background_Colour } = props;
  const { title, text, button } = body;
  return (
    <div
      className="persona-card-body"
      type={size}
      style={{ backgroundColor: background_Colour }}
    >
      <h2 className={props.selected ? "persona_name_selected" : "persona_name"}>
        {title || ""}
      </h2>
    </div>
  );
};

const UIPersonaCard = (props) => {
  const { header, body, content, size, onClick } = props;
  let menuItems = [
    {
      icon: <IconDelete />,
      callback: () =>
        props.onDeletePersona(props?.item?.persona, props?.item?.mpersona)
    }
  ];

  let article = (
    <div>
      <article
        className={
          props.selected ? "persona-card persona-card-selected" : "persona-card"
        }
        type={size}
        //   {...useLongPress(() => props.item.onLongPress(), 2000)}
        onClick={
          props.id === "profile"
            ? {}
            : props.selected
            ? props?.item?.onSecondClick
            : props?.item?.onClick
        }
      >
        {header && <CardHeader header={{ size: size, ...header, ...props }} />}
        {body && <CardBody body={body} size={size} selected={props.selected} />}
        {content}
      </article>
      {/* <IconDropDown className=".UI-icondropdown" menuItems={menuItems} /> */}
    </div>
  );

  return article;
};

export default UIPersonaCard;
