import React, { useEffect, useContext, useMemo, useState } from "react";

import UserContext from "contexts/contextUser";
import GlobalContext from "contexts/context";
import DatabaseContext from "data/contextDatabase";
import WebsocketContext from "connectivity/contextWebsocket";
import LocationContext from "geospatial/contextLocation";
import PersonasContext from "contexts/contextPersonas";

import { assignDescriptor, digestCalc } from "data/descriptors";
import { requestMissingDialogMessages } from "utils/requestMissingMessages";
import { randomString, timestamp, timestampZero } from "hooks/helper";
import { workerGet } from "workers/interfaceRest";
import { moveAttachments } from "utils/attach";
import { isHide, isRead, lastReadMessage } from "utils/isRead";
import { dex_action } from "data/dexUtils";
import { useLiveQuery } from "dexie-react-hooks";
import { removeMatchingObjects } from "utils/removeDuplicateObjects";
/**
 *
 * @param {*} props
 * must include wss target url
 */

/**
 * TODO:
 * Define interface for API and callbacks
 */

const Wss = (props) => {
  const { userState } = useContext(UserContext);
  const { globalState, globalDispatch } = useContext(GlobalContext);
  const { personasState } = useContext(PersonasContext);
  const { databaseState } = useContext(DatabaseContext);
  const { websocketDispatch } = useContext(WebsocketContext);
  const { locationState } = useContext(LocationContext);
  const [localSend, setLocalSend] = useState([]);

  const liveSendMessages = useLiveQuery(
    () => databaseState.dexUser && databaseState.dexUser.send.toArray((d) => d),
    [databaseState.dexUser],
    []
  );

  const liveHideArchived = useLiveQuery(
    () =>
      databaseState.dexUser &&
      databaseState.dexUser.account.get("hideArchived"),
    [databaseState.dexUser]
  );

  useEffect(() => {
    setInterval(() => {
      setLocalSend((localSend) => [...(localSend || [])]);
    }, 5000);
  }, []);

  useEffect(() => {
    let mounted = true;
    setLocalSend((localSend) => {
      let newMessages = liveSendMessages
        ? removeMatchingObjects(liveSendMessages || [], localSend || [])
        : localSend;
      return (liveSendMessages && newMessages?.length > 0) ||
        localSend?.length !== liveSendMessages?.length
        ? liveSendMessages
        : localSend;
    });
    return () => {
      mounted = false;
    };
  }, [liveSendMessages, localSend]);

  useEffect(() => {
    let mounted = true;
    if (
      globalState.authenticated &&
      localSend &&
      mounted &&
      localSend.length > 0
    ) {
      globalState.logging &&
        console.log("[LiveData] SENDLIST", liveSendMessages);
      websocketDispatch({
        type: "SENDLIST",
        values: { messageList: [localSend[0]] }
      });
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localSend, globalState.authenticated]);

  useEffect(() => {
    userState?.parsedToken?.worldid &&
      globalDispatch({
        type: "SET_UID",
        values: {
          uid: userState?.parsedToken?.worldid
        }
      });
  }, [globalDispatch, userState?.parsedToken?.worldid]);

  useEffect(() => {
    // if authenticated, request a w.t.list every 5 minutes
    // note that this is also done every 30 minutes by the service worker
    const t_list = () => {
      let sj = {
        type: "w.t.list",
        version: globalState.version,
        url: process.env.REACT_APP_URL,
        concise: "false",
        smid: "w.t.list" //randomString(8),
      };
      databaseState.dexUser &&
        dex_action({
          type: "DEX_PUT",
          values: {
            db: databaseState.dexUser,
            table: "send",
            doc: sj
          }
        });
    };

    let lister = globalState.authenticated && setInterval(t_list, 300000);
    return () => {
      clearInterval(lister);
    };
  }, [
    // databaseDispatch,
    databaseState.dexUser,
    globalState.authenticated,
    globalState.version
  ]);

  useEffect(() => {
    const ping = () => {
      websocketDispatch({
        type: "PING",
        values: {}
      });
    };
    let pinger;
    let isMounted = true;

    // when derivedLocalKey changes
    // updates the onMessage function
    // to include response, encryption and storage to database
    const respond = (jMessage) => {
      // console.log("NOTIFY RESPOND", Date.now(), jMessage);
      let response;
      switch (jMessage.type) {
        case "w.transact.banks":
          response = {
            type: "w.obj.received",
            msgid: jMessage.msgid,
            version: globalState.version
          };
          websocketDispatch({
            type: "SEND",
            values: {
              jmessage: response
            }
          });
          break;
        case "w.transact.otp.result":
          response = {
            type: "w.obj.received",
            msgid: jMessage.msgid,
            version: globalState.version
          };
          websocketDispatch({
            type: "SEND",
            values: {
              jmessage: response
            }
          });
          break;
        case "w.t.msg":
          globalState.logging && console.log("[Wss] w.t.msg", jMessage);
          let state = {
            delivered: {
              msg_idx: jMessage.msg_idx,
              timestamp: timestamp()
            }
          };
          let j = {
            type: "w.t.read",
            smid: `read_${jMessage.mtopic}_${jMessage.recipient}_${jMessage.msg_idx}`,
            ts_sender: timestamp(),
            version: globalState.version,
            subscription_states: [
              {
                mtopic: jMessage.mtopic,
                mpersona: jMessage.recipient,
                state: state
              }
            ]
          };
          websocketDispatch({
            type: "SEND",
            values: {
              jmessage: j
            }
          });
          // response = {
          //   type: "w.topic.message.received",
          //   msgid: jMessage.msgid,
          //   version: globalState.version
          // };
          // websocketDispatch({
          //   type: "SEND",
          //   values: {
          //     jmessage: response
          //   }
          // });
          break;
        case "w.topic.message":
          response = {
            type: "w.topic.message.received",
            msgid: jMessage.msgid,
            version: globalState.version
          };
          websocketDispatch({
            type: "SEND",
            values: {
              jmessage: response
            }
          });
          break;
        case "w.mpersona.message":
          response = {
            type: "w.mpersona.message.received",
            msgid: jMessage.msgid,
            version: globalState.version
          };
          websocketDispatch({
            type: "SEND",
            values: {
              jmessage: response
            }
          });
          break;
        case "w.topic.background.share":
          response = {
            type: "w.topic.message.received",
            msgid: jMessage.msgid,
            version: globalState.version
          };
          websocketDispatch({
            type: "SEND",
            values: {
              jmessage: response
            }
          });
          break;
        case "w.mpersona.background.share":
          response = {
            type: "w.topic.message.received",
            msgid: jMessage.msgid,
            version: globalState.version
          };
          websocketDispatch({
            type: "SEND",
            values: {
              jmessage: response
            }
          });
          break;
        default:
          // response = {
          //   type: "w.topic.message.received",
          //   msgid: jMessage.msgid ? jMessage.msgid : "undefined",
          //   version: globalState.version
          // };
          // websocketDispatch({
          //   type: "SEND",
          //   values: {
          //     jmessage: response
          //   }
          // });
          // response = {
          //   type: "w.obj.received",
          //   msgid: jMessage.msgid ? jMessage.msgid : "undefined",
          //   version: globalState.version
          // };
          // websocketDispatch({
          //   type: "SEND",
          //   values: {
          //     jmessage: response
          //   }
          // });
          break;
      }
    };

    const sortJoin = (A, B, Delim) => {
      return A < B ? A + Delim + B : B + Delim + A;
    };
    const onMessage = async (m) => {
      let message = m.data;
      globalState.logging && console.log("WSS onMessage", message);

      clearInterval(pinger);
      pinger = setInterval(() => {
        ping();
        globalState.logging && console.log("ping 2");
      }, 120000);

      if (m.currentTarget.readyState === 1) {
        switch (message) {
          case "Authenticated":
            sessionStorage.removeItem("tasklist");
            globalDispatch({
              type: "SET_AUTH",
              values: { authenticated: true }
            });

            const urlPersona = process.env.REACT_APP_PERSONA_API_URL;
            const keyPersona = process.env.REACT_APP_PERSONA_API_KEY;
            globalState.logging &&
              console.log("JWT! pre", {
                type: "getJWT",
                uid: globalState.uid,
                pwd: globalState.password,
                token: userState?.accessToken,
                entity: "muid",
                msgid: randomString(8),
                version: globalState.version
              });
            workerGet(urlPersona, keyPersona, {
              type: "getJWT",
              uid: globalState.uid,
              pwd: globalState.password,
              token: userState?.accessToken || globalState.jwtLongExpiry,
              passwordless: globalState.jwtLongExpiry && "true",
              entity: "muid",
              msgid: randomString(8),
              version: globalState.version
            })
              .then((response) => {
                globalState.logging &&
                  console.log("JWT! response", response?.result?.jwt);
                if (isMounted && response?.result?.jwt) {
                  dex_action({
                    type: "DEX_UPSERT_MATCH",
                    values: {
                      db: databaseState.dexAdmin,
                      table: "store",
                      match: { key: "user" },
                      function: (val) => {
                        return response?.result?.jwt &&
                          response?.result?.jwt !== val?.jwt
                          ? { ...val, jwt: response?.result?.jwt }
                          : val;
                      }
                    }
                  });
                }
              })
              .catch((err) => {
                console.log("CATCH JWT", err);
              });

            // send "w.t.list"
            let sj = {
              type: "w.t.list",
              version: globalState.version,
              url: process.env.REACT_APP_URL,
              concise: "false",
              smid: "w.t.list" //randomString(8),
            };
            // Do not trigger the w.t.list on Authentication, rather rely on the r_t_list in ListFetcher
            false &&
              databaseState.dexUser &&
              dex_action({
                type: "DEX_PUT",
                values: {
                  db: databaseState.dexUser,
                  table: "send",
                  doc: sj
                }
              });
            break;
          case "Confirmation received":
            break;
          default:
            try {
              const jMessage = JSON.parse(message);
              if (jMessage.smid) {
                jMessage.smid = "" + jMessage.smid;
              }
              switch (jMessage.type) {
                case "w.user.personalist":
                  // Support for an acknowledgement to the server is still required
                  globalState.logging &&
                    console.log("w.user.personalist", jMessage);
                  delete jMessage.type;
                  jMessage.list === undefined
                    ? console.log()
                    : dex_action({
                        type: "DEX_PUT_IF_DIFF",
                        values: {
                          db: databaseState.dexUser,
                          table: "account",
                          primaryKey: { type: "personas" },
                          doc: { type: "personas", value: jMessage.list }
                        }
                      });
                  break;
                case "w.user.indices":
                  // Support for an acknowledgement to the server is still required
                  // requestMissingDialogMessages(
                  //   databaseState.dbMessages,
                  //   databaseDispatch,
                  //   jMessage.indices
                  // );
                  break;
                case "w.admin.parameter.setstring":
                  // Support for an acknowledgement to the server is still required
                  dex_action({
                    type: "DEX_PUT",
                    values: {
                      db: databaseState.dexUser,
                      table: "parameters",
                      doc: {
                        muid: globalState.muid,
                        key: jMessage.parameter,
                        value: jMessage.value
                      }
                    }
                  });
                  break;
                case "w.admin.parameter.delete":
                  // Support for an acknowledgement to the server is still required
                  dex_action({
                    type: "DEX_DELETE_RECORD", //TODO: DEX
                    values: {
                      db: databaseState.dexUser,
                      table: "parameters",
                      doc: jMessage
                    }
                  });
                  break;
                case "w.user.otp.result":
                  respond(jMessage);
                  dex_action({
                    type: "DEX_PUT",
                    values: {
                      db: databaseState.dexUser,
                      table: "message",
                      doc: { ...jMessage, mtopic: "otp" },
                      notify: false
                    }
                  });
                  break;
                case "w.confirmed":
                  //console.log("[Wss] w.confirmed", jMessage);
                  if (jMessage.from !== "w.t.msg")
                    dex_action({
                      type: "DEX_DELETE_RECORD",
                      values: {
                        db: databaseState.dexUser,
                        table: "send",
                        primaryKey: jMessage.smid
                      }
                    });
                  else {
                  }
                  break;
                case "w.success":
                  globalState.logging &&
                    console.log("[Wss] w.success", jMessage);
                  // databaseDispatch({
                  //   type: "DELETE_RECORD",
                  //   values: {
                  //     db: databaseState.dbMessages,
                  //     collection: "send",
                  //     table: "confirm_smid",
                  //     rowid: jMessage.smid,
                  //     doc: jMessage
                  //   }
                  // });
                  dex_action({
                    type: "DEX_DELETE_RECORD",
                    values: {
                      db: databaseState.dexUser,
                      table: "send",
                      primaryKey: jMessage.smid
                    }
                  });
                  break;
                case "w.failed":
                  globalState.logging &&
                    console.log("[Wss] message failure", jMessage);
                  break;
                case "w.t.msg":
                  globalState.logging && console.log("[Wss] w.t.msg", jMessage);
                  jMessage.control &&
                    (jMessage.control.ts_received = timestampZero());
                  respond(jMessage);
                  let mpersonas =
                    personasState.personas &&
                    personasState.personas.map((p) => p?.mpersona);
                  mpersonas &&
                    lastReadMessage(
                      databaseState.dexUser,
                      jMessage.mtopic,
                      jMessage.recipient
                    )
                      .then((latest) => {
                        jMessage.msg_idx = parseInt(jMessage.msg_idx, 10);
                        jMessage.read = isRead(jMessage, mpersonas, latest);
                        jMessage.hide = isHide(jMessage);
                        let cb = dex_action({
                          type: "DEX_UPDATE_TOPIC_METADATA",
                          values: {
                            db: databaseState.dexUser,
                            jMessage: jMessage
                          }
                        });
                        dex_action({
                          type: "DEX_UPSERT_KEYS_LATEST_TRANS",
                          values: {
                            db: databaseState.dexUser,
                            table: "message",
                            latest_key: "msg_idx",
                            match_keys: ["mtopic", "recipient", "smid"],
                            doc: {
                              ...jMessage,
                              time: Date.now(),
                              key: `${jMessage.mtopic}|${jMessage.recipient}|${jMessage.smid}`
                            },
                            cb: cb
                          }
                        });

                      })
                      .catch((err) => console.log("lastread err", err));
                  // });
                  break;
                case "w.user.descriptors":
                  // no response needed
                  jMessage.descriptors &&
                    dex_action({
                      type: "DEX_PUT_IF_DIFF",
                      values: {
                        db: databaseState.dexUser,
                        table: "user",
                        primaryKey: { key: "descriptors" },
                        doc: { key: "descriptors", value: jMessage.descriptors }
                      }
                    });
                  // if (jMessage.descriptors) {
                  //   let descriptorPromises = jMessage.descriptors.map(
                  //     (d) =>
                  //       d?.mpersona &&
                  //       d?.digest &&
                  //       digestCalc(jMessage.muid + d.mpersona + d.digest)
                  //         .then((check) => {
                  //           if (check === d.check) {
                  //             assignDescriptor(
                  //               databaseState.dexUser,
                  //               d.mpersona,
                  //               d.digest
                  //             );
                  //           } else {
                  //             console.log("[Wss] check failed", check, d);
                  //           }
                  //         })
                  //         .catch((err) => {
                  //           console.log("CATCH", err);
                  //         })
                  //   );
                  //   Promise.all(descriptorPromises);
                  // }
                  // check

                  break;
                case "w.t.list":
                  globalState.logging && console.log("w.t.list", jMessage);
                  respond(jMessage);
                  jMessage.muid &&
                    jMessage.muid !== globalState.muid &&
                    globalDispatch({
                      type: "SET_MUID",
                      values: { muid: jMessage.muid }
                    });

                  jMessage.muid &&
                    dex_action({
                      type: "DEX_UPSERT_MATCH",
                      values: {
                        db: databaseState.dexAdmin,
                        table: "store",
                        match: { key: "user" },
                        function: (val) => {
                          let v =
                            jMessage.muid && jMessage.muid !== val?.muid
                              ? { ...val, muid: jMessage.muid }
                              : val;
                          return v;
                        }
                      }
                    });
                  jMessage.topics &&
                    dex_action({
                      type: "DEX_PUT_IF_DIFF",
                      values: {
                        db: databaseState.dexUser,
                        table: "account",
                        primaryKey: { type: "topics" },
                        doc: {
                          type: "topics",
                          value: liveHideArchived?.value
                            ? jMessage.topics.filter(
                                (t) => t.subprops?.archived !== "true"
                              )
                            : jMessage.topics,
                          // jMessage.topics
                          // .sort((a, b) =>
                          //   a.last_visible_date > b.last_visible_date ? -1 : 1
                          // )
                          // .splice(
                          //   0,
                          //   parseInt(
                          //     localStorage.getItem("numTopics") || 100000
                          //   )
                          // ),
                          time: Date.now()
                        }
                      }
                    });

                  break;
                case "w.user.fcmtime.list":
                  respond(jMessage);
                  dex_action({
                    type: "DEX_PUT_IF_DIFF",
                    values: {
                      db: databaseState.dexUser,
                      table: "account",
                      primaryKey: { type: "mute" },
                      doc: { type: "mute", value: jMessage.fcm }
                    }
                  });
                  break;

                case "w.t.participants":
                  //console.log("w.t.participants", jMessage);
                  respond(jMessage);

                  delete jMessage.origin_sender;
                  delete jMessage.ts_origin_server;
                  delete jMessage.ts_sender;
                  delete jMessage.ts_server;
                  delete jMessage.ts_origin_server;
                  delete jMessage._id;
                  delete jMessage._rev;
                  delete jMessage.smid;
                  delete jMessage.origin;
                  delete jMessage.origin_sender;

                  dex_action(
                    // {
                    //   type: "DEX_PUT_IF_DIFF",
                    //   values: {
                    //     db: databaseState.dexUser,
                    //     table: "participants",
                    //     primaryKey: { mtopic: jMessage.mtopic },
                    //     doc: jMessage
                    //   }
                    // }

                    {
                      type: "DEX_PUT",
                      values: {
                        db: databaseState.dexUser,
                        table: "participants",
                        doc: jMessage
                      }
                    }
                  );
                  break;
                case "...":
                  globalState.logging && console.log("...: ", jMessage);
                  break;
                default:
                  respond(jMessage);
              }
            } catch (err) {
              console.log(err);
              // console.log("unhandled message: ", message);
            }
        }
      }
    };
    // derivedLocalKey === null ||
    globalState.muid === null ||
    globalState.muid === undefined ||
    databaseState.dexAdmin === null || // TODO: check that the dexUSer database name is correct
    databaseState.dbReady !== true
      ? console.log(
          "WebSocket message not yet ready",
          globalState.muid,
          databaseState
        )
      : websocketDispatch({
          type: "SET_ONMESSAGE",
          values: {
            onmessage: onMessage
          }
        });
    return () => {
      clearInterval(pinger);
      isMounted = false;
    };
  }, [
    // databaseState.dexUser,
    // databaseState.dexAdmin,
    globalState.uid,
    globalState.authenticated,
    globalState?.prefs?.clientMute,
    globalState.muid,
    personasState.personas,
    databaseState.dbReady,
    globalState.version,
    globalState.displaying_topic,
    liveHideArchived?.value
  ]);

  useEffect(() => {
    const ping = () => {
      websocketDispatch({
        type: "PING",
        values: {}
      });
    };

    let pinger;

    const onOpen = () => {
      globalState.logging && console.log("AUTH_TOKEN", userState.accessToken);
      globalDispatch({
        type: "SET_AUTH",
        values: { authenticated: false }
      });
      // send a message
      var j =
        userState.accessToken || globalState.jwtLongExpiry
          ? {
              type: "w.user.authenticate",
              cid: globalState.cid,
              imei: "",
              lat: "" + locationState.latitude,
              lon: "" + locationState.longitude,
              version: globalState.version,
              token: userState.accessToken || globalState.jwtLongExpiry,
              passwordless: "true"
            }
          : {
              type: "w.user.authenticate",
              uid: globalState.uid,
              pwd: globalState.password,
              cid: globalState.cid,
              imei: "",
              lat: "" + locationState.latitude,
              lon: "" + locationState.longitude,
              version: globalState.version
            };
      try {
        let task_list = JSON.parse(sessionStorage.getItem("tasklist"));
        if (task_list !== null && task_list !== undefined) {
          j.task_list = task_list;
        }
      } catch {}
      websocketDispatch({
        type: "SEND",
        values: {
          jmessage: j
        }
      });
    };

    const onClose = () => {
      globalState.logging && console.log("WebSocket closed: ");
      clearInterval(pinger);
      // globalDispatch({
      //   type: "SET_AUTH",
      //   values: { authenticated: false },
      // });
      // !globalState.connected
      //   ? console.log("Not connected")
      //   :
      websocketDispatch({
        type: "OPEN",
        values: {
          url: props.url,
          onopen: onOpen,
          onclose: onClose,
          onerror: onError,
          onmessage: onMessage
        }
      });
    };

    const onError = (e) => {
      console.log("WebSocket error: ", e);
    };

    const onMessage = (m) => {
      // console.log("WebSocket message: ", m);

      clearInterval(pinger);
      pinger = setInterval(() => {
        ping();
        globalState.logging && console.log("ping 1");
      }, 120000);

      if (m.data === "Authenticated") {
        sessionStorage.removeItem("tasklist");
        globalDispatch({
          type: "SET_AUTH",
          values: { authenticated: true }
        });
      }
    };

    if (
      (localStorage.getItem("use_keycloak") === "true" &&
        !userState.accessToken) ||
      (localStorage.getItem("use_keycloak") !== "true" &&
        globalState.password === undefined &&
        globalState.jwtLongExpiry === undefined) ||
      globalState.uid === null ||
      globalState.uid === undefined ||
      globalState.cid === undefined || // TODO: check dex database name matches
      databaseState.dbReady !== true
    ) {
    } else {
      websocketDispatch({
        type: "CLOSE"
      });

      websocketDispatch({
        type: "OPEN",
        values: {
          url: props.url,
          onopen: onOpen,
          onclose: onClose,
          onerror: onError,
          onmessage: onMessage
        }
      });
    }

    return () => {
      clearInterval(pinger);
    };
  }, [
    // globalDispatch,
    globalState.password,
    globalState.uid,
    globalState.cid,
    globalState.jwtLongExpiry,
    // databaseState.dexAdmin,
    // databaseState.dexUser,
    // props.url,
    // websocketDispatch,
    databaseState.dbReady,
    globalState.version,
    // locationState.latitude,
    // locationState.longitude,
    userState.accessToken
  ]);

  let content = <div></div>;
  return content;
};

export default React.memo(Wss, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
