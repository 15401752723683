import React, { useState, useEffect, useContext, useMemo, useRef } from "react";

import "ui/UI.css";
import "ui/serviceMsg.css";
// import DateButton from "./DateButton";
import DateSelector from "./DateSelector";
import Modals from "./Modals";
import "./modals.css";
import { useHistory } from "react-router-dom";
import TopicsContext from "contexts/contextTopics";
import ServiceMessageContext from "contexts/contextServiceMessage";
import Linkify from "linkify-react";
import { linkProps } from "utils/urlTools";
import { getAll, sharedStart, MarkdownComponent } from "utils/textUtils";
import ServiceDropDown from "./ServiceDropDown";
import ServiceCheckBoxes from "./ServiceCheckBoxes";
import { shouldLog } from "utils/shouldLog";
import { isMobileTablet } from "hooks/helper";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import StyleContext from "contexts/contextStyle";

const isEqual = require("react-fast-compare");

const ServiceModal = (props) => {
  const [body, setBody] = useState(undefined);
  const [clickOutsideActive, setClickOutsideActive] = useState(true);
  const { svcMsgState, svcMsgDispatch } = useContext(ServiceMessageContext);
  // const [header, setHeader] = useState("");
  const [modalClose, setModalClose] = useState(false);
  const [outClick, setOutClick] = useState(false);
  const ref_msg_text = useRef(null);
  const [rows, setRows] = useState(1);
  const [minRows, setMinRows] = useState(1);
  const [maxRows, setMaxRows] = useState(5);
  const { topicsDispatch } = useContext(TopicsContext);
  const [btnArr, setBtnArr] = useState([]);
  const { styleState } = useContext(StyleContext);
  const titleStyle = {
    fontSize: "var(--service_message_fontsize)"
  };
  const [firstSet, setFirstSet] = useState(true);
  const [depsOK, setDepsOK] = useState([]);
  let history = useHistory();
  // footerbuttons not used but can be set as shown in commented code
  // const [footerButtons, setFooterButtons] = useState(null)
  let act = props.act;
  let liveTopics = props.liveTopics;
  // const [modalhdr,setModalhdr]=useState(props.header)

  useEffect(() => {
    const textareaLineHeight = 18;

    if (ref_msg_text?.current) {
      ref_msg_text.current.style.height = "auto";
      // const textareaLineHeight = 18;
      const previousRows = ref_msg_text.current.rows;
      ref_msg_text.current.rows = minRows; // reset number of rows in textarea

      const currentRows = ~~(
        ref_msg_text.current.scrollHeight / textareaLineHeight
      );

      if (currentRows === previousRows) {
        ref_msg_text.current.rows = currentRows;
      }
      if (currentRows >= maxRows) {
        ref_msg_text.current.rows = maxRows;
        ref_msg_text.current.scrollTop = ref_msg_text.current.scrollHeight;
      }
      setRows(currentRows < maxRows ? currentRows : maxRows);
      return () => {};
    }
  }, [ref_msg_text?.current?.value]);

  useEffect(() => {
    // Reset height - important to shrink on delete
    // setIsEdit(msgObject.msg_idx);
    if (ref_msg_text?.current?.value) {
      ref_msg_text.current.style.height = `${Math.min(
        ref_msg_text.current?.scrollHeight,
        90
      )}px`;
      ref_msg_text.current?.focus();
    }
  }, [ref_msg_text?.current?.value]);

  const idNoSmid = useMemo(
    () => (r) =>
      r.id?.startsWith(r.smid + "_") ? r.id.replace(r.smid + "_", "") : r.id,
    []
  );

  const matching = useMemo(
    () => (response, item) => {
      let r = { ...response };
      r.smid = props.message.smid;
      let origId = idNoSmid(r);
      return origId === item.id;
    },
    []
  );

  const filterResult = useMemo(
    () => (r, item, event) => {
      let origId = idNoSmid(r);
      if (r.id && item.reset && item.reset.length > 0) {
        let noRegexMatch = item.reset.reduce((acc, pattern) => {
          let strPattern = pattern.regex || pattern;
          if (typeof strPattern === "string") {
            let regex = new RegExp(strPattern, "g");
            let test = !regex.test(origId);
            shouldLog() &&
              console.log("filterResult", origId, strPattern, test, r, item);
            return acc && test;
          } else {
            shouldLog() &&
              console.log("filterResult", origId, strPattern, test, r, item);
            return acc;
          }
        }, true);
        return noRegexMatch && r.id !== event.target.id;
      } else {
        shouldLog() && console.log("filterResult", origId, test, r, item);
        return r.id !== event.target.id;
      }
    },
    [idNoSmid]
  );

  const wait = (item, parent, mSeconds) => {
    svcMsgDispatch({
      type: "SET_WAIT_ID",
      values: {
        id: `${parent}_${item.id}_${item.position}`,
        value: true
      }
    });
    mSeconds > 0 &&
      setTimeout(() => {
        svcMsgDispatch({
          type: "SET_WAIT_ID",
          values: {
            id: `${parent}_${item.id}_${item.position}`,
            value: false
          }
        });
      }, mSeconds);
  };

  const chkField = (button, parent) => {
    shouldLog() && console.log("!!!chkField modal", button, parent);
    let fieldOK = false;
    fieldOK = button.deps
      ? button.deps?.reduce((acc, field) => {
          //for each deps go through the form - if the dep field is present chk for validity
          if (acc) {
            if (svcMsgState.response?.length > 0) {
              if (
                svcMsgState.response?.filter((fldsmr) => {
                  if (fldsmr.value === "none") acc = false;
                  return (
                    (fldsmr.id?.substr(
                      props.message.smid?.length + 1,
                      fldsmr.id?.length - props.message.smid?.length + 1
                    ) === field ||
                      fldsmr.parent?.substr(
                        props.message.smid?.length + 1,
                        fldsmr.parent?.length - props.message.smid?.length + 1
                      ) === field) &&
                    // fldsmr.value && fldsmr.value !== "none" &&
                    ((typeof fldsmr.value === "string" &&
                      fldsmr.value.length > 0) ||
                      fldsmr.value === true)
                  );
                }).length > 0
              ) {
              } else acc = false;
            } else acc = false;
          }
          return acc;
        }, true)
      : true;
    return (
      fieldOK &&
      !(
        svcMsgState.wait &&
        svcMsgState.wait[`${parent}_${button.id}_${button.position}`]
      )
    );
  };

  const linkItem = (parent, item, liveTopics) => {
    let btn = (
      <button
        className={"UI-button-service"}
        disabled={!chkField(item, parent)}
        title={item.title ? item.title : ""}
        onClick={(ev) => {
          if (chkField(item, parent)) {
            window.open(item.url, "_blank").focus();
          }
          wait(item, parent, item.wait || 10000);
        }}
      >
        <span>{item.title}</span>
      </button>
    );
    return <div>{btn}</div>;
  };

  const popupItem = (parent, item, liveTopics, noBtns) => {
    let popup = (
      <ServiceModal
        parent={parent}
        visible={true}
        act={act}
        liveTopics={liveTopics}
        items={item.items}
        header={item.header}
        message={props.message}
        onClose={() =>
          svcMsgDispatch({ type: "SET_MODAL", values: { modal: undefined } })
        }
      ></ServiceModal>
    );
    let btn = (
      <button
        className="UI-button-service"
        style={
          noBtns > 3 && isMobileTablet()
            ? { minWidth: "4.4rem", backgroundColor: "yellow" }
            : { minWidth: "6rem" }
        }
        disabled={!chkField(item, parent)}
        onClick={(ev) => {
          if (chkField(item, parent)) {
            setClickOutsideActive(false);
            svcMsgDispatch({ type: "SET_MODAL", values: { modal: popup } });
          }
          wait(item, parent, item.wait || 10000);
        }}
      >
        {item.title}
      </button>
    );
    return <div>{btn}</div>;
  };

  useEffect(() => {
    // only runs when opening creating Service Modal
    // get all ids nested in props.items
    let ids = getAll(props.items, "id", []).filter(
      // find the shared start of all ids that are not just string numbers
      (id) => isNaN(id) && id !== "Other"
    );
    let strStart =
      Array.isArray(ids) && ids.length > 0 ? sharedStart(ids) : undefined;
    strStart =
      strStart && (strStart.match(/:/g) || []).length > 1
        ? strStart.substr(0, [...strStart.matchAll(/:/g)][1].index + 1)
        : undefined;
    // remove all items that do not have the shared start (with at least 2 ; ) from svcMsgState.response
    if (strStart) {
      let newResponse = svcMsgState.response.filter((r) =>
        idNoSmid(r).startsWith(strStart)
      );
      // console.log("newResponse", strStart, svcMsgState.response, newResponse);
      svcMsgDispatch({
        type: "SET_RESPONSE",
        values: { response: newResponse }
      });
    } else
      svcMsgDispatch({
        type: "SET_RESPONSE",
        values: { response: [] }
      });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.items]);

  useEffect(() => {
    const nested = (parent, items, classStyle1, classStyle2) => {
      // let counter = 0
      let objects = items.map((item) => {
        // counter = counter + 1
        switch (item.type) {
          case "link":
            return linkItem(
              props.message.smid + "_" + item.id,
              item,
              liveTopics
            );
          case "pop-up":
            let pui = popupItem(
              props.message.smid + "_" + item.id,
              item,
              liveTopics,
              items.buttons?.length
            );
            return pui;
          case "dropdown":
            let children = undefined;

            const onChange = (e) => {
              let resp = [
                {
                  mtopic: props.message.mtopic,
                  msg_idx: props.message.msg_idx,
                  smid: props.message.smid,
                  parent: parent,
                  id: e.target.id,
                  position: item.position,
                  value: e.target.selectedOptions[0].id
                },
                ...svcMsgState.response.filter((r) =>
                  // shouldFilter(item)
                  // ? r.position < item.position
                  filterResult(r, item, e)
                )
              ];
              svcMsgDispatch({
                type: "SET_RESPONSE",
                values: { response: resp }
              });
              if (
                resp.filter(
                  (r) =>
                    r.id === props.message.smid + "_" + item.id &&
                    r.value === e.target.selectedOptions[0].id
                ).length > 0 &&
                e.target.selectedOptions[0].attributes.items
              ) {
                // Get the seleted Item original
                let selectedItem = false;
                item.options.forEach((i) => {
                  if (i.id === e.target.selectedOptions[0].id) {
                    selectedItem = i;
                  }
                });
                // if (selectedItem.items) {
                //   children = nested(
                //     props.message.smid + "_" + e.target.selectedOptions[0].id,
                //     selectedItem.items,
                //     "lines",
                //     "na"
                //   );
                //   console.log("!!!onChange children:", children);
                // }
              }
            };

            /* if response contains match smid and selected item has children, show then here */
            // relatedResponse enumerats responses in the svcMsgState that match the item
            // then if the selected option contains "items" they are expanded as children
            let relatedResponse = svcMsgState.response.filter((r) =>
              matching(r, item)
            );
            children = undefined;
            item?.options.forEach((opt) => {
              if (
                opt.items &&
                relatedResponse.map((r) => r.value).includes(opt.id)
              ) {
                children = nested(
                  props.message.smid + "_" + item.id,
                  opt.items,
                  "lines",
                  "na"
                );
              }
            });

            let dropdown = (
              <div
                className={classStyle1 === "lines" ? "lines" : null}
                key={"div_" + props.message.smid + "_" + item.id}
              >
                <ServiceDropDown
                  key={item}
                  item={item}
                  classStyle1={classStyle1}
                  parent={parent}
                  svcMsgState={svcMsgState}
                  filterResult={filterResult}
                  svcMsgDispatch={svcMsgDispatch}
                  message={props.message}
                  onChange={onChange}
                ></ServiceDropDown>

                {children}
              </div>
            );
            return dropdown;
          case "checkbox_group":
            const onChangeCheckBox = (event, box) => {
              let selected = document.getElementById(
                props.message.smid + "_" + box.id
              ).checked;
              // apply a default reset of location
              // item.reset = item.reset || [
              //   "^community:job:location"
              // ];
              // item.reset = item.reset || ["^" + item.id];

              let resp = selected
                ? [
                    {
                      mtopic: props.message.mtopic,
                      msg_idx: props.message.msg_idx,
                      smid: props.message.smid,
                      parent: props.message.smid + "_" + item.id,
                      id: props.message.smid + "_" + box.id,
                      position: item.position,
                      value: selected
                    },
                    ...svcMsgState.response.filter((r) =>
                      filterResult(r, item, event)
                    )
                  ]
                : svcMsgState.response.filter((r) =>
                    filterResult(r, item, event)
                  );
              // console.log("SET_RESPONSE", resp);
              svcMsgDispatch({
                type: "SET_RESPONSE",
                values: { response: resp }
              });
            };
            let checkbox_group = (
              <div>
                <div
                  style={{
                    // marginLeft: "0.75rem",
                    fontSize: "var(--service_message_fontsize)",
                    marginTop: "0.25rem",
                    marginBottom: "0.25rem"
                  }}
                >
                  {item.title}
                </div>
                <ServiceCheckBoxes
                  key={item}
                  item={item}
                  classStyle1={classStyle1}
                  parent={parent}
                  svcMsgState={svcMsgState}
                  filterResult={filterResult}
                  svcMsgDispatch={svcMsgDispatch}
                  message={props.message}
                  onChange={onChangeCheckBox}
                />
              </div>
            );
            return checkbox_group;
          case "radio_group":
            let radio_group = (
              <div
                key={"div_" + props.message.smid + "_" + item.id}
                style={{ marginBottom: "0.5rem" }}
                onChange={(event) => {
                  let selected = event.target.id;
                  // use for test
                  // item.reset = ["^community:job:location"];
                  let resp = selected
                    ? [
                        {
                          mtopic: props.message.mtopic,
                          msg_idx: props.message.msg_idx,
                          smid: props.message.smid,
                          parent: props.message.smid + "_" + item.id,
                          id: event.target.id,
                          position: item.position,
                          value: true
                        },
                        ...svcMsgState.response.filter((r) => {
                          return (
                            filterResult(r, item, event) &&
                            !item?.radio_buttons.some(
                              (el) =>
                                el.id ===
                                r.id.split(props.message.smid + "_").pop()
                            )
                          );
                        })
                      ]
                    : svcMsgState.response.filter((r) =>
                        filterResult(r, item, event)
                      );
                  svcMsgDispatch({
                    type: "SET_RESPONSE",
                    values: { response: resp }
                  });
                }}
              >
                {Array.isArray(item?.radio_buttons) &&
                  item?.radio_buttons.map((radio) => (
                    <div
                      key={"div_" + props.message.smid + "_" + radio.id}
                      className={
                        props.type === "right"
                          ? "right-align-label"
                          : "left-align-label"
                      }
                    >
                      <label
                        style={{ paddingLeft: "1rem" }}
                        htmlFor={props.message.smid + "_" + radio.id}
                      >
                        <input
                          // className="rbtn"
                          disabled={item.locked === "true"}
                          type="radio"
                          id={props.message.smid + "_" + radio.id}
                          name={props.message.smid + "_" + item.id}
                          value={radio.title}
                          checked={
                            item.selected
                              ? item.selected === radio.id
                              : svcMsgState.response.filter(
                                  (r) =>
                                    r.id === props.message.smid + "_" + radio.id
                                )[0]?.value
                          }
                        />
                        {/* <label
                        className="label" */}

                        {/* > */}
                        <span>{radio.title}</span>
                      </label>
                    </div>
                  ))}
              </div>
            );
            return (
              <div
                className="radio-header"
                key={"div_" + props.message.smid + "_" + item.id}
              >
                {item.title}
                {radio_group}
              </div>
            );
          case "header":
            // setHeader(item.title)
            // let title_field = (
            //   <div
            //     className="textfield"
            //     key={"div_" + props.message.smid + "_" + item.id}
            //   >
            // {item.title}
            //   </div>
            // );
            // return title_field;
            break;
          case "text":
            let text_field = (
              // <Linkify options={linkProps}>
              <div
                className="textfield"
                style={
                  item.title?.trim() === ""
                    ? {
                        display: "none",
                        marginLeft: "1.25rem"
                      }
                    : {}
                }
                key={"div_" + props.message.smid + "_" + item.id}
              >
                <MarkdownComponent content={item.title ? item.title : ""} />
                <MarkdownComponent
                  content={item.title && item.value ? ": " : ""}
                />
                <MarkdownComponent content={item.value} />
              </div>
              //</Linkify> }
            );
            return text_field;
          case "hidden":
            let newItem = {
              mtopic: props.message.mtopic,
              msg_idx: props.message.msg_idx,
              smid: props.message.smid,
              parent: parent,
              id: props.message.smid + "_" + item.id,
              position: item.position,
              value: item.value
            };
            if (
              svcMsgState.response.filter((r) => isEqual(newItem, r)).length ===
              0
            ) {
              let resp = [
                newItem,
                ...svcMsgState.response.filter(
                  (r) => r.id !== props.message.smid + "_" + item.id
                )
              ];
              svcMsgDispatch({
                type: "SET_RESPONSE",
                values: { response: resp }
              });
            }
            return <></>;
          case "button_group":
            // footerbuttons not used but can be set as shown in commented code
            // setFooterButtons(<button>TST</button>)
            let button_group =
              Array.isArray(item?.buttons) &&
              item?.buttons.map((button) => {
                if (!btnArr.some((el) => el === button))
                  setBtnArr((prevBtnArr) => [...prevBtnArr, button]);
                switch (button.type) {
                  case "link":
                    return linkItem(
                      props.message.smid + "_" + button?.id,
                      button,
                      liveTopics
                    );
                  case "pop-up":
                    return popupItem(
                      props.message.smid + "_" + button?.id,
                      button,
                      liveTopics,
                      item.buttons?.length
                    );
                  default:
                    return (
                      <button
                        key={
                          "div_" +
                          props.message.smid +
                          "_" +
                          button.id +
                          "_" +
                          button.position
                        }
                        className="UI-button-service"
                        style={
                          isMobileTablet()
                            ? { maxWidth: "4.4rem", minWidth: "4.4rem" }
                            : {}
                        }
                        disabled={!chkField(button, parent)}
                        onClick={(ev) => {
                          if (chkField(button, parent)) {
                            act(item, button, svcMsgState.response).then(
                              () => {}
                            );
                            if (button.action === "goto") {
                              if (
                                !liveTopics
                                  .map((t) => t.mtopic)
                                  .includes(button.target)
                              ) {
                                setTimeout(() => {
                                  ev.target.innerHTML = !liveTopics
                                    .map(
                                      (t) =>
                                        t.mpersona ===
                                          props.message.recipient && t.mtopic
                                    )
                                    .includes(button.target)
                                    ? `${
                                        button.alttitle || button["alt.title"]
                                      }`
                                    : button.title;
                                }, 5000);
                                ev.target.innerHTML =
                                  button.waitingtitle || "Trying...";
                              } else {
                                let targetTopicMatches = liveTopics?.filter(
                                  (t) =>
                                    t.mtopic === button?.target &&
                                    t.mpersona === props.message
                                );
                                if (isMobileTablet()) {
                                  targetTopicMatches.length > 0 &&
                                    history.replace("/UIMessageList", {
                                      subscription: targetTopicMatches[0]
                                    });
                                } else {
                                  // topicsDispatch({
                                  //   type: "SET_SHOWTOPIC",
                                  //   values: { showTopic: { subscription: targetTopicMatches[0] } }
                                  // });
                                }
                              }
                            } else {
                              if (history.length >= 1) {
                                history.goBack();
                              }
                            }
                          }
                          wait(button, parent, button.wait || 10000);
                        }}
                      >
                        {button.action === "goto" &&
                        !liveTopics
                          .map(
                            (t) =>
                              t.mpersona === props.message.recipient && t.mtopic
                          )
                          .includes(button.target)
                          ? `${button.alttitle || button["alt.title"]}`
                          : button.title}
                      </button>
                    );
                }
              });
            return (
              <div
                className="button-layout"
                key={"div_" + props.message.smid + "_" + item.id}
                style={
                  item.buttons?.length > 3 && isMobileTablet()
                    ? { gridTemplateColumns: "repeat (auto-fill, 4.4rem)" }
                    : { gridTemplateColumns: "repeat(auto-fill, 6rem)" }
                }
              >
                {button_group}
              </div>
            );
          case "date_input":
            //removed the follwing code and inserted the current date and time as initialDate
            let initialDate = new Date(
              svcMsgState.response.filter(
                (r) => r.id === props.message.smid + "_" + item.id
              )[0]?.value || null
            );
            // let initialDate = new Date();
            let date_input = (
              <div
                style={{ marginBottom: "0.4rem" }}
                key={"div_" + props.message.smid + "_" + item.id}
              >
                {/* {item.title} */}
                <DateSelector
                  label={item.title}
                  shouldCloseOnSelect={false}
                  readOnly={item.readOnly === "true" ? true : false}
                  // selected={initialDate || new Date()}
                  selected={initialDate > new Date() ? initialDate : null}
                  usePast={item.past === "true" ? true : false}
                  timeInputLabel="Time:"
                  dateFormat={item.format || "DD MMM yyyy HH:mm"}
                  showTimeInput
                  setClickOutsideActive={setClickOutsideActive}
                  value={initialDate || new Date()}
                  onChange={(date) => {
                    let filt = svcMsgState.response.filter(
                      (r) => r.position !== item.position
                    );
                    let resp = [
                      {
                        mtopic: props.message.mtopic,
                        msg_idx: props.message.msg_idx,
                        smid: props.message.smid,
                        parent: parent,
                        id: props.message.smid + "_" + item.id,
                        position: item.position,
                        value: date.toISOString()
                      },
                      ...filt
                    ];
                    !item.locked &&
                      svcMsgDispatch({
                        type: "SET_RESPONSE",
                        values: { response: resp }
                      });
                  }}
                />
              </div>
            );
            return date_input;
          case "text_input":
            setMaxRows(item.lines);
            let text_input = (
              <div key={"div_" + props.message.smid + "_" + item.id}>
                <label
                  for={"txtinput_" + props.message.smid + "_" + item.id}
                  style={
                    item?.title?.length > 0 ? titleStyle : { display: "none" }
                  }
                >
                  {item.title}
                </label>

                <input
                  style={{
                    // marginLeft: "0.75rem",
                    paddingLeft: "0.5rem",
                    fontSize: "var(--service_message_fontsize)",
                    border: "1px solid var(--modal_border_color)",
                    borderRadius: "var(--border_radius_small)",
                    marginBottom: "0.5rem"
                  }}
                  type="text"
                  placeholder={
                    svcMsgState.response.filter(
                      (r) => r.id === props.message.smid + "_" + item.id
                    )[0]?.value || item.hint
                  }
                  value={
                    firstSet
                      ? svcMsgState.response.filter(
                          (r) => r.id === props.message.smid + "_" + item.id
                        )[0]?.value ||
                        item.default ||
                        ""
                      : svcMsgState.response.filter(
                          (r) => r.id === props.message.smid + "_" + item.id
                        )[0]?.value || ""
                  }
                  pattern={item.regex}
                  onChange={(e) => {
                    setFirstSet(false);
                    let valid = e.target.validity.valid;
                    if (valid) {
                      let resp = [
                        {
                          mtopic: props.message.mtopic,
                          msg_idx: props.message.msg_idx,
                          smid: props.message.smid,
                          parent: parent,
                          id: e.target.id.replace("txtinput_", ""),
                          position: item.position,
                          value: e.target.value
                        },
                        ...svcMsgState.response.filter(
                          (r) => r.position !== item.position
                        )
                      ];
                      svcMsgDispatch({
                        type: "SET_RESPONSE",
                        values: { response: resp }
                      });
                    } else {
                      e.target.value = null;
                      let resp = [
                        ...svcMsgState.response.filter(
                          (r) => r.position !== item.position
                        )
                      ];
                      svcMsgDispatch({
                        type: "SET_RESPONSE",
                        values: { response: resp }
                      });
                    }
                  }}
                  id={"txtinput_" + props.message.smid + "_" + item.id}
                  name={"txtinput_" + props.message.smid + "_" + item.id}
                />
              </div>
            );
            return text_input;
          case "text_multiline":
            setMaxRows(item.lines);
            let text_multiline = (
              <div key={"div_" + props.message.smid + "_" + item.id}>
                <label
                  for={"txtinput_" + props.message.smid + "_" + item.id}
                  style={
                    item?.title?.length > 0 ? titleStyle : { display: "none" }
                  }
                >
                  {item.title}
                </label>

                <textarea
                  style={{
                    // marginLeft: "0.75rem",
                    paddingLeft: "0.5rem",
                    fontFamily:
                      styleState?.theme?.fonts?.topic_name_main_screen_font,
                    fontSize: "1.25rem",
                    // fontSize: "var(--service_message_fontsize)",
                    resize: "none",
                    border: "1px solid var(--modal_border_color)",
                    borderRadius: "var(--border_radius_small)",
                    marginBottom: "0.5rem",
                    width: "90%"
                  }}
                  rows={item.lines}
                  // type="text"
                  placeholder={
                    svcMsgState.response.filter(
                      (r) => r.id === props.message.smid + "_" + item.id
                    )[0]?.value || item.hint
                  }
                  value={
                    firstSet
                      ? svcMsgState.response.filter(
                          (r) => r.id === props.message.smid + "_" + item.id
                        )[0]?.value ||
                        item.default ||
                        ""
                      : svcMsgState.response.filter(
                          (r) => r.id === props.message.smid + "_" + item.id
                        )[0]?.value || ""
                  }
                  pattern={item.regex}
                  onChange={(e) => {
                    setFirstSet(false);
                    let valid = e.target.validity.valid;
                    if (valid) {
                      let resp = [
                        {
                          mtopic: props.message.mtopic,
                          msg_idx: props.message.msg_idx,
                          smid: props.message.smid,
                          parent: parent,
                          id: e.target.id.replace("txtinput_", ""),
                          position: item.position,
                          value: e.target.value
                        },
                        ...svcMsgState.response.filter(
                          (r) => r.position !== item.position
                        )
                      ];
                      svcMsgDispatch({
                        type: "SET_RESPONSE",
                        values: { response: resp }
                      });
                    } else {
                      e.target.value = null;
                      let resp = [
                        ...svcMsgState.response.filter(
                          (r) => r.position !== item.position
                        )
                      ];
                      svcMsgDispatch({
                        type: "SET_RESPONSE",
                        values: { response: resp }
                      });
                    }
                  }}
                  id={"txtinput_" + props.message.smid + "_" + item.id}
                  name={"txtinput_" + props.message.smid + "_" + item.id}
                />
              </div>
            );
            return text_multiline;
          default:
            return undefined;
        }
      });
      return objects;
    };

    setBody(nested(props.parent, props.items, "lines", "na"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    act,
    svcMsgState.response,
    svcMsgState.wait,
    liveTopics,
    props,
    svcMsgState.modal
  ]);

  useEffect(() => {
    window.addEventListener("popstate", props.onClose);
    if (
      window.location?.href?.includes("menu") ||
      window.location?.href?.includes("modal")
    ) {
      window.history.replaceState({ id: 2 }, null, "modal");
    } else {
      if (!props.loginModal) {
        window.history.pushState({ id: 2 }, null, "modal");
      }
    }
    return () => {
      window.removeEventListener("popstate", props.onClose);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalClose, outClick]);

  const handleClose = () => {
    setModalClose(true);
    props.onClose();
  };

  const handleClickOutside = () => {
    setOutClick(true);
    if (clickOutsideActive && history.length > 1) history.goBack();
  };

  let modalService = (
    <div id="service_modal">
      <Modals
        // loginModal={true}
        // origin={origin}
        title={props.header}
        onClose={() => handleClose()}
        onClickOutside={() => handleClickOutside()}
        clickOutsideActive={clickOutsideActive}
        style={isMobileTablet() ? { minWidth: "80%" } : {}}
        // footerbuttons not used but can be set as shown in commented code
        // footer={

        // }
      >
        <div>{body}</div>
      </Modals>
    </div>
  );

  let content = <div>{props.visible && modalService}</div>;

  return content;
};

export default React.memo(ServiceModal, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
});
