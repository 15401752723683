import React, { useEffect, useContext, useState, useRef } from "react";
import "./UI.css";
import "./modals.css";
import "utils/utilsUniversal.css";
import UIPersonaPicManager from "./UIPersonaPicManager";
import GlobalContext from "contexts/context";
import { WorldTextInput } from "utils/UtilsUniversal";
import { isMobileTablet, randomString } from "hooks/helper";
import { workerGet, workerPost } from "workers/interfaceRest";
import UserContext from "contexts/contextUser";
import FabButton from "utils/FabButton";
import LoadMenu from "../utils/LoadMenu";
import { sanitizeUserId } from "utils/textUtils";
import { PersonaButton } from "./Forms/TextInput";
import Modals from "./Modals";

const isEqual = require("react-fast-compare");

const UIWorldIDInfo = (props) => {
  const [givenName, setGivenName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [phoneData, setPhoneData] = useState([""]);
  const [emailData, setEmailData] = useState([""]);
  const [idData, setIdData] = useState([""]);
  const [givenNameCopy, setGivenNameCopy] = useState("");
  const [familyNameCopy, setFamilyNameCopy] = useState("");
  const [phoneDataCopy, setPhoneDataCopy] = useState([""]);
  const [emailDataCopy, setEmailDataCopy] = useState([""]);
  const [idDataCopy, setIdDataCopy] = useState([""]);
  const { globalState } = useContext(GlobalContext);
  const urlPersona = process.env.REACT_APP_PERSONA_API_URL;
  const keyPersona = process.env.REACT_APP_PERSONA_API_KEY;
  const { userState } = useContext(UserContext);
  const [showAlert, setShowAlert] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [mnuX, setMnuX] = useState(0);
  const [mnuY, setMnuY] = useState(0);
  const [recordDeleted, setRecordDeleted] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // State to track loading status
  const emailLimit = 3;
  const phoneLimit = 3;
  const idLimit = 3;
  const [limitsFull, setLimitsFull] = useState(false);
  const [newPhonevar, setNewPhonevar] = useState(false);
  const [newEmailvar, setNewEmailvar] = useState(false);
  const [newIdvar, setNewIdvar] = useState(false);
  const [parentWidth, setParentWidth] = useState();
  const [parentHeight, setParentHeight] = useState();
  const [parentObj, setParentObj] = useState({});
  const parentRef = useRef(null);
  const [scrolledAmountY, setScrolledAmountY] = useState(0);
  const [worldIDName, setWorldIDName] = useState({
    value: globalState.uid,
    source: "initial"
  });

  const getResponses = async (property) => {
    return new Promise((resolve, reject) => {
      workerPost(urlPersona, keyPersona, {
        type: "getProperties",
        msgid: randomString(8),
        muid: globalState.muid,
        token:
          userState?.accessToken ||
          globalState.jwtLongExpiry ||
          globalState?.jwt,
        property: property
      })
        .then((response) => {
          if (response.list?.length > 0) {
            resolve(response.list);
          } else {
            resolve("");
          }
        })
        .catch((error) => {
          console.error(
            "[UIWorldInfoID] Error occurred in getResponses:",
            error
          );
          // Reject the promise with the error
          reject(error);
        });
    });
  };

  const updateServerReplaceField = async ({ key, value }) => {
    try {
      const response = await workerPost(urlPersona, keyPersona, {
        type: "replace_property",
        jwt:
          globalState?.jwt ||
          userState?.accessToken ||
          globalState.jwtLongExpiry,
        property: key,
        value: value
      });
      globalState.logging &&
        console.log(
          "[WorldIDInfo] workerPut result in upDateServerReplaceField:",
          response
        );
    } catch (error) {
      globalState.logging &&
        console.log("[[WorldIDInfo] error in upDateServerReplaceField:", error);
    }
  };

  const fetchData = async () => {
    try {
      const properties = [
        "given_name",
        "family_name",
        "email",
        "phone_number",
        "id_number"
      ];
      const responses = await Promise.all(
        properties.map((property) => getResponses(property))
      );
      globalState.logging &&
        console.log("[UIWorldIDInfo] fetchData responses:", responses);
      responses.forEach((response, index) => {
        const property = properties[index];
        // if (response?.length > 0) {
        switch (property) {
          case "given_name":
            if (Array.isArray(response) && response.length > 0) {
              // If response is an array and not empty, set the first element
              setGivenName(response[0]);
              setGivenNameCopy(response[0]);
            } else {
              // If response is not an array or is empty, set response directly
              setGivenName(response);
              setGivenNameCopy(response);
            }
            break;
          case "family_name":
            if (Array.isArray(response) && response.length > 0) {
              // If response is an array and not empty, set the first element
              setFamilyName(response[0]);
              setFamilyNameCopy(response[0]);
            } else {
              // If response is not an array or is empty, set response directly
              setFamilyName(response);
              setFamilyNameCopy(response);
            }
            break;
          case "phone_number":
            if (response.length > 0) {
              setPhoneData(response);
              setPhoneDataCopy(response);
            }
            break;
          case "email":
            if (response.length > 0) {
              setEmailData(response);
              setEmailDataCopy(response);
            }
            break;
          case "id_number":
            if (response.length > 0) {
              setIdData(response);
              setIdDataCopy(response);
            }
            break;
          default:
            break;
        }
        // }
      });
    } catch (error) {
      console.error("[UIWorldIDInfo] Error occurred in fetchData:", error);
      // return Promise.reject(error);
    } finally {
      setIsLoading(false); // Set loading to false regardless of success or failure
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleFabClick = (event) => {
    setOpenModal(true);
    if (event.clientX - 150 > 0) setMnuX(event.clientX - 150);
    else setMnuX(20);
    if (event.clientY - 150 > 0) {
      setMnuY(event.clientY - 150 + scrolledAmountY);
    } else {
      setMnuY(20);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (parentRef.current) {
        setScrolledAmountY(parentRef.current.scrollTop);
      }
    };

    if (parentRef.current) {
      parentRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (parentRef.current) {
        parentRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const handleGivenName = (e) => {
    setGivenName(e);
  };

  const handleBlurGivenName = (e) => {
    let equalCheck = true;
    equalCheck = isEqual(givenName, givenNameCopy);
    if (!equalCheck) {
      if (givenName?.length <= 0) {
        alert("First name cannot be blank");
        setGivenName(givenNameCopy);
      } else updateServerReplaceField({ key: "given_name", value: givenName });
    }
  };

  const handleFamilyName = (e) => {
    setFamilyName(e);
  };
  const handleBlurFamilyName = () => {
    let equalCheck = true;
    equalCheck = isEqual(familyName, familyNameCopy);
    if (!equalCheck) {
      if (familyName?.length <= 0) {
        alert("Family name cannot be blank");
        setFamilyName(familyNameCopy);
      } else
        updateServerReplaceField({ key: "family_name", value: familyName });
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleCallbackPhone = () => {
    if (phoneData.filter((item) => item?.length <= 0).length > 0) {
      alert(
        "Cannot add more than one blank phone attribute, please fill in blank first"
      );
    } else {
      let tempArr = [...phoneData, ""];
      setPhoneData(tempArr);
      handleBlurInput(tempArr, "phone_number");
      setNewPhonevar(true);
    }
    handleClose();
  };

  // const handleCallbackEmail = () => {
  // if (emailData.filter((item) => item?.length <= 0).length > 0) {
  //   alert(
  //     "Cannot add more than one blank email attribute, please fill in blank first"
  //   );
  // } else {
  //   let tempArr = [...emailData, ""];
  //   setEmailData(tempArr);
  //   handleBlurInput(tempArr, "email");
  //   setNewEmailvar(true);
  // }
  // handleClose();
  // };

  const handleCallbackId = () => {
    if (idData.filter((item) => item?.length <= 0).length > 0) {
      alert(
        "Cannot add more than one blank id attribute, please fill in blank first"
      );
    } else {
      let tempArr = [...idData, ""];
      setIdData(tempArr);
      handleBlurInput(tempArr, "id_number");
      setNewIdvar(true);
    }
    handleClose();
  };

  const handlePhone = (index, e) => {
    const tempPhone = [...phoneData];
    tempPhone[index] = e;
    setPhoneData(tempPhone);
  };

  const handleEmail = (index, e) => {
    const tempEmail = [...emailData];
    tempEmail[index] = e;
    setEmailData(tempEmail);
  };

  const handleId = (index, e) => {
    const tempId = [...idData];
    tempId[index] = e;
    setIdData(tempId);
  };

  const handleBlurInput = async (data, key) => {
    //data is an array phoneData, emailData, idData
    let equalCheck = true;
    switch (key) {
      case "phone_number":
        equalCheck = isEqual(data, phoneDataCopy);
        break;
      case "email":
        equalCheck = isEqual(data, emailDataCopy);
        break;
      case "id_number":
        equalCheck = isEqual(data, idDataCopy);
        break;
      default:
        break;
    }
    if (!equalCheck) {
      if (data.filter((_, i) => data[i]?.length > 0).length > 0) {
        if (data.length > 0) {
          const firstItem = data[0]; // Get the first item
          await updateServerReplaceField({ key: key, value: firstItem }); // Replace data on server with the first item
          for (let i = 1; i < data.length; i++) {
            await workerPost(urlPersona, keyPersona, {
              type: "add_unique_property",
              jwt:
                globalState?.jwt ||
                userState?.accessToken ||
                globalState.jwtLongExpiry,
              property: key,
              value: data[i]
            })
              .then((r) => {
                globalState.logging &&
                  console.log(
                    "[UIWorldInfoID]-handleBlurInput workerPut result",
                    r
                  );
              })
              .catch((err) => {
                globalState.logging &&
                  console.log("[UIWorldInfoID] error in handleBlurInput", err);
              });
          }
          switch (key) {
            case "phone_number":
              setPhoneDataCopy(data);
              break;
            case "email":
              setEmailDataCopy(data);
              break;
            case "id_number":
              setIdDataCopy(data);
              break;
            default:
              break;
          }
        }
      } else {
        alert(
          "Data cannot be deleted - at least one field must contain valid data"
        );
        switch (key) {
          case "phone_number":
            setPhoneData(phoneDataCopy);
            break;
          case "email":
            setEmailData(emailDataCopy);
            break;
          case "id_number":
            setIdData(idDataCopy);
            break;
          default:
            break;
        }
      }
    }
  };

  const removePhoneData = (index) => {
    const newData = [...phoneData];
    if (newData.length > 1) {
      if (
        newData.filter((_, i) => i !== index && newData[i]?.length > 0).length >
        0
      ) {
        // If more than one element and at least one other element contains data
        newData.splice(index, 1);
        setPhoneData(newData);
        setRecordDeleted(true);
      } else {
        alert("Cannot delete the only item with data in series");
      }
    }
  };

  // const removeEmailData = (index) => {
  // const newData = [...emailData];
  // if (newData.length > 1) {
  //   if (
  //     newData.filter((_, i) => i !== index && newData[i]?.length > 0).length >
  //     0
  //   ) {
  //     // If more than one element and at least one other element contains data
  //     newData.splice(index, 1);
  //     setEmailData(newData);
  //     setRecordDeleted(true);
  //   } else {
  //     alert("Cannot delete the only item with data in series");
  //   }
  // }
  // };

  const removeIdData = (index) => {
    const newData = [...idData];
    if (newData.length > 1) {
      if (
        newData.filter((_, i) => i !== index && newData[i]?.length > 0).length >
        0
      ) {
        // If more than one element and at least one other element contains data
        newData.splice(index, 1);
        setIdData(newData);
        setRecordDeleted(true);
      } else {
        alert("Cannot delete the only item with data in series");
      }
    }
  };

  useEffect(() => {
    if (recordDeleted) {
      handleBlurInput(phoneData, "phone_number");
      setRecordDeleted(false);
    }
  }, [phoneData]);

  // useEffect(() => {
  //   if (recordDeleted) {
  //     handleBlurInput(emailData, "email");
  //     setRecordDeleted(false);
  //   }
  // }, [emailData]);

  useEffect(() => {
    if (recordDeleted) {
      handleBlurInput(idData, "id_number");
      setRecordDeleted(false);
    }
  }, [idData]);

  const handleChangeWorldIDName = (newValue) => {
    setWorldIDName({ value: newValue, source: "input" });
  };

  // const handleDeleteWorldID = () => {
  //   setShowAlert(true);
  // };

  const handleDelete = () => {
    setShowAlert(false);
  };

  const filteredMenuList = [
    {
      name: "Phone number",
      callback: handleCallbackPhone,
      instances: phoneData?.length,
      limit: phoneLimit
    },
    // { not added yet - issue with storing verfied emails needs investigation first
    //   name: "email",
    //   callback: handleCallbackEmail,
    //   instances: emailData?.length,
    //   limit: emailLimit
    // },
    {
      name: "ID number",
      callback: handleCallbackId,
      instances: idData?.length,
      limit: idLimit
    }
  ].filter((item) => item.instances < item.limit);

  useEffect(() => {
    if (
      phoneData?.length >= phoneLimit &&
      // emailData?.length >= emailLimit && //no changes allowed to email until backend added
      idData?.length >= idLimit
    )
      setLimitsFull(true);
    else setLimitsFull(false);
  }, [phoneData, emailData, idData]);

  const handlePhoneFocus = (val) => {
    setNewPhonevar(false);
  };
  const handleEmailFocus = (val) => {
    setNewEmailvar(false);
  };
  const handleIdFocus = (val) => {
    setNewIdvar(false);
  };

  useEffect(() => {
    if (parentRef?.current?.clientWidth && parentRef?.current?.clientHeight) {
      setParentWidth(parentRef?.current?.clientWidth);
      setParentHeight(parentRef?.current?.clientHeight);
      const padding = 50;
      const parentRect = parentRef?.current?.getBoundingClientRect();
      setParentObj({
        left: parentRect?.left + padding,
        top: parentRect?.top + padding,
        right: parentRect?.right - padding,
        bottom: parentRect?.bottom - padding
      });
      if (mnuX === 0 && mnuY === 0) {
        setMnuX(parentRef?.current?.clientWidth - 70);
        setMnuY(parentRef?.current?.clientHeight + 30);
      }
    }
  }, [
    parentRef,
    parentRef?.current?.clientHeight,
    parentRef?.current?.clientWidth
  ]);

  return (
    <div
      ref={parentRef}
      className="persona-scroll full persona-screen-layout"
      style={{
        position: "relative",
        minWidth: "97%"
      }}
    >
      {isLoading ? ( // Render spinner or loading message while isLoading is true
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)"
          }}
        >
          <div>Loading...</div>
          <div
            className="spinner"
            style={{ width: "50px", height: "50px" }}
          ></div>
        </div>
      ) : (
        <div
          style={{
            width: "100%"
          }}
        >
          {!limitsFull && parentObj && Object.keys(parentObj)?.length > 0 && (
            <div>
              <FabButton
                onFabClick={(event) => handleFabClick(event)}
                parentObj={parentObj}
                xPos={mnuX}
                yPos={mnuY}
              />
            </div>
          )}
          {openModal && (
            <LoadMenu
              title="Attribute type"
              // type="dropdown"
              xPos={mnuX}
              yPos={mnuY}
              menuList={filteredMenuList}
              close={handleClose}
            />
          )}
          <UIPersonaPicManager></UIPersonaPicManager>
          <div style={{ textAlign: "left" }}>{globalState.muid}</div>
          <div
            style={{
              width: "98%",
              overflowY: "auto",
              marginTop: "1rem",
              marginBottom: "1rem"
            }}
          >
            <div className="worldIDInfo-input">
              <WorldTextInput
                active={"false"}
                universalStyle={{ display: "flex", flexDirection: "column" }}
                identity="username"
                type="text"
                callback={(e) => handleChangeWorldIDName(e)}
                label={"User name"}
                labelStyle={{
                  fontSize: "0.8rem"
                }}
                required={true}
                placeholder={"User name" || ""}
                minRows={1}
                maxRows={1}
                minStrLen={6}
                maxStrLen={200}
                value={worldIDName?.value || ""}
                inpStyle={{
                  maxWidth: "100%",
                  width: isMobileTablet() ? "70vw" : "100%",
                  padding: "0.5rem",
                  boxSizing: "border-box"
                }}
              />
              <div></div>
              <div></div>
            </div>
            {/* <PersonaButton
              marginLeft="0px"
              marginBottom={generalButtonMarginBottom}
              marginTop={generalMarginTop}
              onClick={handleDeleteWorldID}
              label="Delete WORLD ID"
              key="personabutton2"
            /> */}
            {/* <br /> */}
            {showAlert && (
              <Modals
                title="WARNING"
                onClose={() => setShowAlert(false)}
                onClickOutside={() => setShowAlert(false)}
                clickOutsideActive={true}
                footer={
                  <div>
                    <button
                      className="UI-button-service"
                      style={{ marginRight: "0.5rem" }}
                      type="button"
                      onClick={() => setShowAlert(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="UI-button-service"
                      type="button"
                      onClick={() => handleDelete()}
                    >
                      Delete
                    </button>
                  </div>
                }
              >
                <div
                  style={{
                    textAlign: "left",
                    whiteSpace: "pre-line"
                  }}
                >
                  <span>All user information will be permanently deleted.</span>
                  <br />
                  <br />
                  <span>
                    All topics with other participants will remain, but you
                    should transfer ownership to another user for topics you
                    have created.
                  </span>
                  <br />
                  <br />
                  <span>
                    Deleting your account cannot be undone. Confirm you want to
                    delete your account by tapping DELETE.
                  </span>
                </div>
              </Modals>
            )}
            <div className="worldIDInfo-input">
              <WorldTextInput
                universalStyle={{ display: "flex", flexDirection: "column" }}
                identity="givenname"
                labelStyle={{
                  fontSize: "0.8rem"
                }}
                type="text"
                callback={(e) => handleGivenName(e)}
                callback2={(e) => handleBlurGivenName(e)}
                label={"First name"}
                required={true}
                placeholder={"Given name"}
                minRows={1}
                maxRows={1}
                minStrLen={2}
                maxStrLen={80}
                value={givenName}
                regex={/[a-z'-._/, ]$/i}
                inpStyle={{
                  maxWidth: "100%",
                  width: isMobileTablet() ? "70vw" : "100%",
                  padding: "0.5rem",
                  boxSizing: "border-box"
                }}
              />
              <div></div>
              <div></div>
            </div>
            <div className="worldIDInfo-input">
              <WorldTextInput
                universalStyle={{ display: "flex", flexDirection: "column" }}
                identity="familyname"
                labelStyle={{
                  fontSize: "0.8rem"
                }}
                type="text"
                callback={(e) => handleFamilyName(e)}
                callback2={(e) => handleBlurFamilyName(e)}
                label={"Surname"}
                required={true}
                placeholder={"Family name"}
                minRows={1}
                maxRows={1}
                minStrLen={2}
                maxStrLen={80}
                value={familyName}
                regex={/[a-z'-._/, ]$/i}
                inpStyle={{
                  maxWidth: "100%",
                  width: isMobileTablet() ? "70vw" : "100%",
                  padding: "0.5rem",
                  boxSizing: "border-box"
                }}
              />
              <div></div>
              <div></div>
            </div>
            {phoneData &&
              phoneData.length > 0 &&
              phoneData.map((item, index) => (
                <div
                  className="worldIDInfo-input"
                  key={index}
                  style={{
                    gridTemplateColumns:
                      phoneData?.length > 1 ? "90% 10%" : "80% 10% 10%"
                  }}
                >
                  <WorldTextInput
                    key={index}
                    universalStyle={{
                      display: "flex",
                      flexDirection: "column"
                    }}
                    lineStyle={
                      phoneData?.length > 1
                        ? {
                            display: "grid",
                            gridTemplateColumns: "89% 11%",
                            alignItems: "center"
                          }
                        : {}
                    }
                    focus={
                      newPhonevar &&
                      phoneData?.length > 0 &&
                      index === phoneData.length - 1
                    }
                    removeFocus={() => handlePhoneFocus()}
                    identity={item}
                    type="phone"
                    callback={(e) => handlePhone(index, e)}
                    callback2={() => handleBlurInput(phoneData, "phone_number")}
                    callbackBin={(e) => removePhoneData(index)}
                    label={`Mobile number (intl format) #${index + 1}`}
                    labelStyle={{
                      fontSize: "0.8rem"
                    }}
                    required={index === 0 ? true : false}
                    placeholder="27 phone number"
                    minRows={1}
                    maxRows={1}
                    minStrLen={9}
                    maxStrLen={13}
                    value={item}
                    inpStyle={{
                      maxWidth: "100%",
                      width: isMobileTablet() ? "70vw" : "100%",
                      padding: "0.5rem",
                      boxSizing: "border-box"
                    }}
                    bin={phoneData?.length > 1 ? true : false}
                    binStyle={{ width: "3rem" }}
                  />
                  <div></div>
                </div>
              ))}
            {emailData &&
              emailData.length > 0 &&
              emailData.map((item, index) => (
                <div
                  className="worldIDInfo-input"
                  key={index}
                  style={{
                    gridTemplateColumns: "80% 10% 10%"
                  }}
                >
                  <WorldTextInput
                    key={index}
                    // active="false"
                    universalStyle={{
                      display: "flex",
                      flexDirection: "column"
                    }}
                    identity={item}
                    type="text"
                    callback={(e) => handleEmail(index, e)}
                    callback2={(e) => handleBlurInput(emailData, "email")}
                    // callbackBin={(e) => removeEmailData(index)}
                    focus={
                      newEmailvar &&
                      emailData?.length > 0 &&
                      index === emailData.length - 1
                    }
                    removeFocus={() => handleEmailFocus()}
                    label={`email #${index + 1}`}
                    labelStyle={{
                      fontSize: "0.8rem"
                    }}
                    required={index === 0 ? true : false}
                    placeholder="email"
                    minRows={1}
                    maxRows={1}
                    minStrLen={9}
                    maxStrLen={80}
                    value={item}
                    inpStyle={{
                      maxWidth: "100%",
                      width: isMobileTablet() ? "70vw" : "100%",
                      padding: "0.5rem",
                      boxSizing: "border-box"
                    }}
                    bin={false}
                  />
                  <div></div>
                </div>
              ))}
            {idData &&
              idData.length > 0 &&
              idData.map((item, index) => (
                <div
                  className="worldIDInfo-input"
                  style={{
                    gridTemplateColumns:
                      idData?.length > 0 ? "90% 10%" : "80% 10% 10%"
                  }}
                  key={index}
                >
                  <WorldTextInput
                    key={index}
                    universalStyle={{
                      display: "flex",
                      flexDirection: "column"
                    }}
                    identity={item}
                    type="text"
                    callback={(e) => handleId(index, e)}
                    callback2={(e) => handleBlurInput(idData, "id_number")}
                    callbackBin={(e) => removeIdData(index)}
                    lineStyle={
                      idData?.length > 0
                        ? {
                            display: "grid",
                            gridTemplateColumns: "89% 11%",
                            alignItems: "center"
                          }
                        : {}
                    }
                    focus={
                      newIdvar &&
                      idData?.length > 0 &&
                      index === idData.length - 1
                    }
                    removeFocus={() => handleIdFocus()}
                    label={`Identity number #${index + 1}`}
                    labelStyle={{
                      fontSize: "0.8rem"
                    }}
                    required={false}
                    placeholder="Identity number"
                    minRows={1}
                    maxRows={1}
                    minStrLen={9}
                    maxStrLen={13}
                    value={item}
                    inpStyle={{
                      maxWidth: "100%",
                      width: isMobileTablet() ? "70vw" : "100%",
                      padding: "0.5rem",
                      boxSizing: "border-box"
                    }}
                    bin={idData?.length > 1 ? true : false}
                    binStyle={{ width: "3rem" }}
                  />
                  <div></div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};;;

export default React.memo(UIWorldIDInfo, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
});
