import React, { useState, useEffect, useContext, useMemo, useRef } from "react";

import { useHistory } from "react-router-dom";
import "ui/serviceMsg.css";
import "ui/UI.css";

import DatabaseContext from "data/contextDatabase";
import GlobalContext from "contexts/context";
import TopicsContext from "contexts/contextTopics";
import { timestamp, randomString, isMobileTablet } from "hooks/helper";
import ServiceModal from "./ServiceModal";
import DateSelector from "./DateSelector";
import ServiceMessageContext from "contexts/contextServiceMessage";
import { GoReport } from "react-icons/go";
import {
  AiOutlineTool,
  AiOutlineQuestionCircle,
  AiOutlineSchedule,
  AiOutlineMessage
} from "react-icons/ai";
import { BiTachometer, BiAngry } from "react-icons/bi";
import { BsPiggyBank } from "react-icons/bs";
import { CgDanger, CgFileDocument, CgProfile } from "react-icons/cg";
import { FaTeamspeak } from "react-icons/fa";
import { GiEarthAfricaEurope } from "react-icons/gi";
import BsBoxWorld from "ui/icons/BsBoxWorld";
import GrDocTimeWorld from "ui/icons/GrDocTimeWorld";
import GrWorkshopWorld from "ui/icons/GrWorkshopWorld";
import { IoIosGlobe } from "react-icons/io";
import { IoCardOutline, IoEnterOutline, IoExitOutline } from "react-icons/io5";
import { MdOutlineCheckCircleOutline, MdOutlineGroups } from "react-icons/md";
import { RiInformationLine, RiCoinsLine } from "react-icons/ri";
import { TiContacts } from "react-icons/ti";
import StyleContext from "contexts/contextStyle";

import { linkProps } from "utils/urlTools";
import Linkify from "linkify-react";
import { MarkdownComponent } from "utils/textUtils";
import ServiceDropDown from "./ServiceDropDown";
import PersonasContext from "contexts/contextPersonas";
import { dex_action } from "data/dexUtils";

const isEqual = require("react-fast-compare");

const ServiceMsg = (props) => {
  const { personasState } = useContext(PersonasContext);
  const { databaseState } = useContext(DatabaseContext);
  const { globalState } = useContext(GlobalContext);
  const { topicsState, topicsDispatch } = useContext(TopicsContext);
  const { svcMsgState, svcMsgDispatch } = useContext(ServiceMessageContext);
  const [layout, setLayout] = useState(<div></div>);
  const titleStyle = {
    fontSize: "var(--service_message_fontsize)"
  };
  const [firstSet, setFirstSet] = useState([]);
  const liveTopics = topicsState.topics;
  const ref_msg_text = useRef(null);
  const [rows, setRows] = useState(1);
  const [minRows, setMinRows] = useState(1);
  const [maxRows, setMaxRows] = useState(5);
  const { styleState } = useContext(StyleContext);

  const lt = useRef(topicsState.topics);
  const history = useHistory();
const communityStatus = ["community:status", "community_status"];
// globalState.logging && console.log("SERVICEMSG", props.message);

const personaDescriptor = useMemo(
  () => (mpersona) => {
    globalState.logging &&
      console.log("personasState.descriptors", personasState.descriptors);
    return (
      personasState.descriptors?.filter((d) => d.mpersona === mpersona)[0]
        ?.digest || ""
    );
  },
  [personasState.descriptors]
);

useEffect(() => {
  const textareaLineHeight = 18;

  if (ref_msg_text?.current) {
    ref_msg_text.current.style.height = "auto";
    // const textareaLineHeight = 18;
    const previousRows = ref_msg_text.current.rows;
    ref_msg_text.current.rows = minRows; // reset number of rows in textarea

    const currentRows = ~~(
      ref_msg_text.current.scrollHeight / textareaLineHeight
    );

    if (currentRows === previousRows) {
      ref_msg_text.current.rows = currentRows;
    }
    if (currentRows >= maxRows) {
      ref_msg_text.current.rows = maxRows;
      ref_msg_text.current.scrollTop = ref_msg_text.current.scrollHeight;
    }
    setRows(currentRows < maxRows ? currentRows : maxRows);
    return () => {};
  }
}, [ref_msg_text?.current?.value]);

useEffect(() => {
  // Reset height - important to shrink on delete
  // setIsEdit(msgObject.msg_idx);
  if (ref_msg_text?.current?.value) {
    ref_msg_text.current.style.height = `${Math.min(
      ref_msg_text.current?.scrollHeight,
      90
    )}px`;
    ref_msg_text.current?.focus();
  }
}, [ref_msg_text?.current?.value]);

const idNoSmid = useMemo(
  () => (r) =>
    r.id?.startsWith(r.smid + "_") ? r.id.replace(r.smid + "_", "") : r.id,
  []
);

const matching = useMemo(
  () => (response, item) => {
    let r = { ...response };
    r.smid = props.message.smid;
    let origId = idNoSmid(r);
    globalState.logging &&
      console.log("!!!matching: ", response, item, r, origId);
    return origId === item.id;
  },
  []
);

const filterResult = useMemo(
  () => (r, item, event) => {
    let origId = idNoSmid(r);
    if (r.id && item.reset && item.reset.length > 0) {
      let noRegexMatch = item.reset.reduce((acc, pattern) => {
        let strPattern = pattern.regex || pattern;
        if (typeof strPattern === "string") {
          let regex = new RegExp(strPattern, "g");
          let test = !regex.test(origId);
          globalState.logging &&
            console.log("filterResult", origId, strPattern, test, r, item);
          return acc && test;
        } else {
          globalState.logging &&
            console.log("filterResult", origId, strPattern, test, r, item);
          return acc;
        }
      }, true);
      return noRegexMatch && r.id !== event.target.id;
    } else {
      globalState.logging && console.log("filterResult", origId, test, r, item);
      return r.id !== event.target.id;
    }
  },
  [idNoSmid]
);

// useEffect(() => {
//   // only runs when opening creating Service Message
//   // get all ids nested in props.items
//   let ids = props.message?.parameters?.service_msg?.items
//     ? getAll(props.message?.parameters?.service_msg?.items, "id", []).filter(
//         // find the shared start of all ids that are not just string numbers
//         (id) => isNaN(id) && id !== "Other"
//       )
//     : [];
//   let strStart = sharedStart(ids);
//   strStart = (strStart.match(/:/g) || []).length > 1 ? strStart : undefined;
//   // remove all items that do not have the shared start (with at least 2 ; ) from svcMsgState.response
//   if (strStart) {
//     let newResponse = svcMsgState.response.filter((r) =>
//       idNoSmid(r).startsWith(strStart)
//     );
//     // console.log("newResponse", strStart, svcMsgState.response, newResponse);
//     svcMsgDispatch({
//       type: "SET_RESPONSE",
//       values: { response: newResponse }
//     });
//   }
//   return () => {};
//   // eslint-disable-next-line react-hooks/exhaustive-deps
// }, [props.message?.parameters?.service_msg?.items]);

useEffect(() => {
  lt.current = topicsState.topics;
  return () => {};
}, [topicsState.topics]);

const handleClick = (event) => {
  event.target.disabled = true;
  event.currentTarget.style.opacity = 0.5;
  event.currentTarget.disabled = true;
};

useEffect(() => {
  const wait = (item, parent, mSeconds) => {
    svcMsgDispatch({
      type: "SET_WAIT_ID",
      values: {
        id: `${parent}_${item.id}_${item.position}`,
        value: true
      }
    });
    mSeconds > 0 &&
      setTimeout(() => {
        svcMsgDispatch({
          type: "SET_WAIT_ID",
          values: {
            id: `${parent}_${item.id}_${item.position}`,
            value: false
          }
        });
      }, mSeconds);
  };

  const send = (item, descriptor, payload) => {
    let jResponse = {
      type: "w.t.msg",
      content: { body: "" + item?.id, msgtype: "w.text" },
      mpersona: globalState?.persona?.mpersona,
      mtopic: props.message.mtopic,
      mpersona_rx: [props.message.mpersona],
      ts_sender: timestamp(),
      smid: randomString(8),
      status: "1",
      origin_sender: "registered",
      parameters: {
        descriptor: descriptor,
        control_payload: payload
      },
      version: globalState.version
    };
    globalState.logging && console.log("[ServiceMsg] send", jResponse);
    dex_action({
      type: "DEX_PUT",
      values: {
        db: databaseState.dexUser,
        table: "send",
        doc: jResponse
      }
    });
  };

  const chkField = (button, parent) => {
    let fieldOK = false;
    fieldOK = button.deps
      ? button.deps?.reduce((acc, field) => {
          //for each deps go through the form - if the dep field is present chk for validity
          if (acc) {
            if (svcMsgState.response?.length > 0) {
              if (
                svcMsgState.response?.filter((fldsmr) => {
                  return (
                    fldsmr.id?.substr(
                      props.message.smid?.length + 1,
                      fldsmr.id?.length - props.message.smid?.length + 1
                    ) === field ||
                    fldsmr.parent?.substr(
                      props.message.smid?.length + 1,
                      fldsmr.parent?.length - props.message.smid?.length + 1
                    ) === field
                  );
                }).length > 0
              ) {
              } else acc = false;
            } else acc = false;
          }
          return acc;
        }, true)
      : true;
    return (
      fieldOK &&
      !(
        svcMsgState.wait &&
        svcMsgState.wait[`${parent}_${button.id}_${button.position}`]
      )
    );
  };

  // const shouldFilter = (item) => {
  //   return item?.id?.startsWith("welcome:");
  // };

  const act = async (parent, item, response) => {
    let descriptor;
    switch (item.action) {
      // we can add special handling for specific item.action values
      case "toggle":
        // update response, with new button state.
        // Final submission will only show latest state of button
        let toggleTime = timestamp();
        let toggleState = svcMsgState.response.filter(
          (r) => r.id === props.message.smid + "_" + item?.id
        )[0]?.value?.state;
        let toggleResponse = [
          {
            mtopic: props.message.mtopic,
            msg_idx: props.message.msg_idx,
            smid: props.message.smid,
            parent: props.message.smid + "_" + parent?.id,
            id: props.message.smid + "_" + item.id,
            position: item.position,
            value: {
              id: item.id,
              action: item.action,
              state: !toggleState,
              by: {
                persona: globalState.persona?.persona,
                mpersona: globalState.persona?.mpersona,
                timestamp: toggleTime
              }
            }
          },
          ...response.filter(
            (r) => r.id !== props.message.smid + "_" + item?.id
          )
        ];
        svcMsgDispatch({
          type: "SET_RESPONSE",
          values: { response: [...toggleResponse] }
        });
        // if state = "selected", show child items
        // when state changes, send notification
        let notification = {};
        notification.state = {
          id: item.id,
          action: item.action,
          by: {
            persona: globalState.persona?.persona,
            mpersona: globalState.persona?.mpersona,
            timestamp: toggleTime
          }
        };
        globalState.logging &&
          console.log("[ServiceMsg] toggle", item, descriptor, notification);
        send(item, descriptor, notification);
        return;
      case "goto":
        globalState.logging && console.log("[ServiceMsg] goto");
        svcMsgDispatch({ type: "SET_MODAL", values: { modal: undefined } });
        let targetTopicMatches = topicsState.topics?.filter(
          (t) =>
            t.mtopic === item?.target && t.mpersona === props.message.recipient
        );
        globalState.logging &&
          console.log("[ServiceMsg] targetTopicMatches", targetTopicMatches[0]);
        let timeresp = timestamp();
        let resp = {};
        resp.state = {
          ...props.message.parameters.service_msg.state,
          id: item.id,
          action: item.action,
          by: {
            persona: globalState.persona?.persona,
            mpersona: globalState.persona?.mpersona,
            timestamp: timeresp
          },
          mtopic: item?.target,
          subscribed: targetTopicMatches?.length > 0 ? "true" : "false"
        };
        resp.response = response.map((r) => {
          let p = { ...r };
          p.parent = p.parent.replace(p.smid + "_", "");
          p.id = p.id.replace(p.smid + "_", "");
          return p;
        });
        globalState.logging &&
          console.log("[ServiceMsg] targetTopicMatches", targetTopicMatches);

        if (targetTopicMatches?.length > 0) {
          // is member, navigate to topic
          if (isMobileTablet()) {
            history.replace("/UIMessageList", {
              subscription: targetTopicMatches[0]
            });
          } else {
            topicsDispatch({
              type: "SET_SHOWTOPIC",
              values: { showTopic: { subscription: targetTopicMatches[0] } }
            });
          }
        } else {
          // not yet member, subscribe AND request access to bot (covering both public and private cases)
          globalState.logging &&
            console.log("[ServiceMsg] send goto", item, descriptor, resp);
          send(item, descriptor, resp);
          let j = {
            version: globalState.version,
            type: "w.t.join",
            source: "goto",
            mtopic: item.target,
            mpersona: props.you,
            persona: props.persona,
            smid: randomString(8),
            ts_sender: timestamp(),
            origin_sender: "registered"
          };
          globalState.logging && console.log("[ServiceMsg] w.t.join", j);
          databaseState.dexUser &&
            dex_action({
              type: "DEX_PUT",
              values: {
                db: databaseState.dexUser,
                table: "send",
                doc: j
              }
            });
        }
        return;
      case "closeOnClick":
        svcMsgDispatch({ type: "SET_MODAL", values: { modal: undefined } });
        descriptor = personaDescriptor(globalState.persona?.mpersona);
        // let itemDisable = ""
        // if (item.disable && (item.disable === "true" || item.disable === "disabledBtn")) {
        //   itemDisable = "disabledBtn"
        // }
        let timeClose = timestamp();
        let closeResponse = [
          {
            mtopic: props.message.mtopic,
            msg_idx: props.message.msg_idx,
            smid: props.message.smid,
            parent: props.message.smid + "_" + parent?.id,
            id: props.message.smid + "_" + item.id,
            position: item.position,
            value: {
              action: item.action,
              by: {
                persona: globalState.persona?.persona,
                mpersona: globalState.persona?.mpersona,
                timestamp: timeClose
              }
            }
            // disable: itemDisable
          },
          ...response.filter(
            (r) => r.parent !== props.message.smid + "_" + parent?.id
          )
        ];
        svcMsgDispatch({
          type: "SET_RESPONSE",
          values: { response: [...closeResponse] }
        });
        let closePayload = {};
        closePayload.state = {
          id: item.id,
          action: item.action,
          by: {
            persona: globalState.persona?.persona,
            mpersona: globalState.persona?.mpersona,
            timestamp: timeClose
          }
        };
        closePayload.response = closeResponse.map((r) => {
          let p = { ...r };
          p.parent = p.parent.replace(p.smid + "_", "");
          p.id = p.id.replace(p.smid + "_", "");
          return p;
        });
        props.message?.parameters?.service_msg?.state &&
          (closePayload.response = [
            ...closePayload.response,
            { state: props.message.parameters.service_msg.state }
          ]);
        globalState.logging &&
          console.log(
            "[ServiceMsg] send closeOnClick",
            item,
            descriptor,
            closePayload
          );
        send(item, descriptor, closePayload);
        return;
      default:
        descriptor = personaDescriptor(globalState.persona?.mpersona);
        let time = timestamp();
        let newResponse = [
          {
            mtopic: props.message.mtopic,
            msg_idx: props.message.msg_idx,
            smid: props.message.smid,
            parent: props.message.smid + "_" + parent?.id,
            id: props.message.smid + "_" + item.id,
            position: item.position,
            value: {
              id: item.id,
              action: item.action,
              by: {
                persona: globalState.persona?.persona,
                mpersona: globalState.persona?.mpersona,
                timestamp: time
              }
            }
          },
          ...response.filter(
            (r) => r.parent !== props.message.smid + "_" + parent?.id
          )
        ];
        svcMsgDispatch({
          type: "SET_RESPONSE",
          values: { response: [...newResponse] }
        });
        let payload = {};
        payload.state = {
          id: item.id,
          action: item.action,
          by: {
            persona: globalState.persona?.persona,
            mpersona: globalState.persona?.mpersona,
            timestamp: time
          }
        };
        payload.response = newResponse.map((r) => {
          let p = { ...r };
          p.parent = p.parent.replace(p.smid + "_", "");
          p.id = p.id.replace(p.smid + "_", "");
          return p;
        });
        props.message?.parameters?.service_msg?.state &&
          (payload.response = [
            ...payload.response,
            { state: props.message.parameters.service_msg.state }
          ]);
        globalState.logging &&
          console.log("[ServiceMsg] send default", item, descriptor, payload);
        send(item, descriptor, payload);
        return;
    }
  };

  const convtoUpper = (text) => {
    return text.toUpperCase();
  };

  const styleBtnOne = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#008080"
  };
  const styleBtnGeneral = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ADD5D5"
  };

  const buttonTypes = [
    {
      title: "Services",
      icon: <AiOutlineTool />
    },
    {
      title: "Info",
      icon: <RiInformationLine />
    },
    {
      title: "Account",
      icon: <RiCoinsLine />
    },
    {
      title: "Report",
      icon: <GoReport />
    },
    {
      title: "Finance",
      icon: <BsPiggyBank />
    },
    {
      title: "Portal",
      icon: <IoIosGlobe />
    },
    {
      title: "Meters",
      icon: <BiTachometer />
    },
    {
      title: "Docs",
      icon: <CgFileDocument />
    },
    {
      title: "Meetings",
      icon: <AiOutlineSchedule />
    },
    {
      title: "Visitors",
      icon: <MdOutlineGroups />
    },
    {
      title: "Directory",
      icon: <TiContacts />
    },
    {
      title: "Profile",
      icon: <CgProfile />
    },
    {
      title: "Panic",
      icon: <CgDanger />
    },
    {
      title: "Exit",
      icon: <IoExitOutline size={20} />
    },
    {
      title: "Enter",
      icon: <IoEnterOutline />
    },
    {
      title: "Help",
      icon: <AiOutlineQuestionCircle />
    },
    {
      title: "Chat",
      icon: <AiOutlineMessage />
    },
    {
      title: "Complaint",
      icon: <BiAngry />
    },
    {
      title: "Ask",
      icon: <FaTeamspeak />
    },
    {
      title: "Approvals",
      icon: <MdOutlineCheckCircleOutline />
    },
    {
      title: "WORLD",
      icon: <GiEarthAfricaEurope />
    },
    {
      title: "Timesheet",
      icon: <GrDocTimeWorld strokecolor="white" fillcolor="white" />
      //  GrDocumentTime classname="icon" size={40} />
    },
    {
      title: "Work Order",
      icon: <GrWorkshopWorld strokecolor="white" fillcolor="white" />
    },
    {
      title: "Expense",
      icon: <IoCardOutline />
    },
    {
      title: "Material",
      icon: (
        <BsBoxWorld strokecolor="white" fillcolor="white" strokewidth="0.25" />
      )
    }
  ];

  const getIndex = (value, arr, prop) => {
    for (var i = 0; i < (arr?.length || 0); i++) {
      if (arr[i][prop] === value) {
        return i;
      }
    }
    return -1; //to handle the case where the value doesn't exist
  };

  const popupItem = (parent, item, liveTopics, noBtns) => {
    let popup = (
      <ServiceModal
        parent={parent}
        visible={true}
        act={act}
        liveTopics={liveTopics}
        items={item.items}
        header={item.header}
        message={props.message}
        onClose={() =>
          svcMsgDispatch({
            type: "SET_MODAL",
            values: { modal: undefined }
          })
        }
      ></ServiceModal>
    );
    let btn = (
      <div>
        <button
          className={
            // props.message.parameters.service_msg.id === "community:status"
            communityStatus.includes(props.message.parameters.service_msg.id)
              ? "ezist_btn"
              : "UI-button-service"
          }
          disabled={!chkField(item, parent)}
          // type={chkField(item, parent) ? {} : "greyOpacity"}style={{
          style={
            // props.message.parameters.service_msg.id === "community:status"
            communityStatus.includes(props.message.parameters.service_msg.id)
              ? getIndex(item.title, buttonTypes, "title") !== -1
                ? styleBtnOne
                : styleBtnGeneral
              : noBtns > 3 && isMobileTablet()
              ? { minWidth: "4.4rem" }
              : {}
          }
          title={item.title ? item.title : ""}
          onClick={(ev) => {
            if (chkField(item, parent)) {
              svcMsgDispatch({
                type: "SET_MODAL",
                values: { modal: popup }
              });
              wait(item, parent, item.wait || 10000);
            }
          }}
        >
          <span
            style={
              // props.message.parameters.service_msg.id === "community:status"
              communityStatus.includes(props.message.parameters.service_msg.id)
                ? {
                    fontSize: "3.2rem",
                    color: "white",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center"
                  }
                : {}
            }
          >
            {
              // props.message.parameters.service_msg.id === "community:status"
              communityStatus.includes(
                props.message.parameters.service_msg.id
              ) ? (
                getIndex(item.title, buttonTypes, "title") > -1 ? (
                  buttonTypes[getIndex(item.title, buttonTypes, "title")].icon
                ) : (
                  <span>{item.title}</span>
                )
              ) : (
                <span>{item.title}</span>
              )
            }
          </span>
        </button>
        {
          // props.message.parameters.service_msg.id === "community:status"
          communityStatus.includes(props.message.parameters.service_msg.id) && (
            <span
              style={
                item.title?.length < 9
                  ? {
                      fontSize: "0.85rem",
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "0.25rem"
                    }
                  : {
                      fontSize: "0.85rem",
                      display: "flex",
                      textAlign: "center",
                      justifyContent: "center",
                      marginTop: "0.25rem"
                    }
              }
            >
              {item.title}
            </span>
          )
        }
      </div>
    );
    return <div> {btn}</div>;
  };

  const linkItem = (parent, item, liveTopics) => {
    let btn = (
      <div>
        <button
          className={
            // props.message.parameters.service_msg.id === "community:status"
            communityStatus.includes(props.message.parameters.service_msg.id)
              ? "ezist_btn"
              : "UI-button-service"
          }
          disabled={!chkField(item, parent)}
          // type={chkField(item, parent) ? "" : "greyOpacity"}
          style={
            // props.message.parameters.service_msg.id === "community:status"
            communityStatus.includes(props.message.parameters.service_msg.id)
              ? getIndex(item.title, buttonTypes, "title") !== -1
                ? styleBtnOne
                : styleBtnGeneral
              : {}
          }
          title={item.title ? item.title : ""}
          onClick={(ev) => {
            if (chkField(item, parent)) {
              window.open(item.url, "_blank").focus();
              wait(item, parent, item.wait || 10000);
            }
          }}
        >
          <span
            style={
              // props.message.parameters.service_msg.id === "community:status"
              communityStatus.includes(props.message.parameters.service_msg.id)
                ? {
                    fontSize: "3.2rem",
                    color: "white",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center"
                  }
                : {}
            }
          >
            {
              // props.message.parameters.service_msg.id === "community:status"
              communityStatus.includes(
                props.message.parameters.service_msg.id
              ) ? (
                getIndex(item.title, buttonTypes, "title") > -1 ? (
                  buttonTypes[getIndex(item.title, buttonTypes, "title")].icon
                ) : (
                  <span>{item.title}</span>
                )
              ) : (
                <span>{item.title}</span>
              )
            }
          </span>
        </button>
        {
          // props.message.parameters.service_msg.id === "community:status"
          communityStatus.includes(props.message.parameters.service_msg.id) && (
            <span
              style={
                item.title?.length < 9
                  ? {
                      fontSize: "0.85rem",
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "0.25rem"
                    }
                  : {
                      fontSize: "0.85rem",
                      display: "flex",
                      textAlign: "center",
                      justifyContent: "center",
                      marginTop: "0.25rem"
                    }
              }
            >
              {item.title}
            </span>
          )
        }
      </div>
    );
    return <div>{btn}</div>;
  };

  const updateDefaults = (parent, item, indx) => {
    let resp = [
      {
        mtopic: props.message.mtopic,
        msg_idx: props.message.msg_idx,
        smid: props.message.smid,
        parent: parent,
        id: parent + "_" + item.id,
        position: item.position,
        value: item.default
      },
      ...svcMsgState.response.filter((r) => r.position !== item.position)
    ];
    svcMsgDispatch({
      type: "SET_RESPONSE",
      values: { response: resp }
    });
    const tmp = [...firstSet];
    tmp[indx] = true;
    setFirstSet(tmp);
  };

  let counter = 0;
  const nested = (parent, items, classStyle1, classStyle2) => {
    let objects = items.map((item, indx) => {
      counter = counter + 1;
      switch (item.type) {
        case "link":
          return linkItem(props.message.smid + "_" + item.id, item, lt.current);
        case "pop-up":
          return popupItem(
            props.message.smid + "_" + item.id,
            item,
            lt.current,
            item.buttons?.length
          );
        case "dropdown":
          let children = undefined;
          const onChange = (e) => {
            globalState.logging &&
              console.log("!!!onChange:", e.target.selectedOptions[0].id);
            let resp = [
              {
                mtopic: props.message.mtopic,
                msg_idx: props.message.msg_idx,
                smid: props.message.smid,
                parent: parent,
                id: e.target.id,
                position: item.position,
                value: e.target.selectedOptions[0].id
              },
              ...svcMsgState.response.filter((r) =>
                // shouldFilter(item)
                // ? r.position < item.position
                filterResult(r, item, e)
              )
            ];
            svcMsgDispatch({
              type: "SET_RESPONSE",
              values: { response: resp }
            });
            globalState.logging && console.log("!!!onChange resp:", resp);
            if (
              resp.filter(
                (r) =>
                  r.id === props.message.smid + "_" + item.id &&
                  r.value === e.target.selectedOptions[0].id
              ).length > 0 &&
              e.target.selectedOptions[0].attributes.items
            ) {
              // Get the seleted Item original
              let selectedItem = false;
              item.options.forEach((i) => {
                if (i.id === e.target.selectedOptions[0].id) {
                  selectedItem = i;
                }
              });
              globalState.logging &&
                console.log("!!!onChange selectedItem:", selectedItem);
              // if (selectedItem.items) {
              //   children = nested(
              //     props.message.smid + "_" + e.target.selectedOptions[0].id,
              //     selectedItem.items,
              //     "lines",
              //     "na"
              //   );
              //   console.log("!!!onChange children:", children);
              // }
            }
          };

          /* if response contains match smid and selected item has children, show then here */
          // relatedResponse enumerats responses in the svcMsgState that match the item
          // then if the selected option contains "items" they are expanded as children
          let relatedResponse = svcMsgState.response.filter((r) =>
            matching(r, item)
          );
          children = undefined;
          item?.options.forEach((opt) => {
            globalState.logging &&
              console.log("!!!opt: ", opt, item, relatedResponse);
            if (
              opt.items &&
              relatedResponse.map((r) => r.value).includes(opt.id)
            ) {
              children = nested(
                props.message.smid + "_" + item.id,
                opt.items,
                "lines",
                "na"
              );
              globalState.logging &&
                console.log("!!!onChange children2:", children);
            }
          });
          let dropdown = (
            <div
              className={classStyle1 === "lines" ? "lines" : null}
              key={"div_" + props.message.smid + "_" + item.id}
            >
              <ServiceDropDown
                item={item}
                classStyle1={classStyle1}
                parent={parent}
                svcMsgState={svcMsgState}
                filterResult={filterResult}
                svcMsgDispatch={svcMsgDispatch}
                message={props.message}
                onChange={onChange}
              ></ServiceDropDown>

              {children}
            </div>
          );
          return dropdown;
        case "checkbox_group":
          const onChangeCheckBox = (event, box) => {
            // console.log("box_1", event);
            let selected = document.getElementById(
              props.message.smid + "_" + box.id
            ).checked;
            // apply a default reset of location
            // item.reset = item.reset || ["^" + idNoSmid(item.id) ];
            let resp = selected
              ? [
                  {
                    mtopic: props.message.mtopic,
                    msg_idx: props.message.msg_idx,
                    smid: props.message.smid,
                    parent: props.message.smid + "_" + item.id,
                    id: props.message.smid + "_" + box.id,
                    position: item.position,
                    value: selected
                  },
                  ...svcMsgState.response.filter((r) =>
                    filterResult(r, item, event)
                  )
                ]
              : svcMsgState.response.filter((r) =>
                  filterResult(r, item, event)
                );
            // console.log("box_resp", resp);
            svcMsgDispatch({
              type: "SET_RESPONSE",
              values: { response: resp }
            });
          };
          let checkbox_group = (
            <div
              style={{
                // marginLeft: "0.75rem",
                fontSize: "var(--service_message_fontsize)",
                marginTop: "0.25rem",
                marginBottom: "0.25rem"
              }}
            >
              <div>{item.title}</div>
              <div>
                {Array.isArray(item?.boxes) &&
                  item?.boxes.map((box) => (
                    <div
                      className={
                        props.type === "right"
                          ? "right-align-label"
                          : "left-align-label"
                      }
                      key={"div_" + props.message.smid + "_" + box.id}
                    >
                      <label>
                        <input
                          className="checkbox"
                          type="checkbox"
                          id={props.message.smid + "_" + box.id}
                          checked={
                            svcMsgState.response.filter(
                              (r) => r.id === props.message.smid + "_" + box.id
                            )[1]?.value
                          }
                          onChange={(event) => onChangeCheckBox(event, box)}
                        />
                        {box.title}
                      </label>
                    </div>
                  ))}
              </div>
            </div>
          );
          return checkbox_group;
        case "radio_group":
          let radio_group = (
            <div
              key={"div_" + props.message.smid + "_" + item.id}
              style={{ marginBottom: "0.5rem" }}
              onChange={(event) => {
                let selected = event.target.id;
                let resp = selected
                  ? [
                      {
                        mtopic: props.message.mtopic,
                        msg_idx: props.message.msg_idx,
                        smid: props.message.smid,
                        parent: props.message.smid + "_" + item.id,
                        id: event.target.id,
                        position: item.position,
                        value: true
                      },
                      ...svcMsgState.response.filter((r) => {
                        return (
                          filterResult(r, item, event) &&
                          !item?.radio_buttons.some(
                            (el) =>
                              el.id ===
                              r.id.split(props.message.smid + "_").pop()
                          )
                        );
                      })
                    ]
                  : svcMsgState.response.filter((r) =>
                      filterResult(r, item, event)
                    );
                svcMsgDispatch({
                  type: "SET_RESPONSE",
                  values: { response: resp }
                });
              }}
            >
              {Array.isArray(item?.radio_buttons) &&
                item?.radio_buttons.map((radio) => (
                  <div
                    key={"div_" + props.message.smid + "_" + radio.id}
                    className={
                      props.type === "right"
                        ? "right-align-label"
                        : "left-align-label"
                    }
                  >
                    <label
                      style={{ paddingLeft: "1rem" }}
                      htmlFor={props.message.smid + "_" + radio.id}
                    >
                      <input
                        // className="rbtn"
                        disabled={item.locked === "true"}
                        type="radio"
                        id={props.message.smid + "_" + radio.id}
                        name={props.message.smid + "_" + item.id}
                        value={radio.title}
                        checked={
                          item.selected
                            ? item.selected === radio.id
                            : svcMsgState.response.filter(
                                (r) =>
                                  r.id === props.message.smid + "_" + radio.id
                              )[0]?.value
                        }
                      />
                      {/* <label
                      className="label" */}

                      {/* > */}
                      <span>{radio.title}</span>
                    </label>
                  </div>
                ))}
            </div>
          );
          return (
            <div
              className="radio-header"
              key={"div_" + props.message.smid + "_" + item.id}
            >
              {item.title}
              {radio_group}
            </div>
          );
        case "text":
          let text_field = (
            // <Linkify options={linkProps}>
            <div
              className="textfield"
              style={
                item.title?.trim() === ""
                  ? {
                      display: "none",
                      marginLeft: "1.25rem"
                    }
                  : {}
              }
              key={"div_" + props.message.smid + "_" + item.id}
            >
              <MarkdownComponent content={item.title ? item.title : ""} />
              <MarkdownComponent
                content={item.title && item.value ? ": " : ""}
              />
              <MarkdownComponent content={item.value} />
            </div>
            // </Linkify>
          );
          return text_field;
        case "hidden":
          let newItem = {
            mtopic: props.message.mtopic,
            msg_idx: props.message.msg_idx,
            smid: props.message.smid,
            parent: parent,
            id: props.message.smid + "_" + item.id,
            position: item.position,
            value: item.value
          };
          if (
            svcMsgState.response.filter((r) => isEqual(newItem, r)).length === 0
          ) {
            let resp = [
              newItem,
              ...svcMsgState.response.filter(
                (r) => r.id !== props.message.smid + "_" + item.id
              )
            ];
            svcMsgDispatch({
              type: "SET_RESPONSE",
              values: { response: resp }
            });
          }
          return <></>;
        case "button_group":
          let button_group =
            Array.isArray(item?.buttons) &&
            item?.buttons.map((button) => {
              let buttonState = svcMsgState.response.filter(
                (r) => r.id === props.message.smid + "_" + button?.id
              )[0]?.value?.state;
              switch (button.type) {
                case "pop-up":
                  return popupItem(
                    props.message.smid + "_" + button?.id,
                    button,
                    lt.current,
                    item.buttons?.length
                  );
                case "link":
                  return linkItem(
                    props.message.smid + "_" + button?.id,
                    button,
                    lt.current
                  );
                default:
                  return (
                    <div>
                      <button
                        key={
                          "div_" +
                          props.message.smid +
                          "_" +
                          button.id +
                          "_" +
                          button.position
                        }
                        className="UI-button-service"
                        style={
                          isMobileTablet()
                            ? { maxWidth: "4.4rem", minWidth: "4.4rem" }
                            : {}
                        }
                        disabled={!chkField(button, parent)}
                        onClick={(ev) => {
                          if (chkField(button, parent)) {
                            act(item, button, svcMsgState.response).then(
                              () => {}
                            );
                            if (
                              button.action === "goto" &&
                              !liveTopics
                                .map((t) => t.mtopic)
                                .includes(button.target)
                            ) {
                              setTimeout(() => {
                                ev.target.innerHTML = !lt.current
                                  .map(
                                    (t) =>
                                      t.mpersona === props.message.recipient &&
                                      t.mtopic
                                  )
                                  .includes(button.target)
                                  ? `${button.alttitle || button["alt.title"]}`
                                  : button.title;
                              }, 5000);
                              ev.target.innerHTML =
                                button.waitingtitle || "Trying...";
                            }
                            wait(button, parent, button.wait || 10000);
                          }
                        }}
                      >
                        {button.action === "goto" &&
                        !liveTopics
                          .map(
                            (t) =>
                              t.mpersona === props.message.recipient && t.mtopic
                          )
                          .includes(button.target)
                          ? `${button.alttitle || button["alt.title"]}`
                          : button.title}
                      </button>
                      {button.items &&
                        buttonState &&
                        nested(props.message.smid, button.items, "lines", "na")}
                    </div>
                  );
              }
            });
          return (
            <div
              className={
                communityStatus.includes(
                  props.message.parameters.service_msg.id
                )
                  ? // props.message?.parameters?.service_msg?.id ==="community:status"
                    // props.message.topic.toUpperCase().includes("STARWOOD") ||
                    // props.message.topic.toUpperCase().includes("COURT"))
                    "ezist-btn-layout"
                  : "button-layout-service"
              }
              style={
                item.buttons?.length > 3 &&
                isMobileTablet() &&
                !communityStatus.includes(
                  props.message.parameters.service_msg.id
                )
                  ? // props.message?.parameters?.service_msg?.id !== "community:status"
                    {
                      gridTemplateColumns: "repeat(auto-fill, 4.4rem)",
                      gap: "0.4rem"
                    }
                  : {}
              }
              key={"div_" + props.message.smid + "_" + item.id}
              type={item?.buttons[0]?.type}
            >
              {button_group}
            </div>
          );
        case "date_input":
          //removed the follwing code and inserted the current date and time as initialDate
          // let initialDate = new Date(
          //   svcMsgState.response?.filter(
          //     (r) => r.id === props.message.smid + "_" + item.id
          //   )[0]?.value || item.date
          // );
          let initialDate = new Date();
          let date_input = (
            <div
              style={{ margin: "0.4rem" }}
              key={"div_" + props.message.smid + "_" + item.id}
            >
              {/* {item.title} */}
              <DateSelector
                label={item.title}
                shouldCloseOnSelect={false}
                readOnly={item.readOnly === "true" ? true : false}
                selected={initialDate || new Date()}
                timeInputLabel="Time:"
                dateFormat={item.format || "DD MMM yyyy HH:mm"}
                showTimeInput
                value={initialDate || new Date()}
                onChange={(date) => {
                  let resp = [
                    {
                      mtopic: props.message.mtopic,
                      msg_idx: props.message.msg_idx,
                      smid: props.message.smid,
                      parent: parent,
                      id: props.message.smid + "_" + item.id,
                      position: item.position,
                      value: date.toISOString()
                    },
                    ...svcMsgState.response.filter(
                      (r) => r.position !== item.position
                    )
                  ];
                  !item.locked &&
                    svcMsgDispatch({
                      type: "SET_RESPONSE",
                      values: { response: resp }
                    });
                }}
              />
            </div>
          );
          return date_input;
        case "text_input":
          setMaxRows(item.lines);
          let text_input = (
            <div
              // className="textfield"
              key={"div_" + props.message.smid + "_" + item.id}
            >
              <label
                for={"txtinput_" + props.message.smid + "_" + item.id}
                style={
                  item?.title?.length > 0 ? titleStyle : { display: "none" }
                }
              >
                {item.title}
              </label>
              <input
                style={{
                  // marginLeft: "0.75rem",
                  paddingLeft: "0.5rem",
                  fontSize: "var(--service_message_fontsize)",
                  border: "1px solid var(--modal_border_color)",
                  borderRadius: "var(--border_radius_small)",
                  marginBottom: "0.5rem"
                }}
                type="text"
                placeholder={
                  svcMsgState.response.filter(
                    (r) => r.id === props.message.smid + "_" + item.id
                  )[0]?.value || item.hint
                }
                value={
                  !firstSet[indx] && item.default
                    ? updateDefaults(parent, item, indx)
                    : svcMsgState.response.filter(
                        (r) => r.id === props.message.smid + "_" + item.id
                      )[0]?.value || ""
                }
                pattern={item.regex}
                onChange={(e) => {
                  let valid = e.target.validity.valid;
                  if (valid && e.target?.value?.length > 0) {
                    let resp = [
                      {
                        mtopic: props.message.mtopic,
                        msg_idx: props.message.msg_idx,
                        smid: props.message.smid,
                        parent: parent,
                        id: e.target.id.replace("txtinput_", ""),
                        position: item.position,
                        value: e.target.value
                      },
                      ...svcMsgState.response.filter(
                        (r) => r.position !== item.position
                      )
                    ];
                    svcMsgDispatch({
                      type: "SET_RESPONSE",
                      values: { response: resp }
                    });
                  } else {
                    e.target.value = null;
                    let resp = [
                      ...svcMsgState.response.filter(
                        (r) => r.position !== item.position
                      )
                    ];
                    svcMsgDispatch({
                      type: "SET_RESPONSE",
                      values: { response: resp }
                    });
                  }
                }}
                id={"txtinput_" + props.message.smid + "_" + item.id}
                name={"txtinput_" + props.message.smid + "_" + item.id}
              />
            </div>
          );
          return text_input;
        case "text_multiline":
          setMaxRows(item.lines);
          let text_multiline = (
            <div key={"div_" + props.message.smid + "_" + item.id}>
              <label
                for={"txtinput_" + props.message.smid + "_" + item.id}
                style={
                  item?.title?.length > 0 ? titleStyle : { display: "none" }
                }
              >
                {item.title}
              </label>

              <textarea
                style={{
                  // marginLeft: "0.75rem",
                  paddingLeft: "0.5rem",
                  fontFamily:
                    styleState?.theme?.fonts?.topic_name_main_screen_font,
                  fontSize: "1.25rem",
                  // fontSize: "var(--service_message_fontsize)",
                  border: "1px solid var(--modal_border_color)",
                  borderRadius: "var(--border_radius_small)",
                  marginBottom: "0.5rem",
                  width: "90%"
                }}
                rows={item.lines}
                // type="text"
                placeholder={
                  svcMsgState.response.filter(
                    (r) => r.id === props.message.smid + "_" + item.id
                  )[0]?.value || item.hint
                }
                value={
                  firstSet
                    ? svcMsgState.response.filter(
                        (r) => r.id === props.message.smid + "_" + item.id
                      )[0]?.value ||
                      item.default ||
                      ""
                    : svcMsgState.response.filter(
                        (r) => r.id === props.message.smid + "_" + item.id
                      )[0]?.value || ""
                }
                pattern={item.regex}
                onChange={(e) => {
                  setFirstSet(false);
                  let valid = e.target.validity.valid;
                  if (valid) {
                    let resp = [
                      {
                        mtopic: props.message.mtopic,
                        msg_idx: props.message.msg_idx,
                        smid: props.message.smid,
                        parent: parent,
                        id: e.target.id.replace("txtinput_", ""),
                        position: item.position,
                        value: e.target.value
                      },
                      ...svcMsgState.response.filter(
                        (r) => r.position !== item.position
                      )
                    ];
                    svcMsgDispatch({
                      type: "SET_RESPONSE",
                      values: { response: resp }
                    });
                  } else {
                    e.target.value = null;
                    let resp = [
                      ...svcMsgState.response.filter(
                        (r) => r.position !== item.position
                      )
                    ];
                    svcMsgDispatch({
                      type: "SET_RESPONSE",
                      values: { response: resp }
                    });
                  }
                }}
                id={"txtinput_" + props.message.smid + "_" + item.id}
                name={"txtinput_" + props.message.smid + "_" + item.id}
              />
            </div>
          );
          return text_multiline;
        default:
          return undefined;
      }
    });
    return objects;
  };

  setLayout(
    <div
      style={{
        width: "100%",
        marginTop: "0.8rem"
      }}
    >
      {props.message?.parameters?.service_msg?.items &&
        nested(
          props.message.smid,
          props.message?.parameters?.service_msg?.items,
          "lines",
          "na"
        )}
      {props.message?.parameters?.service_msg?.items && svcMsgState.modal}
    </div>
  );
  // console.log("RESPONSE !!!", svcMsgState);
  return () => {};
}, [
  databaseState.dexAdmin,
  databaseState.dexUser,
  topicsState.topics,
  globalState.persona?.mpersona,
  globalState.persona?.persona,
  globalState.version,
  history,
  liveTopics,
  props.message,
  props.message.mpersona,
  props.message.msg_idx,
  props.message.mtopic,
  props.message.parameters.service_msg?.items,
  props.message.parameters?.show_menu,
  props.message.smid,
  props.persona,
  props.roles,
  props.type,
  props.you,
  svcMsgState.response,
  svcMsgState.wait,
  svcMsgState.modal
]);

  return (
    <div
      style={{
        width: "100%"
      }}
    >
      {layout}
    </div>
  );
};

export default React.memo(ServiceMsg, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
});
