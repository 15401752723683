import React, { useContext, useState, createRef, useEffect, useCallback, useReducer, useRef } from "react";
// import ClickOutsideDetector from "utils/ClickOutsideDetector";
import "./UI.css";
import "./modals.css";
import { getImage64, storeImage } from "data/descriptors";
import { s3ToImage } from "connectivity/s3";
import { useHistory } from "react-router-dom";
// import { useLocation } from 'react-router-dom'

import DatabaseContext from "data/contextDatabase";
import { AiOutlineClose } from 'react-icons/ai'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'

const UIProfileModal = (props) => {
  let locProps = props.location.state;
  const [dimensions, setDimensions] = useState({ wdth: 0, hgt: 0 });
  const imgRef = createRef();
  const [picStyle, setPicStyle] = useState({});
  const [isLoaded, setIsLoaded] = React.useState(false);
  const { databaseState } = useContext(DatabaseContext);
  const [newImg, setNewImg] = useState(undefined);
  const [found, setFound] = useState(false);
  let history = useHistory()
  const [index, setIndex] = useState()
  const modalcontent = useRef()
  const [zoomFctr, setZoomFctr] = useState(1)

  useEffect(() => {
    let timer1 = setTimeout(() => {
      setIsLoaded(true);
    }, 10000);
    if (found) clearTimeout(timer1);
    return () => clearTimeout(timer1);
  }, [imgRef, found]);

  useEffect(() => {
    handleImageSize();
  }, [zoomFctr]);

  const heightStyle = (h) => {
    return {
      height: h * 1 + "px",
      width: "auto",
      resizeMode: "cover",
      objectFit: "contain",
      borderRadius: "var(--border_radius_large)",
      transform: `scale(${zoomFctr})`
    };
  };

  const widthStyle = (w) => {
    return {
      width: w * 1 + "px",
      height: "auto",
      resizeMode: "cover",
      objectFit: "contain",
      borderRadius: "var(--border_radius_large)",
      transform: `scale(${zoomFctr})`
    };
  };

  useEffect(() => {
    let i = new Image();
    i.onload = function () {
      setDimensions({
        wdth: i.width,
        hgt: i.height
      });
    };
    i.src = newImg;
    return () => { };
  }, [newImg]);

  const handleImageSize = () => {
    let w = 0;
    let h = 0;
    let imgwid = dimensions.wdth;
    let imghgt = dimensions.hgt;
    const ratio = imgwid / imghgt;
    const sameType = () => {
      w = window.innerWidth;
      h = window.innerWidth / ratio;
      if (h > window.innerHeight) {
        h = window.innerHeight;
        w = h * ratio;
      }
    };

    if (imgwid > imghgt) {
      if (window.innerWidth > window.innerHeight) {
        sameType();
      } else {
        w = window.innerWidth;
        h = w / ratio;
      }
    } else {
      if (window.innerWidth > window.innerHeight) {
        h = window.innerHeight;
        w = h * ratio;
      } else {
        sameType();
      }
    }
    if (w > h) setPicStyle(heightStyle(h));
    else setPicStyle(widthStyle(w));
  };

  useEffect(() => {
    window.addEventListener("resize", handleImageSize);
    return () => {
      window.removeEventListener("resize", handleImageSize);
    };
  }, [dimensions, newImg]);

  useEffect(() => {
    if (locProps.avatar) {
      if (locProps.picArr) {
        setIndex(
          locProps.picArr.findIndex(
            (x) => x.thumbdigest === locProps.descriptor.thumbdigest
          )
        );
      }
      locProps.descriptor?.imgdigest &&
        // locProps.imgdigest &&
        getImage64(databaseState.dexUser, locProps.descriptor.imgdigest)
          .then((img) => {
            if (img) {
              setNewImg(img);
            } else {
              s3ToImage(locProps.scope, locProps.imgdigest).then((i) => {
                if (i?.b64) {
                  storeImage(databaseState.dexUser, i);
                  setNewImg(i.b64);
                }
              });
            }
          })
          .catch((err) => {
            console.log("CATCH", err);
          });
      if (!locProps.picArr || !locProps.descriptor?.imgdigest) {
        setNewImg(locProps.thumb);
      }
    }
  }, []);

  useEffect(() => {
    if (locProps.picAttach) {
      if (locProps.picArr) {
        setIndex(
          locProps.picArr.findIndex(
            (x) => x.thumbdigest === locProps.descriptor.thumbdigest
          )
        );
      }

      // locProps.image &&
      //   locProps.image.digest &&
      locProps.descriptor?.imgdigest &&
        getImage64(databaseState.dexUser, locProps.descriptor.imgdigest)
          // getImage64(databaseState.dbMaster, locProps.image.digest)
          .then((imgLarge64) => {
            if (imgLarge64) {
              let str1 = "data:image/png;base64," + imgLarge64;
              setNewImg(str1);
            } else {
              // s3ToImage(locProps.image.scope, locProps.image.digest).then(
              s3ToImage(
                locProps.descriptor?.imgpath.split("/")[1],
                locProps.descriptor?.imgdigest
              ).then((imgLarge) => {
                if (imgLarge?.b64) {
                  storeImage(databaseState.dexUser, imgLarge);
                  let str2 = "data:image/png;base64," + imgLarge.b64;
                  setNewImg(str2);
                }
              });
            }
          })
          .catch((err) => {
            console.log("CATCH", err);
          });
    }
  }, []);

  useEffect(handleImageSize, [newImg, dimensions]);

  const displayPreviousPic = () => {
    if (index > 0) {
      locProps.picArr[index - 1]?.imgdigest &&
        // locProps.image.digest &&
        getImage64(
          databaseState.dexUser,
          locProps.picArr[index - 1]?.thumbdigest
        )
          .then((img) => {
            if (img) {
              setNewImg(img);
            } else {
              s3ToImage(
                locProps.scope,
                locProps.picArr[index - 1]?.imgdigest
              ).then((i) => {
                if (i?.b64) {
                  storeImage(databaseState.dexUser, i);
                  setNewImg(i.b64);
                }
              });
            }
          })
          .catch((err) => {
            console.log("CATCH", err);
          });
      setIndex((state) => state - 1);
    }
  };

  const displayNextPic = () => {
    if (index < locProps.picArr.length - 1) {
      locProps.picArr[index + 1]?.imgdigest &&
        // locProps.image.digest &&
        getImage64(
          databaseState.dexUser,
          locProps.picArr[index + 1]?.thumbdigest
        )
          .then((img) => {
            if (img) {
              setNewImg(img);
            } else {
              s3ToImage(
                locProps.scope,
                locProps.picArr[index + 1]?.imgdigest
              ).then((i) => {
                if (i?.b64) {
                  storeImage(databaseState.dexUser, i);
                  setNewImg(i.b64);
                }
              });
            }
          })
          .catch((err) => {
            console.log("CATCH", err);
          });
      setIndex((state) => state + 1);
    }
  };
  const handleUserKeyPress = useCallback((event) => {
    if (event.ctrlKey && event.key === "=") {
      if (zoomFctr < 2) setZoomFctr(prev => prev + 0.2)
      else setZoomFctr(2)
    }
    if (event.ctrlKey && event.key === "-") {
      if (zoomFctr > 0.4) setZoomFctr(prev => prev - 0.2)
      else setZoomFctr(0.4)
    }
    // if (event.ctrlKey === true && (event.which === '61' || event.which === '107'
    //   || event.which === '173' || event.which === '109'
    //   || event.which === '187' || event.which === '189')) {
    //   event.preventDefault();
    // }
    // 107 Num Key  +
    // 109 Num Key  -
    // 173 Min Key  hyphen/underscore key
    // 61 Plus key  +/= key

    if (event.ctrlKey === true) {
      event.preventDefault();
    }

  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  let modal = (
    <div id="profile_modal" className="modals modal-image-new">
      {/* <ClickOutsideDetector
        caller="UIProfileModal"
        listen
        onClickOutside={() => {
          document.getElementById("profile_modal").style.display = "none";
          locProps.cb();
        }}
        onClick={() => { }}
      > */}
      <div className="modals modal-content-image-new" ref={modalcontent}>
        <button
          type="button"
          className="image-close"
          style={
            locProps.picArr && isLoaded && found && index > 0
              ? {
                top: "50%",
                transform: "translate(0,-50%)"
              }
              : { display: "none" }
          }
          onClick={() => {
            displayPreviousPic();
          }}
        >
          <IoIosArrowBack size="16" />
        </button>
        <button
          type="button"
          className="image-close"
          style={
            locProps.picArr &&
              isLoaded &&
              found &&
              index < locProps.picArr.length - 1
              ? {
                top: "50%",
                transform: "translate(0,-50%)",
                right: "0"
              }
              : { display: "none" }
          }
          onClick={() => {
            displayNextPic();
          }}
        >
          <IoIosArrowForward size="16" />
        </button>
        <div>
          <img
            ref={imgRef}
            alt={""}
            src={locProps.thumb}
            style={
              isLoaded
                ? { display: "none" }
                : {
                  height: window.innerHeight * 0.25,
                  width: window.innerWidth * 0.25
                }
            } //picstyle??
          />
          <img
            onLoad={() => {
              setIsLoaded(true);
              setFound(true);
            }}
            style={isLoaded ? picStyle : { display: "none" }}
            alt={""}
            src={newImg}
          />
        </div>
        <div>
          <div
            style={isLoaded ? { display: "none" } : { color: "wheat" }}
            className="loader"
          >
            Loading
            <span className="loader__dot" style={{ fontSize: "2rem" }}>
              .
            </span>
            <span className="loader__dot" style={{ fontSize: "2.5rem" }}>
              .
            </span>
            <span className="loader__dot" style={{ fontSize: "3rem" }}>
              .
            </span>
          </div>
          {!found && isLoaded && (
            <button
              onClick={
                () => history.goBack()
                // locProps.cb()
              }
              style={isLoaded ? { height: "30px" } : { display: "none" }}
            >
              <label>
                Error: picture not discovered
                {/* - press any key to exit */}
              </label>
            </button>
          )}
        </div>
      </div>
      <button
        type="button"
        className="image-close"
        style={
          isLoaded
            ? {
              right: "0",
              marginTop: "0.1rem",
              marginRight: "0.1rem",
              marginLeft: "auto",
              display: "flex",
              justifyContent: "center",
              zIndex: "999"
            }
            : { display: "none" }
        }
        data-dismiss="modal"
        aria-label="Close"
        onClick={() => {
          history.goBack();
          // document.getElementById("profile_modal").style.display = "none";
          // locProps.cb();
        }}
        title="Close Modal"
      >
        <AiOutlineClose size="16" />
      </button>
      {/* </ClickOutsideDetector> */}
    </div>
  );

  return modal;
};

export default React.memo(UIProfileModal, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
