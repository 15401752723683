import React, { useState, useContext, useEffect } from "react";
import { FaChevronRight } from "react-icons/fa";
import "ui/UIPersonaCard.css";
import IconDelete from "ui/Dropdown/iconDelete";
import GlobalContext from "contexts/context";
import plusLogo from "./icons/plusonly.png";
import PersonasContext from "contexts/contextPersonas";
import GetInitials from "../utils/GetInitials";
import logo from "../../src/person_outline2.png";
import { useHistory } from "react-router-dom";

const UIProfileCard = (props) => {
  const { personasState } = useContext(PersonasContext);
  const { globalState, globalDispatch } = useContext(GlobalContext);
  let history = useHistory();

  const handleThisOnClick = () => {
    if (!globalState.showWorldIDScreen) {
      history.push({
        pathname: "/UIWorldIDInfo"
      });
    }
    globalDispatch({
      type: "SET_WORLD_ID_SCREEN",
      values: { showWorldIDScreen: true }
    });
  };
  return (
    <div
      className="profilecard"
      style={{
        // margin: "1rem 0 0 0",
        width: "2rem",
        height: "2rem"
      }}
      onClick={() => {
        handleThisOnClick();
      }}
    >
      <img
        id={"image_" + props.mpersona}
        className="persona-card-header-unselected"
        src={logo}
        // to be changed when image is available src={props?.header?.image === "profile" ? logo : props?.header?.image}
        alt="Logo"
        height={40}
        style={{
          zIndex: "5",
          width: props?.id === "profile" ? "1.75rem" : {},
          height: props?.id === "profile" ? "1.75rem" : {}
        }}
      />
    </div>
  );
};

export default UIProfileCard;
