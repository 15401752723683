import React from 'react'
import "./Skeleton.css"

const SkeletonElement = ({ type, ...props }) => {
  const classes = `skeleton ${type}`
  const seStyles = {
    msgImg: {
      maxWidth: props.width && props.width,
      width: props.width && props.width,
      height: props.height && props.height,
      maxHeight: props.height && props.height,
      background: props.background
    }
  }
  return (
    <div
      className={classes}
      style={
        props.image === "true" &&
        seStyles.msgImg}>
    </div>
  )
}

export default SkeletonElement