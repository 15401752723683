import React, { useContext, useState, useEffect, useRef, useMemo } from "react";
import GlobalContext from "contexts/context";
import UserContext from "contexts/contextUser";
import DatabaseContext from "data/contextDatabase";
import { commitDescriptor } from "data/descriptors";
import UICarousel from "ui/UICarousel";
import { isMobileTablet } from "hooks/helper";
import UITopicTest from "./UITopicTest";
// import DropdownTopicMenu from "ui/Dropdown/dropdownTopicMenu";
import {
  // digestCalc,
  getDescriptor,
  // storeDescriptor,
  // assignDescriptor,
} from "data/descriptors";
import UIPersonaCard from "./UIPersonaCard";
import UIPersonaPicManager from "./UIPersonaPicManager";
import logo from "../../src/person_outline2.png";
// import ClickOutsideDetector from "utils/ClickOutsideDetector";
import UIPersonaFlag from "ui/UIPersonaFlag";
import {
  // ShowSingleLineSwitch,
  // ShowTextArea,
  // ShowOptionSwitch,
  PersonaButton,
  // PersonaButtonChkBox,
  // ShowOptionSwitchiBtn,
  // OneLineInfo,
  // DropdownMenu,
  TextInputSingleLine
  // TextInputArea,
  // ToggleSwitch,
  // TextEmail,
  // TextPhone,
  // SingleLineSwitch,
} from "./Forms/TextInput";
import { WorldTextInput } from "utils/UtilsUniversal";
import { FiArrowLeftCircle } from "react-icons/fi";
import "./UI.css";
import AttributeModal from "./Forms/AttributeModal";
// import { NameForm } from "./Forms/IDInfo";
import { personaDelete, personaRename } from "utils/persona";
import PersonasContext from "contexts/contextPersonas";
// import UIPersonaManager from "ui/UIPersonaManager";
import { useHistory } from "react-router-dom";
import ThumbsContext from "contexts/contextThumbs";
import UICreateNewPersona from "ui/UICreateNewPersona";
import CountContext from "contexts/contextCount";
import TopicsContext from "contexts/contextTopics";
import "ui/UISS.css";
import Modals from "./Modals";

const isEqual = require("react-fast-compare");

const UIPersonaInfo = (props) => {
  const { globalState, globalDispatch } = useContext(GlobalContext);
  const { userState } = useContext(UserContext);
  const { databaseState, databaseDispatch } = useContext(DatabaseContext);
  const { thumbsState } = useContext(ThumbsContext);
  // const [modal, setModal] = useState(undefined);
  const { personasState } = useContext(PersonasContext);
  const [createPersona, setCreatePersona] = useState(false);
  // const [open, setOpen] = useState(false);
  const [formsName, setFormsName] = useState(false);
  const [openAttr, setOpenAttr] = useState(false);
  // const [closeThis, setCloseThis] = useState(true);
  const [itemList, setItemList] = useState([]);
  const commitDelay = 2000;
  const { countState } = useContext(CountContext);
  const { topicsState } = useContext(TopicsContext);
  const [intFrameHeight, setIntFrameHeight] = useState(0);
  const [UIFrameHeight, setUIFrameHeight] = useState(0);
  const [items, setItems] = useState([]);
  const [changedPic, setChangedPic] = useState(props.location.state.changedPic);
  const [showAlert, setShowAlert] = useState(false);
  let history = useHistory();
  useEffect(() => {
    function handleResize() {
      setIntFrameHeight(window.innerHeight);
      setUIFrameHeight(window.innerHeight);
      // setChangeHgt(true)
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleNamesForm = () => {
    setOpenAttr(false);
    // setOpen(false)
    setFormsName(!formsName);
  };

  const dispnone = {
    display: "none"
  };
  const dispblk = {
    display: "block"
  };

  const menuItems = [
    {
      text: "Name",
      selected: false,
      callback: handleNamesForm,
      // callback: () => setFormsName(!formsName),
      // handleNamesForm(),
      key: 1
    },
    {
      text: "Nationality",
      selected: false,
      key: 2
    },
    {
      text: "Religion",
      selected: false,
      key: 3
    },
    {
      text: "Distance",
      selected: false,
      key: 4
    },
    {
      text: "Home phone number",
      selected: false,
      key: 5
    },
    {
      text: "Create Attribute",
      selected: false,
      key: 6
    }
  ];

  const showAttributeModal = () => {
    setOpenAttr(!openAttr);
  };

  const closeCreateModal = () => {
    // setCloseThis(false)
    // setModal(<div></div>);
    setCreatePersona(false);
  };

  const unreadCollections = useMemo(
    () => (mpersona) => {
      let cnt = 0;
      Object.keys(countState.count).forEach((d) => {
        if (d.includes(mpersona) && countState.count[d].unread > 0) {
          // if it is a topic, check that it is 'alive'
          if (d.startsWith("t_")) {
            Object.keys(topicsState?.topics).forEach((t) => {
              if (d.includes(topicsState.topics[t].mtopic)) {
                if (topicsState.topics[t].mpersona == mpersona) {
                  cnt += 1;
                }
              }
            });
          } else {
            cnt += 1;
          }
        }
      });
      return cnt;
    },
    [countState.count, topicsState.topics]
  );

  const [rows, setRows] = useState(4);

  /*Persona Name*/
  const [personaName, setPersonaName] = useState({
    value: globalState.persona?.persona,
    source: "initial"
  });

  const handleChangePersonaName = (newValue) => {
    setPersonaName({ value: newValue, source: "input" });
  };

  useEffect(() => {
    setPersonaName({
      value: globalState?.persona?.persona,
      source: "initial"
    });
  }, [globalState?.persona?.persona]);

  const PersonaNameTooltip =
    "no leading or trailing spaces\n" +
    "maximum 56 characters\n" +
    "A..Z a..z 0-9 .\n";

  useEffect(() => {
    let isMounted = true;
    let uid = globalState?.uid;
    let password = globalState.password;
    let accessToken = userState.accessToken;
    let mpersona = globalState.persona?.mpersona;
    let persona = globalState.persona?.persona;
    let newPersona = personaName.value?.trim();
    // Timeout is for debounce. Change is submitted to backend 2 seconds after typing
    const handler = setTimeout(() => {
      if (
        isMounted &&
        newPersona &&
        persona !== newPersona &&
        personaName.source === "input"
      ) {
        personaRename(
          uid,
          password,
          globalState.version,
          accessToken,
          mpersona,
          newPersona,
          databaseState.dexUser,
          databaseDispatch,
          globalDispatch
        ).then((r) => {});
      }
    }, commitDelay);
    return () => {
      isMounted = false;
      clearTimeout(handler);
    };
  }, [
    databaseDispatch,
    databaseState.dexUser,
    globalState.version,
    globalDispatch,
    personaName,
    userState.accessToken,
    globalState?.uid,
    globalState.password,
    globalState.persona?.mpersona,
    globalState.persona?.persona
  ]);

  /*Persona Purpose*/
  const [descriptor, setDescriptor] = useState();
  const [personaPurpose, setPersonaPurpose] = useState({
    value: "",
    source: "initial"
  });

  // const debouncedPersonaPurpose = useDebounce(personaPurpose, 2000);
  const onChangePersonaPurposeVal = (v) => {
    if (v === "") {
      setPersonaPurpose({ value: "", source: "input" });
    } else setPersonaPurpose({ value: v, source: "input" });
  };

  useEffect(() => {
    let mounted = true;
    let mpersona = globalState?.persona?.mpersona;
    if (personasState.descriptors) {
      let desc = personasState.descriptors.filter(
        (d) => d?.mpersona === mpersona
      )[0];
      // desc?.purpose &&
      //   setPersonaPurpose({ value: desc?.purpose, source: "database" });
      // desc && setDescriptor(desc);
      // console.log("DESC_1", desc, personasState.descriptors);
      desc?.digest
        ? getDescriptor(
            databaseState.dexUser,
            "descriptor",
            mpersona,
            desc.digest
          ).then((x) => {
            if (!x) {
            } else {
              mounted &&
                setPersonaPurpose({ value: x.purpose, source: "database" });
              mounted && setDescriptor(x);
            }
          })
        : mounted && setPersonaPurpose({ value: "", source: "" });
    }
    return () => {
      mounted = false;
    };
  }, [
    databaseState.dexUser,
    globalState?.persona?.mpersona,
    personasState.descriptors
  ]);

  useEffect(() => {
    let isMounted = true;
    let muid = globalState.muid;
    let mpersona = globalState?.persona?.mpersona;
    let purpose = personaPurpose?.value?.trim();
    let source = personaPurpose?.source;
    // the following only works for simple properties in descriptor ... no function or Date objects
    let oldDescriptor = descriptor && JSON.parse(JSON.stringify(descriptor));
    // Timeout is for debounce. Change is submitted to backend 2 seconds after typing
    const handler = setTimeout(() => {
      if (isMounted && source === "input") {
        //can we do this to allow for empty field??
        // if (isMounted && purpose && source === "input") {
        let newDescriptor = {
          ...oldDescriptor,
          purpose: purpose
        };
        // console.log("newDescriptor", newDescriptor);
        databaseState?.dexUser &&
          commitDescriptor(
            databaseState?.dexUser,
            muid,
            mpersona,
            newDescriptor
          );
      }
    }, commitDelay);
    return () => {
      isMounted = false;
      clearTimeout(handler);
    };
  }, [
    databaseState?.dexUser,
    descriptor,
    globalState.muid,
    globalState?.persona?.mpersona,
    personaPurpose?.source,
    personaPurpose?.value
  ]);

  /* Toggle switch for Persona Purpose */
  const [checkedPP, setCheckedPP] = useState(false);
  const handleChangePersonaPurpose = (newValue) => {
    setPersonaPurpose(newValue);
  };
  const onChangePersonaPurpose = () => {
    setCheckedPP(!checkedPP);
  };
  /*My Story*/
  // const [multString, setMultString] = useState(
  //   "Other things you want people to see about you"
  // );
  // const handleChangeMult = (newValue) => {
  //   setMultString(newValue);
  // };
  const [myStory, setMyStory] = useState("");
  const handleChangeMyStory = (newValue) => {
    setMyStory(newValue);
  };
  /*Persona Reachable*/
  const [personaReachable, setPersonaReachable] = useState("");
  const [checkedPersonaReachable, setCheckedPersonaReachable] = useState(false);
  const handleChangePersonaReachable = (newValue) => {
    setPersonaReachable(newValue);
  };
  const onChangePersonaReachable = () => {
    setCheckedPersonaReachable(!checkedPersonaReachable);
  };
  /*Relationship status*/
  const [relationship, setRelationship] = useState("");
  const [checkedRelationship, setCheckedRelationship] = useState(false);
  const handleChangeRelationship = (newValue) => {
    setRelationship(newValue);
  };
  const onChangeRelationship = () => {
    setCheckedRelationship(!checkedRelationship);
  };
  /*Muting button*/
  const [checkedMuteDeviceOnly, setCheckedMuteDeviceOnly] = useState(false);
  const onChangeMuteDeviceOnly = () => {
    setCheckedMuteDeviceOnly(!checkedMuteDeviceOnly);
  };

  /*Reachable Attribute*/
  const [reachableAttr, setReachableAttr] = useState("");
  const handleChangeReachableAttr = (newValue) => {
    setReachableAttr(newValue);
  };
  /*SA Phone Number*/
  const [SAPhn, setSAPhn] = useState("Cell phone");
  const [checkedSAPhn, setCheckedSAPhn] = useState(false);
  const handleChangeSAPhn = (newValue) => {
    setSAPhn(newValue);
  };
  const onChangeSAPhn = () => {
    setCheckedSAPhn(!checkedSAPhn);
  };
  /*Other Phone Number*/
  const [otherPhn, setOtherPhn] = useState("Cell phone");
  const [checkedOtherPhn, setCheckedOtherPhn] = useState(false);
  const handleChangeOtherPhn = (newValue) => {
    setOtherPhn(newValue);
  };
  const onChangeOtherPhn = () => {
    setCheckedOtherPhn(!checkedOtherPhn);
  };
  /*Address*/
  const [address, setAddress] = useState("Unit Address");
  const [checkedAddress, setCheckedAddress] = useState(false);
  const handleChangeAddress = (newValue) => {
    setAddress(newValue);
  };
  const onChangeAddress = () => {
    setCheckedAddress(!checkedAddress);
  };
  /*Vehicle Registration*/
  const [vehReg, setVehReg] = useState("Registration Number");
  const [checkedVehReg, setCheckedVehReg] = useState(false);
  const handleChangeVehReg = (newValue) => {
    setVehReg(newValue);
  };
  const onChangeVehReg = () => {
    setCheckedVehReg(!checkedVehReg);
  };
  /*Visible Attributes*/
  const [visAttr, setVisAttr] = useState("");
  const handleChangeVisAttr = (newValue) => {
    setVisAttr(newValue);
  };

  const availAttr = useRef(null);

  useEffect(() => {
    if (openAttr && availAttr.current) {
      availAttr.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [openAttr]);

  /*Gender*/
  const [gender, setGender] = useState("");
  const [checkedGender, setCheckedGender] = useState(false);
  const handleChangeGender = (newValue) => {
    setGender(newValue);
  };
  const onChangeGender = () => {
    setCheckedGender(!checkedGender);
  };
  /*Nationality*/
  const [nationality, setNationality] = useState("");
  const handleChangeNationality = (newValue) => {
    setNationality(newValue);
  };
  /*Religion*/
  const [religion, setReligion] = useState("");
  const handleChangeReligion = (newValue) => {
    setReligion(newValue);
  };
  /*Distance*/
  const [distance, setDistance] = useState("");
  const [checkedDistance, setCheckedDistance] = useState(false);
  const handleChangeDistance = (newValue) => {
    setDistance(newValue);
  };
  const onChangeDistance = () => {
    setCheckedDistance(!checkedDistance);
  };

  const generalWidth = "300px";
  const generalPadding = "4px";
  const generalHeight = "30px";
  const generalBorderWidth = "1px";
  const generalBorderColor = "var(--modal_border_color)";
  const generalBorderStyle = "solid";
  const generalBorderRadius = "var(--border_radius_medium)";
  const generalBackgroundColor = "var(--modal_background_color)";
  const generalMarginTop = "4px";
  const generalMarginBottom = "0px";
  const generalTextColor = "var(--modal_text_color)";
  const generalFontSize = "1rem";
  const generalFontSizeLabel = "0.8rem";
  const generalMarginLeft = "12px";
  const generalMarginRight = "16px";
  const generalHeightSwitch = "20px";
  const generalBackgroundColorSwitch = "var(--toggle_switch_background_color)";
  const generalColorSelectSwitch = "#64DD17";
  const generalborderWidthSwitch = "1px";
  const generalborderColorSwitch = "var(--toggle_switch_border_color)";
  const generalborderStyleSwitch = "solid";
  const generalmarginRightSwitch = "16px";
  const generalButtonMarginLeft = "16px";
  const generalButtonMarginBottom = "8px";
  const generalButtonMarginRight = "8px";
  const generalButtonButtonMarginRight = "16px";

  const genStyle = {
    marginTop: generalMarginTop,
    marginLeft: generalMarginLeft,
    marginRight: generalMarginRight,
    marginBottom: generalMarginBottom,
    width: "100%",
    height: "40px",
    // fontSizeLabel: "1.2rem",
    fontSize: generalFontSize
    // paddingLeft: "8px",
    // paddingRight: "4px",
    // paddingTop: "4px",
    // paddingBottom: "4px",
    // borderRadius: "8px",
    // borderWidth: generalBorderWidth,
    // borderStyle: generalBorderStyle,
  };

  const returnToTopics = () => {
    globalDispatch({
      type: "SET_PERSONA_SCREEN",
      values: { showPersonaScreen: false }
    });
    globalDispatch({
      type: "SET_WORLD_ID_SCREEN",
      values: { showWorldIDScreen: false }
    });
    if (history.length >= 1) history.goBack();
  };

  useEffect(() => {
    // if (closeThis) {
    history.listen((location) => {
      if (globalState.showPersonaScreen)
        globalDispatch({
          type: "SET_PERSONA_SCREEN",
          values: { showPersonaScreen: false }
        });
    });
    // }
    return () => {};
  }, [history.location.action]);

  const getIndex = () => {
    for (var i = 0; i < personasState.personas?.length; i++) {
      if (
        personasState?.personas[i]?.mpersona === globalState?.persona?.mpersona
      ) {
        return i;
      }
    }
    return -1; //to handle the case where the value doesn't exist
  };

  useEffect(() => {
    const lists = document.querySelectorAll(".scroll-box__container");
    setItems(
      itemList.map((item) => {
        return (
          <UIPersonaCard
            header={{
              // preTitle: "Something small",
              // title: "Something big",
              image: thumbsState?.thumbs[item.mpersona] || "logo"
            }}
            // preTitle={"hellow rold"}
            size={"small"}
            // title={"JONES"}
            body={{
              title: item.persona,
              text: ""
            }}
            key={item.mpersona}
            id={"card_" + item.mpersona}
            uid={globalState.uid}
            password={globalState.password}
            item={item}
            // onDeletePersona={onDeletePersona}
            selected={globalState.persona?.mpersona === item.mpersona}
          ></UIPersonaCard>
        );
      })
    );

    lists.forEach((el) => {
      const n = itemList.length;
      el.style.setProperty("--total", n);
    });
    return () => {};
  }, [
    globalState.password,
    globalState.persona?.mpersona,
    globalState.uid,
    thumbsState.thumbs,
    itemList,
    changedPic
  ]);

  useEffect(() => {
    const btn_add_persona = {
      key: "btn_add_persona",
      id: "btn_add_persona",
      // header: <FiPlusCircle />,
      persona: "Add Persona",
      mpersona: "",
      onClick: () => setCreatePersona(true)
      // setModal(modalCreatePersona),
    };
    let iList = personasState.personas
      ? personasState.personas.map((persona) => ({
          id: persona.persona,
          persona: persona.persona,
          mpersona: persona.mpersona,
          topicCnt: unreadCollections(persona.mpersona),
          onClick: () => {
            globalDispatch({
              type: "SET_PERSONA",
              values: { persona: persona }
            });
            setChangedPic(true);
          },
          onSecondClick: () => {
            if (!globalState.showPersonaScreen) {
              history.push({
                pathname: "/UIPersonaInfo",
                state: { changedPic: true }
              });
              globalDispatch({
                type: "SET_PERSONA_SCREEN",
                values: { showPersonaScreen: true }
              });
            }
          }
        }))
      : [];
    if (personasState.personas?.length < 4)
      globalState.uid && (iList = iList.concat(btn_add_persona));
    setItemList([...iList]);

    return () => {};
  }, [
    globalDispatch,
    globalState.uid,
    personasState.personas,
    globalState.showPersonaScreen,
    globalState.showWorldIDScreen,
    // thumbsState.thumbs,
    unreadCollections
  ]);

  const handleDeletePersonaWarning = () => {
    setShowAlert(true);
  };
  const handleDeletePersona = () => {
    let uid = globalState.uid;
    let password = globalState.password;
    let accessToken = userState.accessToken;
    let mpersona = globalState.persona.mpersona;
    personaDelete(
      uid,
      password,
      globalState.version,
      accessToken,
      globalState.persona.mpersona,
      databaseState.dexUser,
      databaseDispatch,
      globalDispatch
    ).then((r) => {});
    returnToTopics();
  };

  // const mpersona = livePersonas[getIndex()].mpersona
  let content = (
    <div
      // className="UI"
      className={isMobileTablet() ? "UI" : "UISs"}
      style={
        intFrameHeight
          ? {
              height: intFrameHeight
            }
          : { height: window.innerHeight }
      }
    >
      <div
        className={isMobileTablet() ? "Ui-frame" : "col-1"}
        style={
          globalState.isLoggedIn
            ? UIFrameHeight
              ? { height: UIFrameHeight }
              : { height: window.innerHeight }
            : { display: "none" }
        }
      >
        {/* <UIPersonaManager setModal={setModal} />  */}

        <div className="UI-persona-frame">
          {/* {true */}
          {/* // (globalState.showPersonaScreen || globalState.showWorldIDScreen) */}
          {/* && ( */}
          <div>
            <FiArrowLeftCircle
              style={{
                fontSize: "3rem",
                color: "var(--menu_button_icon_color)"
              }}
              onClick={() => returnToTopics()}
            />
          </div>
          {/* )} */}
          <UICarousel items={items} />
        </div>
        {/* <span
          className="label-cards"
          style={{
            // marginLeft: 0,
            // fontSize: generalFontSizeLabel,
            fontWeight: "bold",
          }}
        >
          Persona Image
        </span> */}

        <UIPersonaPicManager
          changedPic={changedPic}
          setChangedPic={(val) => setChangedPic(val)}
        ></UIPersonaPicManager>
        <div className="persona-scroll full no-scrollbar persona-screen-layout">
          {/* <div
            className="label-cards"
            style={{
              marginLeft: generalMarginLeft,
              marginBottom: "0",
              fontSize: generalFontSizeLabel,
            }}
          >
            Unique Persona Number
          </div> */}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "0.5rem"
              // marginLeft: generalMarginLeft,
            }}
          >
            <span
              style={{
                marginRight: "0.5rem",
                marginBottom: "0.5rem"
              }}
            >
              <UIPersonaFlag
                mpersona={personasState.personas[getIndex()].mpersona}
                showFlagOnly={true}
              />
            </span>
            {personasState.personas[getIndex()].mpersona}
          </div>
          {/* <WorldTextInput
            identity="personaname"
            // ref={inviteBoxRef}
            focus={!isMobileTablet() ? true : false}
            labelStyle={{
              fontWeight: "bold"
            }}
            inpStyle={{
              height: "2rem",
              borderRadius: "var(--border_radius_medium)"
            }}
            type="text"
            title={PersonaNameTooltip}
            callback={(e) => handleChangePersonaName(e)}
            label={"Persona name"}
            // required={true}
            placeholder={"Persona name"}
            // description={"Personaname"}
            minRows={1}
            maxRows={1}
            // minStrLen={3}
            maxStrLen={56}
            // active={worldTestTA?.length > 0 ? "true" : "false"}
            value={personaName.value}
            // regex={/^[\w\ ]+$/i}
          /> */}
          <WorldTextInput
            identity="personaname"
            universalStyle={{ display: "flex", flexDirection: "column" }}
            labelStyle={{
              fontSize: "0.8rem"
            }}
            required={false}
            inpStyle={{
              maxWidth: "100%",
              width: isMobileTablet() ? "70vw" : "100%",
              padding: "0.5rem",
              boxSizing: "border-box"
            }}
            type="text"
            callback={(e) => handleChangePersonaName(e)}
            label={"Persona name"}
            placeholder="Persona name"
            minRows={1}
            maxRows={1}
            maxStrLen={56}
            value={personaName?.value || ""}
          />
          {/* <WorldTextInput
            identity="personapurpose"
            labelStyle={{
              fontWeight: "bold"
            }}
            type="text"
            // title="Description will appear under header"
            callback={(e) => onChangePersonaPurposeVal(e)}
            label={"Persona purpose"}
            required={false}
            placeholder={"What is this persona used for?"}
            border="0.06rem solid var(--modal_border_color)"
            border_radius="var(--border_radius_medium)"
            background_color="var(--modal_background_color)"
            text_color="var(--modal_text_color)"
            hint_color="var(--send_bar_hint_color)"
            minHeight="2.5rem"
            minWidth="80%"
            marginBottom="0.5rem"
            // description={"generic World text input field testing phase"}
            variant="outlined"
            minRows={1}
            maxRows={1}
            // minStrLen={3}
            maxStrLen={56}
            active={"true"}
            value={personaPurpose?.value}
          /> */}

          {/* <TextInputSingleLine
            label="Persona Purpose"
            fontSizeLabel={generalFontSizeLabel}
            fontSize={generalFontSize}
            mandatory={false}
            type="input"
            value={personaPurpose?.value || ""}
            width={generalWidth}
            height={generalHeight}
            paddingLeft={generalPadding}
            paddingRight={generalPadding}
            marginLeft={generalMarginLeft}
            marginRight={generalMarginRight}
            marginTop={generalMarginTop}
            marginBottom={generalMarginBottom}
            borderWidth={generalBorderWidth}
            borderColor={generalBorderColor}
            borderStyle={generalBorderStyle}
            borderRadius={generalBorderRadius}
            textColor={generalTextColor}
            placeholder="What is this Persona used for?"
            callback={(v) => {
              onChangePersonaPurposeVal(v);
            }}
            key="1c"
            heightSwitch={generalHeightSwitch}
            backgroundColorSwitch={generalBackgroundColorSwitch}
            colorSelectSwitch={generalColorSelectSwitch}
            borderWidthSwitch={generalborderWidthSwitch}
            borderColorSwitch={generalborderColorSwitch}
            borderStyleSwitch={generalborderStyleSwitch}
            marginRightSwitch={generalmarginRightSwitch}
            id="reachable_switch"
            checkedPP={checkedPP}
            onChange={onChangePersonaPurpose}
            iBtn="false"
            addSwitch="false"
          /> */}

          <PersonaButton
            marginLeft="0"
            marginBottom={generalButtonMarginBottom}
            marginTop={generalMarginTop}
            onClick={() => {
              handleDeletePersonaWarning();
              // let uid = globalState.uid;
              // let password = globalState.password;
              // let accessToken = userState.accessToken;
              // let mpersona = globalState.persona.mpersona;
              // personaDelete(
              //   uid,
              //   password,
              //   globalState.version,
              //   accessToken,
              //   globalState.persona.mpersona,
              //   databaseState.dexUser,
              //   databaseDispatch,
              //   globalDispatch
              // ).then((r) => {});
              // returnToTopics();
            }}
            label="Delete Persona"
            key="3"
          />
          {showAlert && (
            <Modals
              title="WARNING"
              onClose={() => setShowAlert(false)}
              onClickOutside={() => setShowAlert(false)}
              clickOutsideActive={true}
              //  switchScrollOff={}
              footer={
                <div>
                  <button
                    className="UI-button-service"
                    style={{ marginRight: "0.5rem" }}
                    type="button"
                    onClick={() => setShowAlert(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="UI-button-service"
                    type="button"
                    onClick={() => handleDeletePersona()}
                  >
                    Delete
                  </button>
                </div>
              }
            >
              <div
                style={{
                  textAlign: "left",
                  whiteSpace: "pre-line"
                }}
              >
                <span>You are about to delete one of your persona.</span>
                <br />
                <br />
                <span>
                  All topics with other participants will remain, but you should
                  transfer ownership to another user for topics you have
                  created.
                </span>
                <br />
                <br />
                <span>
                  Deleting your persona cannot be undone. Confirm you want to
                  delete your persona by tapping DELETE.
                </span>
              </div>
            </Modals>
          )}
          {/* <ShowOptionSwitchiBtn
        width={generalWidth}
        height={generalHeight}
        fontSize={generalFontSize}
        paddingLeft={generalPadding}
        paddingRight={generalPadding}
        marginLeft={generalMarginLeft}
        marginRight={generalMarginRight}
        marginTop={generalMarginTop}
        marginBottom={generalMarginBottom}
        backgroundColor={generalBackgroundColor}
        textColor={generalTextColor}
        value="Persona is reachable"
        callback={handleChangePersonaReachable}
        placeholder="Type Name Here"

        heightSwitch={generalHeightSwitch}
        backgroundColorSwitch={generalBackgroundColorSwitch}
        colorSelectSwitch={generalColorSelectSwitch}
        borderWidthSwitch={generalborderWidthSwitch}
        borderColorSwitch={generalborderColorSwitch}
        borderStyleSwitch={generalborderStyleSwitch}
        marginRightSwitch={generalmarginRightSwitch}
        id="reachable_switch"
        checkedPP={checkedPersonaReachable}
        onChange={onChangePersonaReachable}
        iBtn="false"
        addSwitch="true"
      /> */}

          {/* 
      <PersonaButton
        marginLeft="16px"
        marginBottom="8px"
        marginRight="0"
        alignSelf="end"
        onClick={() => {

        }}
        label="Add to Carousel"
      /> */}

          {/* <TextInputArea
        label="My story"
        type="text"
        rows={rows}
        fontSizeLabel={generalFontSizeLabel}
        fontSize={generalFontSize}
        fontFamily="Segoe UI"
        width={generalWidth}
        paddingLeft={generalPadding}
        paddingRight={generalPadding}
        marginLeft={generalMarginLeft}
        marginRight="0px"
        marginTop={generalMarginTop}
        marginBottom={generalMarginBottom}
        borderWidth={generalBorderWidth}
        borderColor={generalBorderColor}
        borderStyle={generalBorderStyle}
        borderRadius={generalBorderRadius}
        backgroundColor={generalBackgroundColor}
        textColor={generalTextColor}
        value={myStory}
        callback={handleChangeMyStory}
        placeholder="Other things you want people to see about you"
      ></TextInputArea> */}

          {/* <TextInputSingleLine
        label="Relationship status"
        fontSizeLabel={generalFontSizeLabel}
        fontSize={generalFontSize}
        mandatory={false}
        type="input"
        width={generalWidth}
        height={generalHeight}
        paddingLeft={generalPadding}
        paddingRight={generalPadding}
        marginLeft={generalMarginLeft}
        marginRight={generalMarginRight}
        marginTop={generalMarginTop}
        marginBottom={generalMarginBottom}
        borderWidth={generalBorderWidth}
        borderColor={generalBorderColor}
        borderStyle={generalBorderStyle}
        borderRadius={generalBorderRadius}
        backgroundColor={generalBackgroundColor}
        textColor={generalTextColor}
        placeholder="Married, Available, etc"
        value={relationship}
        callback={handleChangeRelationship}
        heightSwitch={generalHeightSwitch}
        backgroundColorSwitch={generalBackgroundColorSwitch}
        colorSelectSwitch={generalColorSelectSwitch}
        borderWidthSwitch={generalborderWidthSwitch}
        borderColorSwitch={generalborderColorSwitch}
        borderStyleSwitch={generalborderStyleSwitch}
        marginRightSwitch={generalmarginRightSwitch}
        id="newswitch"
        checkedPP={checkedRelationship}
        onChange={onChangeRelationship}
        addSwitch="false"
        key="6"
      /> */}

          {/* <PersonaButtonChkBox
        const="8px"
        const="16px"
        marginLeft={generalButtonMarginLeft}
        marginBottom={generalButtonMarginBottom}
        marginRight={generalButtonMarginRight}
        marginTop={generalMarginTop}
        buttonMarginRight={generalButtonButtonMarginRight}
        onClick={() => console.log("muting..")}
        buttonLabel="Mute/Unmute"
        chkboxLabel="This device only"
        checkedMute={checkedMuteDeviceOnly}
        onChange={onChangeMuteDeviceOnly}
        key="7"
      /> */}

          {/* <PersonaButton
        marginLeft={generalButtonMarginLeft}
        marginBottom={generalButtonMarginBottom}
        marginTop={generalMarginTop}
        onClick={() => console.log("mark all as read")}
        label="Mark all as read"
        key="8"
      /> */}
          {/* <PersonaButton
        marginLeft={generalButtonMarginLeft}
        marginBottom={generalButtonMarginBottom}
        marginTop={generalMarginTop}
        onClick={() => console.log("delete persona")}
        label="Delete all messages"
        key="9"
      /> */}
          {/* <PersonaButton
        marginLeft={generalButtonMarginLeft}
        marginBottom={generalButtonMarginBottom}
        marginTop={generalMarginTop}
        onClick={() => console.log("text bubble colors")}
        label="Text & Bubble Colour"
        key="10"
      /> */}

          {/* <TextInputArea
    //             label="My story"
    //             type="text"
    //             rows={rows}
    //             width={generalWidth}
    //             paddingLeft={generalPadding}
    //             paddingRight={generalPadding}
    //             marginLeft={generalMarginLeft}
    //             marginRight="0px"
    //             // marginTop={generalMarginTop}
    //             marginBottom={generalMarginBottom}
    //             borderRadius={generalBorderRadius}
    //             backgroundColor={generalBackgroundColor}
    //             textColor={generalTextColor}
    //             value={multString}
    //             callback={handleChangeMult}
    //             placeholder="Other things you want people to see about you"
    //         >
    //         </TextInputArea> */}

          {/* <ShowOptionSwitchiBtn
        width={generalWidth}
        height={generalHeight}
        fontWeight="bold"
        paddingLeft={generalPadding}
        paddingRight={generalPadding}
        marginLeft="12px"
        marginRight={generalMarginRight}
        marginTop={generalMarginTop}
        marginBottom="0px"
        backgroundColor={generalBackgroundColor}
        textColor={generalTextColor}
        value="Reachable Attributes"
        iBtn="true"
        onClick={() => console.log("the info module has landed")}
        addSwitch="false"
        key="11"
      /> */}

          {/* <OneLineInfo
        width="100%"
        height={generalHeight}
        fontWeight="bold"
        paddingLeft={generalPadding}
        paddingRight={generalPadding}
        marginLeft={generalMarginLeft}
        marginRight={generalMarginRight}
        marginTop="0px"
        marginBottom="0px"
        backgroundColor={generalBackgroundColor}
        textColor="red"
        fontStyle="italic"
        value="Toggle the switch to let users reach you"
        key="12"
      /> */}

          {/* <ShowSingleLineSwitch
        label="SA Phone number"
        type="text"
        width={generalWidth}
        height={generalHeight}
        paddingLeft={generalPadding}
        paddingRight={generalPadding}
        marginLeft="12px"
        marginRight={generalMarginRight}
        marginTop={generalMarginTop}
        marginBottom={generalMarginBottom}
        borderWidth={generalBorderWidth}
        borderColor={generalBorderColor}
        borderStyle={generalBorderStyle}
        borderRadius={generalBorderRadius}
        backgroundColor={generalBackgroundColor}
        textColor={generalTextColor}
        value={SAPhn}
        callback={handleChangePersonaName}
        placeholder="Type Name Here"
        heightSwitch={generalHeightSwitch}
        backgroundColorSwitch={generalBackgroundColorSwitch}
        colorSelectSwitch={generalColorSelectSwitch}
        borderWidthSwitch={generalborderWidthSwitch}
        borderColorSwitch={generalborderColorSwitch}
        borderStyleSwitch={generalborderStyleSwitch}
        marginRightSwitch={generalmarginRightSwitch}
        id="newswitch"
        checkedPP={checkedSAPhn}
        onChange={onChangeSAPhn}
        addSwitch="true"
        key="13"
      /> */}

          {/* <ShowSingleLineSwitch
        label="Other Phone number"
        type="text"
        width={generalWidth}
        height={generalHeight}
        paddingLeft={generalPadding}
        paddingRight={generalPadding}
        marginLeft={generalMarginLeft}
        marginRight={generalMarginRight}
        marginTop={generalMarginTop}
        marginBottom={generalMarginBottom}
        borderWidth={generalBorderWidth}
        borderColor={generalBorderColor}
        borderStyle={generalBorderStyle}
        borderRadius={generalBorderRadius}
        backgroundColor={generalBackgroundColor}
        textColor={generalTextColor}
        value={otherPhn}
        callback={handleChangePersonaName}
        placeholder="Type Name Here"
        heightSwitch={generalHeightSwitch}
        backgroundColorSwitch={generalBackgroundColorSwitch}
        colorSelectSwitch={generalColorSelectSwitch}
        borderWidthSwitch={generalborderWidthSwitch}
        borderColorSwitch={generalborderColorSwitch}
        borderStyleSwitch={generalborderStyleSwitch}
        marginRightSwitch={generalmarginRightSwitch}
        id="newswitch"
        checkedPP={checkedOtherPhn}
        onChange={onChangeOtherPhn}
        addSwitch="true"
        key="14"
      /> */}

          {/* <ShowSingleLineSwitch
        label="Address"
        type="text"
        width={generalWidth}
        height={generalHeight}
        paddingLeft={generalPadding}
        paddingRight={generalPadding}
        marginLeft={generalMarginLeft}
        marginRight={generalMarginRight}
        marginTop={generalMarginTop}
        marginBottom={generalMarginBottom}
        borderWidth={generalBorderWidth}
        borderColor={generalBorderColor}
        borderStyle={generalBorderStyle}
        borderRadius={generalBorderRadius}
        backgroundColor={generalBackgroundColor}
        textColor={generalTextColor}
        value={address}
        callback={handleChangePersonaName}
        placeholder="Type Name Here"
        heightSwitch={generalHeightSwitch}
        backgroundColorSwitch={generalBackgroundColorSwitch}
        colorSelectSwitch={generalColorSelectSwitch}
        borderWidthSwitch={generalborderWidthSwitch}
        borderColorSwitch={generalborderColorSwitch}
        borderStyleSwitch={generalborderStyleSwitch}
        marginRightSwitch={generalmarginRightSwitch}
        id="newswitch"
        checkedPP={checkedAddress}
        onChange={onChangeAddress}
        addSwitch="true"
        key="15"
      /> */}

          {/* <ShowSingleLineSwitch
        label="Vehicle Registration Number"
        type="text"
        width={generalWidth}
        height={generalHeight}
        paddingLeft={generalPadding}
        paddingRight={generalPadding}
        marginLeft={generalMarginLeft}
        marginRight={generalMarginRight}
        marginTop={generalMarginTop}
        marginBottom={generalMarginBottom}
        borderWidth={generalBorderWidth}
        borderColor={generalBorderColor}
        borderStyle={generalBorderStyle}
        borderRadius={generalBorderRadius}
        backgroundColor={generalBackgroundColor}
        textColor={generalTextColor}
        value={vehReg}
        callback={handleChangePersonaName}
        placeholder="Type Name Here"
        heightSwitch={generalHeightSwitch}
        backgroundColorSwitch={generalBackgroundColorSwitch}
        colorSelectSwitch={generalColorSelectSwitch}
        borderWidthSwitch={generalborderWidthSwitch}
        borderColorSwitch={generalborderColorSwitch}
        borderStyleSwitch={generalborderStyleSwitch}
        marginRightSwitch={generalmarginRightSwitch}
        id="newswitch"
        checkedPP={checkedVehReg}
        onChange={onChangeVehReg}
        addSwitch="true"
        key="16"
      /> */}

          {/* <ShowOptionSwitchiBtn
        width={generalWidth}
        height={generalHeight}
        fontWeight="bold"
        paddingLeft={generalPadding}
        paddingRight={generalPadding}
        marginLeft={generalMarginLeft}
        marginRight={generalMarginRight}
        marginTop={generalMarginTop}
        marginBottom="0px"
        backgroundColor={generalBackgroundColor}
        textColor={generalTextColor}
        value="Visible Attributes"
        iBtn="true"
        onClick={() => console.log("the info module has landed")}
        addSwitch="false"
        key="17"
      /> */}

          {/* <OneLineInfo
        width="100%"
        height={generalHeight}
        fontWeight="bold"
        paddingLeft={generalPadding}
        paddingRight={generalPadding}
        marginLeft="12px"
        marginRight={generalMarginRight}
        marginTop="0px"
        marginBottom="0px"
        backgroundColor="var(--modal_background_color)"
        textColor="red"
        fontStyle="italic"
        value="Toggle the switch to let other users see your attributes"
        key="18"
      /> */}

          {/* <ShowOptionSwitchiBtn
        width={generalWidth}
        height={generalHeight}
        fontWeight="bold"
        paddingLeft={generalPadding}
        paddingRight={generalPadding}
        marginLeft={generalMarginLeft}
        marginRight={generalMarginRight}
        marginTop={generalMarginTop}
        marginBottom="0px"
        backgroundColor={generalBackgroundColor}
        textColor={generalTextColor}
        value="Available Attributes"
        iBtn="true"
        onClick={() => console.log("the info module has landed")}
        addSwitch="false"
        key="19"
      /> */}

          {/* <PersonaButton
        marginLeft="16px"
        marginBottom="16px"
        onClick={() => setOpenAttr(!openAttr)}
        label="Add Attributes"
      // key="20"
      /> */}

          {openAttr && (
            <AttributeModal onClose={showAttributeModal}>
              {/* <DropdownMenu
                        style={
                            open ? dispnone : dispblk
                        }
                        title="Available Attributes UIP"
                        menuItems={menuItems}
                        open={open}
                        onClick={() => setOpenAttr(!openAttr)}
                        onClose={props.onClose}
                        key="21"
                    > */}

              {/* {formsName && <NameForm
                            onClose={props.onClose}
                            menuItems={menuItems}
                            handleNamesForm={handleNamesForm} />} */}

              {/* </DropdownMenu> */}
            </AttributeModal>

            // <div
            //     ref={availAttr}>
            //     <DropdownMenu
            //         style={
            //             open ? dispnone : dispblk
            //         }
            //         title="Available Attributes"
            //         menuItems={menuItems}
            //         open={open}
            //         onClick={() => setOpen(!open)}
            //         key="21"
            //     >

            //     </DropdownMenu>
            // </div>
          )}

          {/* {formsName && <NameForm />} */}

          {/* <TextEmail
                label="email"
                type="email"
                width="fit-content"
                height="50px"
                paddingLeft="15px"
                paddingRight="15px"
                backgroundColor="#C1C1C1"
                textColor="dark-blue"
                placeholder="your email"
                value=""
            > */}

          {/* </TextEmail>  */}
        </div>
      </div>
      <div>
        <UITopicTest />
      </div>
      {createPersona && <UICreateNewPersona cb={closeCreateModal} />}
    </div>
  );

  return content;
};

export default React.memo(UIPersonaInfo, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
});
