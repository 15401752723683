import React, { useContext, useState, createRef, useEffect, useCallback } from "react";
// import ClickOutsideDetector from "utils/ClickOutsideDetector";
import "./UI.css";
import "./modals.css";
import DatabaseContext from "data/contextDatabase";
import { getImage64, storeImage } from "../data/descriptors"
import { s3ToImage } from "../connectivity/s3"
import { useHistory } from "react-router-dom";
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { AiOutlineClose } from 'react-icons/ai'
// import usePanAndZoom from './usePanAndZoom';
// import './PanAndZoomImage.css';
// import { zoom } from "./actions";

const UIProfileModalNew = (props) => {
  const [dimensions, setDimensions] = useState({});
  const imgRef = createRef();
  const [picStyle, setPicStyle] = useState({});
  const [isLoaded, setIsLoaded] = React.useState(false);
  const { databaseState } = useContext(DatabaseContext);
  const [newImg, setNewImg] = useState(undefined);
  const [found, setFound] = useState(false);
  let history = useHistory()
  const [index, setIndex] = useState()
  const displayRef = createRef();
  const [displayRefWidth, setDisplayRefHeight] = useState()
  const [displayRefHeight, setDisplayRefWidth] = useState()
  const [zoomIn, setZoomIn] = useState(false)
  const [zoomCountIn, setZoomCountIn] = useState(0)
  const [zoomCountOut, setZoomCountOut] = useState(0)
  const [zoomFctr, setZoomFctr] = useState(1)

  useEffect(() => {
    let timer1 = setTimeout(() => {
      setIsLoaded(true);
    }, 10000);
    if (found) clearTimeout(timer1);
    return () => clearTimeout(timer1);
  }, [imgRef, found]);

  const heightStyle = (h) => {
    return {
      height: h * 1 + "px",
      width: "auto",
      resizeMode: "cover",
      objectFit: "contain",
      borderRadius: "var(--border_radius_large)",
      transform: `scale(${zoomFctr})`
    };
  };

  const widthStyle = (w) => {
    return {
      width: w * 1 + "px",
      height: "auto",
      resizeMode: "cover",
      objectFit: "contain",
      borderRadius: "var(--border_radius_large)",
      transform: `scale(${zoomFctr})`
    };
  };

  useEffect(() => {
    let i = new Image();
    i.onload = function () {
      setDimensions({
        wdth: i.width,
        hgt: i.height
      });
    };
    i.src = newImg;
    return () => { };
  }, [newImg]);

  // useEffect(() => {
  //   // if (zoomIn) {
  //   setDimensions({
  //     ...dimensions,
  //     wdth: dimensions.wdth - 5,
  //     hgt: dimensions.hgt - 5
  //   })
  //   // }
  // }, [zoomIn])

  useEffect(() => {
    handleImageSize()
  }, [zoomFctr])

  const handleImageSize = () => {
    let w = 0;
    let h = 0;
    let imgwid = dimensions.wdth;
    let imghgt = dimensions.hgt;
    const ratio = imgwid / imghgt;
    const sameType = () => {
      w = window.innerWidth;
      h = window.innerWidth / ratio;
      if (h > window.innerHeight) {
        h = window.innerHeight;
        w = h * ratio;
      }
    };

    if (imgwid > imghgt) {
      if (window.innerWidth > window.innerHeight) {
        sameType();
      } else {
        w = window.innerWidth;
        h = w / ratio;
      }
    } else {
      if (window.innerWidth > window.innerHeight) {
        h = window.innerHeight;
        w = h * ratio;
      } else {
        sameType();
      }
    }
    if (w > h) setPicStyle(heightStyle(h));
    else setPicStyle(widthStyle(w));
  };

  const handleUserKeyPress = useCallback((event) => {
    if (event.ctrlKey && event.key === "=") {
      if (zoomFctr < 2) setZoomFctr(prev => prev + 0.2)
      else setZoomFctr(2)
    }
    if (event.ctrlKey && event.key === "-") {
      if (zoomFctr > 0.4) setZoomFctr(prev => prev - 0.2)
      else setZoomFctr(0.4)
    }
    // if (event.ctrlKey === true && (event.which === '61' || event.which === '107'
    //   || event.which === '173' || event.which === '109'
    //   || event.which === '187' || event.which === '189')) {
    //   event.preventDefault();
    // }
    // 107 Num Key  +
    // 109 Num Key  -
    // 173 Min Key  hyphen/underscore key
    // 61 Plus key  +/= key

    if (event.ctrlKey === true) {
      event.preventDefault();
    }

  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);


  useEffect(() => {
    window.addEventListener("resize", handleImageSize);
    return () => {
      window.removeEventListener("resize", handleImageSize);
    };
  }, [dimensions, newImg, zoomCountIn]);

  useEffect(() => {
    if (props.avatar) {
      if (props.picArr)
        setIndex(
          props.picArr.findIndex(
            (x) => x.thumbdigest === props.descriptor.thumbdigest
          )
        );

      props.descriptor?.imgdigest &&
        // props.imgdigest &&
        getImage64(databaseState.dexUser, props.descriptor.imgdigest)
          .then((img) => {
            if (img) {
              setNewImg(img);
            } else {
              s3ToImage(props.scope, props.imgdigest).then((i) => {
                if (i?.b64) {
                  storeImage(databaseState.dexUser, i);
                  setNewImg(i.b64);
                }
              });
            }
          })
          .catch((err) => {
            console.log("CATCH", err);
          });
    }
  }, []);

  useEffect(() => {
    if (props.picAttach) {
      if (props.picArr)
        setIndex(
          props.picArr.findIndex(
            (x) => x.thumbdigest === props.descriptor.thumbdigest
          )
        );
      props.image &&
        props.image.digest &&
        getImage64(databaseState.dexUser, props.image.digest)
          .then((imgLarge64) => {
            if (imgLarge64) {
              let str1 = "data:image/png;base64," + imgLarge64;
              setNewImg(str1);
            } else {
              s3ToImage(props.image.scope, props.image.digest).then(
                (imgLarge) => {
                  if (imgLarge?.b64) {
                    storeImage(databaseState.dexUser, imgLarge);
                    let str2 = "data:image/png;base64," + imgLarge.b64;
                    setNewImg(str2);
                  }
                }
              );
            }
          })
          .catch((err) => {
            console.log("CATCH", err);
          });
    }
  }, []);

  useEffect(handleImageSize, [newImg, dimensions]);

  const displayPreviousPic = () => {
    if (index > 0) {
      props.picArr[index - 1]?.imgdigest &&
        // props.image.digest &&
        getImage64(databaseState.dexUser, props.picArr[index - 1]?.thumbdigest)
          .then((img) => {
            if (img) {
              setNewImg(img);
            } else {
              s3ToImage(props.mp, props.picArr[index - 1]?.imgdigest).then(
                (i) => {
                  if (i?.b64) {
                    storeImage(databaseState.dexUser, i);
                    setNewImg(i.b64);
                  }
                }
              );
            }
          })
          .catch((err) => {
            console.log("CATCH", err);
          });
      setIndex((state) => state - 1);
    }
  };

  const displayNextPic = () => {
    if (index < props.picArr.length - 1) {
      props.picArr[index + 1]?.imgdigest &&
        // props.image.digest &&
        getImage64(databaseState.dexUser, props.picArr[index + 1]?.thumbdigest)
          .then((img) => {
            if (img) {
              setNewImg(img);
            } else {
              s3ToImage(props.mp, props.picArr[index + 1]?.imgdigest).then(
                (i) => {
                  if (i?.b64) {
                    storeImage(databaseState.dexUser, i);
                    setNewImg(i.b64);
                  }
                }
              );
            }
          })
          .catch((err) => {
            console.log("CATCH", err);
          });
      setIndex((state) => state + 1);
    }
  };

  let contentmodal = (
    <div
      id="profile_modal"
      className="modals modal-image-new"
    >
      <div
        className="modals modal-content-image-new"
      >
        <button
          type="button"
          className="image-close"
          style={
            props.picArr && isLoaded && found && index > 0
              ? {
                  top: "50%",
                  transform: "translate(0,-50%)"
                }
              : { display: "none" }
          }
          onClick={() => {
            displayPreviousPic();
          }}
        >
          <IoIosArrowBack size="16" />
        </button>
        <button
          type="button"
          className="image-close"
          style={
            props.picArr && isLoaded && found && index < props.picArr.length - 1
              ? {
                  top: "50%",
                  transform: "translate(0,-50%)",
                  right: "0"
                }
              : { display: "none" }
          }
          onClick={() => {
            displayNextPic();
          }}
        >
          <IoIosArrowForward size="16" />
        </button>
        <div>
          <img
            ref={imgRef}
            alt={""}
            src={props.thumb}
            style={
              isLoaded
                ? { display: "none" }
                : {
                    height: window.innerHeight * 0.25,
                    width: window.innerWidth * 0.25
                  }
            } //picstyle??
          />
          <img
            ref={displayRef}
            onLoad={() => {
              setIsLoaded(true);
              setFound(true);
            }}
            style={isLoaded ? picStyle : { display: "none" }}
            alt={""}
            src={newImg}
          />
        </div>
        <div>
          <div
            style={isLoaded ? { display: "none" } : { color: "wheat" }}
            className="loader"
          >
            Loading
            <span className="loader__dot" style={{ fontSize: "2rem" }}>
              .
            </span>
            <span className="loader__dot" style={{ fontSize: "2.5rem" }}>
              .
            </span>
            <span className="loader__dot" style={{ fontSize: "3rem" }}>
              .
            </span>
          </div>
          {!found && isLoaded && (
            <button
              onClick={
                () => history.goBack()
                // props.cb()
              }
              style={isLoaded ? { height: "30px" } : { display: "none" }}
            >
              <label>Error: picture not discovered</label>
            </button>
          )}
        </div>
      </div>
      <button
        type="button"
        className="image-close"
        style={
          // isLoaded
          //   ?
          {
                right: "0",
                marginTop: "0.1rem",
                marginRight: "0.1rem",
                marginLeft: "auto",
                display: "flex",
                justifyContent: "center",
                zIndex: "999"
              }
            // : { display: "none" }
        }
        data-dismiss="modal"
        aria-label="Close"
        onClick={() => {
          props.onClose();
        }}
        title="Close Modal"
      >
        <AiOutlineClose size="16" />
      </button>
      {/* </ClickOutsideDetector> */}
    </div>
  );

  return contentmodal;
};

export default React.memo(UIProfileModalNew, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
